import React from "react";

type Props = {
    titulo: string,
    value: boolean,
    onClick: () => void
}

const BotonCheck: React.FC<Props> = ({titulo, value, onClick}) => {
    const estiloBoton = (value ? 'btn-info' : 'btn-outline-info')
    const iconoBoton = (value ? 'fa-check' : 'fa-times')

    return (
        <button type='button' className={'btn btn-sm me-2 mb-2 ' + estiloBoton} onClick={onClick}>
            <div className='icochico'>
                <i className={'fas me-2 ' + iconoBoton}/>
            </div>
            {titulo}
        </button>
    )
}

export default BotonCheck
