import React from "react";

type Props = {
    onExcel?: () => void,
    onPdf?: () => void
}

const BotonesExportar: React.FC<Props> = (
    {onExcel, onPdf}
) => {

    return (
        <div className='d-flex align-items-center'>
            <div className='mx-2' style={{fontSize: '0.75em'}}>
                <i className='fas fa-chevron-right me-2 text-muted'/>
                <i className='fas fa-download me-2'/>Exportar
            </div>
            <div className='btn-group btn-group-sm me-1'>
                {onExcel !== undefined &&
                    <button type='button' className='btn btn-light text-success' onClick={() => onExcel()}>
                        <i className='fas fa-file-excel me-2'/>
                        <small className='text-black'>Excel</small>
                    </button>}
                {onPdf !== undefined &&
                    <button type='button' className='btn btn-light text-danger' onClick={() => onPdf()}>
                        <i className='fas fa-file-pdf me-2'/>
                        <small className='text-black'>PDF</small>
                    </button>}
            </div>
        </div>
    )
}

export default BotonesExportar