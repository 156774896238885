import React from "react";
import ToolbarBtn from "./ToolbarBtn";
import BodyCarga from "./BodyCarga";
import Boton from "./Boton";
import BarraSuperior from "./BarraSuperior";

type Props = {
    error: string | undefined,
    onReintentar: () => void,
    onVolver?: () => void
}

const TarjetaCarga: React.FC<Props> = ({error, onReintentar, onVolver}) => {
    return (
        <div>
            <BarraSuperior>
                <ToolbarBtn>
                    {onVolver !== undefined ? (
                        <Boton toolbar titulo='Volver' icono='fas fa-arrow-left'
                               className='btn-secondary' onClick={() => onVolver()}/>
                    ) : (
                        <div className='btn-group btn-group-sm'>
                            <div className='btn disabled'>&nbsp;</div>
                        </div>
                    )}
                </ToolbarBtn>
            </BarraSuperior>
            <BodyCarga error={error} onReintentar={onReintentar}/>
        </div>
    )
}

export default TarjetaCarga
