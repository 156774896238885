import React from "react";
import './Modal.css'

type Props = {
    children: any,
    className?: string | undefined,
    sinFondo?: boolean,
    titulo?: string
}

const Modal: React.FC<Props> = ({children, className, sinFondo, titulo}) => {
    return (
        <div className={'alineacionmodal ' + (sinFondo ? '' : 'fondomodal')}>
            <div className={'contenidomodal ' + className}>
                <div className='card sombramodal border-0'>
                    {titulo !== undefined && <div className='card-header'>{titulo}</div>}
                    <div className='card-body'>{children}</div>
                </div>
            </div>
        </div>
    )
}

export default Modal
