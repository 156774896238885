import React, {useEffect, useState} from "react";
import Modal from "../controles/Modal";
import ModalBotonCerrar from "../controles/ModalBotonCerrar";
import TarjetaSeccion from "../controles/TarjetaSeccion";
import {useGsBackend} from "../funcionesApi";

type Props = {
    idOrden: number,
    onCerrar: () => void
}

type ProductoOrden = {
    producto: string,
    cantidad: string,
    sector: string,
    costo: string
}

type InfoOrden = {
    id: number,
    proveedor: string,
    nro_factura: string,
    importe: string,
    fecha: string,
    hora: string,
    comentarios: string,
    usuario: string,
    nombre_usuario: string,
    productos: Array<ProductoOrden>
}

type DatosOrden = {
    ordendecompra: InfoOrden
}

const StockConsultaOrden: React.FC<Props> = ({idOrden, onCerrar}) => {
    const [datos, setDatos] = useState<DatosOrden | undefined>(undefined)

    const {pedidoJson} = useGsBackend()
    useEffect(() => {
        pedidoJson({
            url: `stock/ordenesdecompra/consultar/${idOrden}`, method: 'get', ok: setDatos
        })
    }, [idOrden, pedidoJson])

    if (datos === undefined) {
        return <></>
    } else {
        return (
            <Modal className='w-50'>
                <h4>Consultar Orden de Compra</h4>
                <TarjetaSeccion>
                    <div className='row mb-2'>
                        <div className='col-md-3'><strong>Proveedor</strong></div>
                        <div className='col-md-9'>{datos.ordendecompra.proveedor}</div>
                    </div>
                    <div className='row mb-2'>
                        <div className='col-md-3'><strong>Nro. Factura / Remito</strong></div>
                        <div className='col-md-9'>{datos.ordendecompra.nro_factura}</div>
                    </div>
                    {datos.ordendecompra.comentarios !== '' && (
                        <div className='row mb-2'>
                            <div className='col-md-3'><strong>Comentarios</strong></div>
                            <div className='col-md-9'>{datos.ordendecompra.comentarios}</div>
                        </div>
                    )}
                    <div className='row mb-2'>
                        <div className='col-md-3'><strong>Fecha y Hora</strong></div>
                        <div className='col-md-9'>{datos.ordendecompra.fecha} {datos.ordendecompra.hora}</div>
                    </div>
                    <div className='row'>
                        <div className='col-md-3'><strong>Usuario</strong></div>
                        <div className='col-md-9'>
                            <strong>{datos.ordendecompra.usuario}</strong> ({datos.ordendecompra.nombre_usuario})
                        </div>
                    </div>
                </TarjetaSeccion>
                <TarjetaSeccion>
                    <table className='table table-responsive table-sm table-striped table-bordered'>
                        <thead>
                        <tr>
                            <th>Producto</th>
                            <th>Cantidad</th>
                            <th>Sector</th>
                            <th>Costo</th>
                        </tr>
                        </thead>
                        <tbody>
                        {datos.ordendecompra.productos.map((p, k) => (
                            <tr key={k}>
                                <td>{p.producto}</td>
                                <td className='text-nowrap'>{p.cantidad}</td>
                                <td>{p.sector}</td>
                                <td className='text-nowrap text-end'>{p.costo}</td>
                            </tr>
                        ))}
                        </tbody>
                    </table>
                    <div className='row'>
                        <div className='col-md-3'><strong>Importe Total</strong></div>
                        <div className='col-md-9'>{datos.ordendecompra.importe}</div>
                    </div>
                </TarjetaSeccion>
                <ModalBotonCerrar onCerrar={onCerrar}/>
            </Modal>
        )
    }
}

export default StockConsultaOrden