import React from "react";

type Props = {
    children: React.ReactNode,
    sinEspacio?: boolean
}

const CuerpoPrincipal: React.FC<Props> = ({children, sinEspacio}) => {
    return (
        <div className={`p-3 ${sinEspacio ? 'pb-0' : ''}`}>
            {children}
        </div>
    )
}

export default CuerpoPrincipal