import React from "react";

type WarningMensajeProps = {
    className?: string,
    mensaje: string
}

const WarningMensaje: React.FC<WarningMensajeProps> = ({className, mensaje}) => {
    return (
        <div className={className + ' row'}>
            <div className='col-auto'>
                <i className='fas fa-exclamation-circle text-warning'/>
            </div>
            <div className='col p-0 text-warning'>
                {mensaje}
            </div>
        </div>
    )
}

export default WarningMensaje
