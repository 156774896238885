import React, {useEffect, useState} from "react";
import TarjetaCarga from "../controles/TarjetaCarga";
import ToolbarBtn from "../controles/ToolbarBtn";
import Boton from "../controles/Boton";
import StockConsultaOrden from "./StockConsultaOrden";
import Tooltip, {InfoTooltip} from "../controles/Tooltip";
import BotonesExportar from "../controles/BotonesExportar";
import TrInfo from "../controles/TrInfo";
import {useNavigate, useParams} from "react-router-dom";
import BarraSuperior from "../controles/BarraSuperior";
import CuerpoPrincipal from "../controles/CuerpoPrincipal";
import {useGsBackend} from "../funcionesApi";
import {funcionDescarga} from "../descargas/descargas";

type RegistroProveedor = {
    tipo: number,
    id_ordendecompra: number,
    id_movimiento: number,
    fecha: string,
    hora: string,
    descripcion: string,
    cantidad: string,
    importe: string,
    comentarios: string,
    documento: string,
    usuario: string,
    nombre_usuario: string
}

type InfoHistorialProveedor = {
    proveedor: string,
    historial: Array<RegistroProveedor>
}

const StockHistorialProveedor: React.FC = () => {
    const [datos, setDatos] = useState<InfoHistorialProveedor | undefined>(undefined)
    const [consultaOrden, setConsultaOrden] = useState<number | undefined>(undefined)
    const [infoTooltip, setInfoTooltip] = useState<InfoTooltip | undefined>(undefined)
    const navigate = useNavigate()
    const params = useParams()
    const idProveedor = parseInt(params.idProveedor || '0')

    const {pedidoJson, pedidoBlob, errorCarga, actualizar} = useGsBackend()

    useEffect(() => {
        pedidoJson({
            url: `stock/proveedores/historial/${idProveedor}`, method: 'get',
            ok: res => {
                if (res.proveedor === undefined) navigate('../')
                setDatos(res)
            }
        })
    }, [pedidoJson, idProveedor, navigate])

    const cargaTooltipHistorial = (idMovimiento: number, idOrdenDeCompra: number): void => {
        if (idOrdenDeCompra !== 0) {
            setInfoTooltip({
                urlPreview: `previews?tipo=ordenesdecompra&id_ordendecompra=${idOrdenDeCompra}`
            })
        } else {
            setInfoTooltip({
                urlPreview: `previews?tipo=movimientosstock&id_movimiento=${idMovimiento}`
            })
        }
    }

    const excelHistorialProveedor = (): void => {
        pedidoBlob({
            url: `excel/stockhistorialproveedor?id_proveedor=${idProveedor}`,
            ok: res => funcionDescarga(res, 'historialproveedor.xlsx')
        })
    }

    const descargaOrdenDeCompra = (idOrdenDecompra: number, nombreArchivo: string): void => {
        pedidoBlob({
            url: `archivos?tipo=ordenesdecompra&id_ordendecompra=${idOrdenDecompra}`,
            ok: res => funcionDescarga(res, nombreArchivo)
        })
    }

    const descargaMovimientoStock = (idMovimientoStock: number, nombreArchivo: string): void => {
        pedidoBlob({
            url: `archivos?tipo=movimientosstock&id_movimiento=${idMovimientoStock}`,
            ok: res => funcionDescarga(res, nombreArchivo)
        })
    }

    if (datos === undefined) {
        return (
            <TarjetaCarga error={errorCarga} onReintentar={actualizar}
                          onVolver={() => navigate('../')}/>
        )
    } else {
        return (
            <div>
                <BarraSuperior>
                    <ToolbarBtn>
                        <Boton toolbar titulo='Volver a Proveedores'
                               icono='fas fa-arrow-left' className='btn-secondary'
                               onClick={() => navigate('../')}/>
                        {datos.historial.length ? <BotonesExportar
                            onExcel={excelHistorialProveedor}/> : <></>}
                    </ToolbarBtn>
                </BarraSuperior>
                <CuerpoPrincipal sinEspacio>
                    <h4>
                        <i className='fas fa-clock text-primary me-2'/>
                        Historial {datos.proveedor}
                    </h4>
                </CuerpoPrincipal>
                <table className='table table-responsive table-striped'>
                    <thead>
                    <tr>
                        <th className='ps-3'>Tipo</th>
                        <th>Fecha</th>
                        <th>Hora</th>
                        <th>Referencia</th>
                        <th>Cantidad</th>
                        <th>Importe</th>
                        <th>Comentarios</th>
                        <th>Documento</th>
                        <th>Usuario</th>
                        <th>Acciones</th>
                    </tr>
                    </thead>
                    <tbody>
                    {datos.historial.length ? datos.historial.map((h, k) => (
                        <tr key={k} className='small'>
                            <td className='text-nowrap ps-3'>
                                {h.tipo === 1 ? (
                                    <span className='text-info'>
                                        <i className='fas fa-plus me-2'/>Producto
                                    </span>
                                ) : h.tipo === 2 ? (
                                    <span className='text-primary'>
                                        <i className='fas fas fa-cart-shopping me-2'/>Orden de Compra
                                    </span>
                                ) : (
                                    <></>
                                )}
                            </td>
                            <td>{h.fecha}</td>
                            <td>{h.hora}</td>
                            <td>{h.descripcion}</td>
                            <td className='text-nowrap'>{h.cantidad}</td>
                            <td className='text-nowrap text-end'>{h.importe}</td>
                            <td>{h.comentarios}</td>
                            <td>
                                {h.documento !== '' && (
                                    <span className='enlacespan text-primary'
                                          style={{'cursor': 'pointer'}}
                                          onClick={e => {
                                              e.stopPropagation()
                                              if (h.tipo === 1) {
                                                  descargaMovimientoStock(h.id_movimiento, h.documento)
                                              } else {
                                                  descargaOrdenDeCompra(h.id_ordendecompra, h.documento)
                                              }
                                          }}
                                          onMouseEnter={() => {
                                              cargaTooltipHistorial(h.id_movimiento, h.id_ordendecompra)
                                          }}
                                          onMouseLeave={() => setInfoTooltip(undefined)}>
                                              <i className='fas fa-file-download me-2'/>Descargar
                                              </span>
                                )}
                            </td>
                            <td><strong>{h.usuario}</strong><br/>{h.nombre_usuario}</td>
                            <td className='text-nowrap'>
                                {h.tipo === 2 && (
                                    <Boton toolbar chico titulo='Detalle' icono='fas fa-search'
                                           onClick={() => setConsultaOrden(h.id_ordendecompra)}/>
                                )}
                            </td>
                        </tr>
                    )) : (
                        <TrInfo colSpan={7} texto='No hay registros para mostrar'/>
                    )}
                    </tbody>
                </table>
                {consultaOrden !== undefined && (
                    <StockConsultaOrden onCerrar={() => setConsultaOrden(undefined)} idOrden={consultaOrden}/>
                )}
                {infoTooltip !== undefined && <Tooltip datos={infoTooltip}/>}
            </div>
        )
    }
}

export default StockHistorialProveedor