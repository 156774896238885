import React, {useEffect, useMemo, useState} from "react";
import {tablasParametros} from "./tablas";
import Boton from "../controles/Boton";
import Modal from "../controles/Modal";
import InputTexto from "../controles/InputTexto";
import ModalBotones from "../controles/ModalBotones";
import {useAppSelector} from "../redux/hooks";
import TrInfo from "../controles/TrInfo";
import TrCarga from "../controles/TrCarga";
import {GsRequestParams, useGsBackend} from "../funcionesApi";

type Props = {
    tabla: string
}

type Datos = {
    id: number,
    descripcion: string
}

const TablaParametros: React.FC<Props> = ({tabla}) => {
    const infoTabla = tablasParametros.filter(t => t.codigo === tabla)[0]
    const [editando, setEditando] = useState<number | undefined>(undefined)
    const [editDescripcion, setEditDescripcion] = useState<string>('')
    const [validaDesc, setValidaDesc] = useState<boolean>(true)
    const [datos, setDatos] = useState<Array<Datos> | undefined>(undefined)
    const edita = useAppSelector(state => state.auth.edicion)

    const infoApi = useMemo<GsRequestParams>(() => {
        return {url: `tablas/iddescripcion/${infoTabla.codigo || ''}`, ok: setDatos}
    }, [infoTabla.codigo])
    const {pedidoJson, cargando} = useGsBackend()

    useEffect(() => {
        pedidoJson(infoApi)
    }, [infoApi, pedidoJson])

    const editHandler = (arg: number | undefined): void => {
        if (datos === undefined) return

        setValidaDesc(true)
        if (arg === undefined) {
            setEditando(undefined)
        } else if (arg === 0) {
            setEditDescripcion('')
            setEditando(0)
        } else {
            setEditDescripcion(datos.filter(d => d.id === arg)[0].descripcion)
            setEditando(arg)
        }
    }

    const guardarHandler = async (eliminar: boolean): Promise<void> => {
        if (editando === undefined) return

        const valido = editDescripcion !== ''
        setValidaDesc(valido)
        if (!valido) return

        const fd = new FormData()
        fd.append('accion', eliminar ? 'eliminar' : editando === 0 ? 'nuevo' : 'editar')
        fd.append('id', editando.toString())
        fd.append('descripcion', editDescripcion)

        pedidoJson({
            url: infoApi.url, method: 'post', body: fd,
            ok: () => {
                setEditando(undefined)
                pedidoJson(infoApi)
            }
        })
    }

    return (
        <>
            <h4>{infoTabla.titulo}</h4>
            <div className='row g-3'>
                <div className='col-sm'>
                    <div className='border rounded-2'>
                        <table className={'table table-responsive table-striped table-sm mb-0' +
                            (edita ? ' table-hover' : '')}>
                            <thead>
                            <tr>
                                <th>Descripción</th>
                            </tr>
                            </thead>
                            <tbody>
                            {datos === undefined ? (
                                <TrCarga colSpan={1}/>
                            ) : datos.length ? datos.map((d, i) => (
                                <tr className={edita ? 'cursorpointer' : ''} onClick={() => {
                                    if (edita) editHandler(d.id)
                                }} key={i}>
                                    <td>{d.descripcion}</td>
                                </tr>
                            )) : (
                                <TrInfo colSpan={1} texto='No se encontró ningún registro'/>
                            )}
                            </tbody>
                        </table>
                    </div>
                </div>
                {edita && <div className='col-sm-auto'>
                    <Boton titulo='Nuevo registro' icono='fas fa-plus' chico
                           onClick={() => editHandler(0)}
                           disabled={datos === undefined}/>
                </div>}
                {editando !== undefined && (
                    <Modal className='w-50'>
                        <h4>{editando === 0 ? 'Nuevo' : 'Editar'}</h4>
                        <div className='mb-3'>
                            <InputTexto titulo='Descripción' value={editDescripcion}
                                        onChange={setEditDescripcion}
                                        invalido={!validaDesc} autoFocus/>
                        </div>
                        <ModalBotones editando={editando !== 0} guardando={cargando}
                                      onCancelar={() => editHandler(undefined)}
                                      onGuardar={() => guardarHandler(false)}
                                      onEliminar={() => guardarHandler(true)}/>
                    </Modal>
                )}
            </div>
        </>
    )
}

export default TablaParametros
