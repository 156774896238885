import React, {useState} from "react";

type InputTextoProps = {
    titulo?: string,
    className?: string,
    chico?: boolean,
    value: string,
    placeholder?: string,
    onChange: (arg: string) => void,
    invalido?: boolean,
    autoFocus?: boolean,
    titulado?: boolean,
    disabled?: boolean,
    obligatorio?: boolean,
    numerico?: boolean,
    clave?: boolean,
    onEnter?: () => void,
    textoInvalido?: string,
    izquierda?: boolean,
    textoPosterior?: string
}

/**
 * Funcion generadora de un input tipo Texto
 * @param props
 * @returns {JSX.Element}
 * @constructor
 */
const InputTexto: React.FC<InputTextoProps> = (
    {
        titulo, className, chico, value,
        placeholder, onChange, invalido, autoFocus,
        titulado, disabled, obligatorio,
        numerico, clave, onEnter,
        textoInvalido, izquierda, textoPosterior
    }
) => {
    const [mostrarClave, setMostrarClave] = useState<boolean>(false)
    const tipo = clave && !mostrarClave ? 'password' : 'text'

    const edicionHandler = (arg: string): void => {
        if (numerico) {
            const numero = arg.replace(/\D/g, '').replace(/^0+/, '')
            onChange(numero === '' ? '0' : numero)
        } else {
            onChange(arg)
        }
    }

    const teclaHandler = onEnter === undefined ? undefined : (e: React.KeyboardEvent) => {
        if (e.key === 'Enter') {
            onEnter()
        }
    }

    const feedbackInvalido = () => {
        if (textoInvalido !== undefined && textoInvalido !== '') {
            return <div className='invalid-feedback'>{textoInvalido}</div>
        } else {
            return <></>
        }
    }

    if (titulo && titulado) {
        return (
            <div className='mb-2'>
                <div className='form-label ms-1 mb-1'>
                    {titulo}
                    {obligatorio && <span className='text-muted small ms-1'> (obligatorio)</span>}
                </div>
                <input type={tipo} className={'form-control ' + (invalido ? 'is-invalid ' : '') +
                    (numerico && !izquierda ? 'text-end ' : '')} value={value}
                       placeholder={placeholder} disabled={disabled} onKeyDown={teclaHandler}
                       onChange={e => edicionHandler(e.target.value)} autoFocus={autoFocus}/>
                {feedbackInvalido()}
            </div>
        )
    } else if (titulo) {
        return (
            <div className={className + ' input-group' + (chico ? ' input-group-sm' : '') +
                (textoInvalido !== undefined && textoInvalido !== '' ? ' has-validation' : '')}>
                <div className='input-group-text'>{titulo}</div>
                <input type={tipo} className={'form-control ' + (invalido ? 'is-invalid ' : '') +
                    (numerico && !izquierda ? 'text-end ' : '')} value={value}
                       placeholder={placeholder} disabled={disabled} onKeyDown={teclaHandler}
                       onChange={e => edicionHandler(e.target.value)} autoFocus={autoFocus}/>
                {clave && (
                    <div className='input-group-text' style={{cursor: 'pointer'}}
                         onClick={() => setMostrarClave(!mostrarClave)}>
                        <i className={'fas ' + (mostrarClave ? 'fa-eye-slash' : 'fa-eye')}/>
                    </div>
                )}
                {textoPosterior && (
                    <div className='input-group-text'>{textoPosterior}</div>
                )}
                {feedbackInvalido()}
            </div>
        )
    } else {
        return <>
            <input type={tipo} placeholder={placeholder} disabled={disabled}
                   className={className +
                       ' form-control ' + (invalido ? 'is-invalid ' : '') + (chico ? 'form-control-sm ' : '') +
                       (numerico && !izquierda ? 'text-end ' : '')} onKeyDown={teclaHandler}
                   value={value} onChange={e => edicionHandler(e.target.value)} autoFocus={autoFocus}/>
            {feedbackInvalido()}
        </>
    }
}

export default InputTexto
