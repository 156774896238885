import React from "react";
import {tablasParametros} from "./tablas";
import TablaParametros from "./TablaParametros";
import {Navigate, useNavigate, useParams} from "react-router-dom";
import CuerpoPrincipal from "../controles/CuerpoPrincipal";

const ConfiguracionParametros: React.FC = () => {
    const tablas = tablasParametros.sort((a, b) => a.titulo.localeCompare(b.titulo))
    const tablaInicial = tablas[0]?.codigo || ''
    const params = useParams()
    const navigate = useNavigate()

    const tabla = params.tabla || ''
    if (tabla === '' || !tablas.filter(t => t.codigo === tabla).length) {
        return <Navigate to={tablaInicial} replace/>
    }

    return (
        <CuerpoPrincipal>
            <div className='row g-3'>
                <div className='col-auto'>
                    <h4><i className='fas fa-table text-primary me-2'/>Tablas</h4>
                    <ul className='nav flex-column nav-pills'>
                        {tablas.map((t, i) => (
                            <li className='nav-item' key={i}>
                                <div className={'py-1 ps-3 pe-4 nav-link cursorpointer' +
                                    (tabla === t.codigo ? ' active' : '')}
                                     onClick={() => navigate(t.codigo)}>
                                    {t.titulo}
                                </div>
                            </li>
                        ))}
                    </ul>
                </div>
                <div className='col'>
                    <TablaParametros tabla={tabla}/>
                </div>
            </div>
        </CuerpoPrincipal>
    )
}

export default ConfiguracionParametros
