import React, {useEffect, useState} from "react";
import ToolbarBtn from "../controles/ToolbarBtn";
import Boton from "../controles/Boton";
import TarjetaCarga from "../controles/TarjetaCarga";
import Tooltip, {InfoTooltip} from "../controles/Tooltip";
import BotonesExportar from "../controles/BotonesExportar";
import TrInfo from "../controles/TrInfo";
import {useNavigate, useParams} from "react-router-dom";
import BarraSuperior from "../controles/BarraSuperior";
import CuerpoPrincipal from "../controles/CuerpoPrincipal";
import {useGsBackend} from "../funcionesApi";
import {funcionDescarga} from "../descargas/descargas";

type Movimiento = {
    id: number,
    tipo_movimiento: number,
    fecha: string,
    hora: string,
    id_producto: number,
    producto: string,
    id_proveedor: number,
    proveedor: string,
    id_ordendecompra: number,
    ordendecompra: string,
    id_sector: number,
    sector: string,
    id_destino: number,
    destino: string,
    cantidad_movimiento: number,
    cantidad_parcial: number,
    unidaddemedida: string,
    costo: number,
    costo_fmt: string,
    costo_unitario: number,
    costo_unitario_fmt: string,
    comentarios: string,
    documento: string,
    usuario: string,
    nombre_usuario: string
}

type InfoMovimientos = {
    movimientos: Array<Movimiento>,
    titulo_filtros: string
}

const StockConsultaMovimientos: React.FC = () => {
    const [datos, setDatos] = useState<InfoMovimientos | undefined>(undefined)
    const [infoTooltip, setInfoTooltip] = useState<InfoTooltip | undefined>(undefined)
    const params = useParams()
    const navigate = useNavigate()

    const idProducto = params.idProducto === undefined ? undefined : parseInt(params.idProducto || '0')
    const idSector = params.idSector === undefined ? undefined : parseInt(params.idSector || '0')
    const idDestino = params.idDestino === undefined ? undefined : parseInt(params.idDestino || '0')

    const {pedidoJson, pedidoBlob, errorCarga, actualizar} = useGsBackend()

    useEffect(() => {
        const req: Array<string> = []
        if (idProducto !== undefined) req.push(`id_producto=${idProducto}`)
        if (idSector !== undefined) req.push(`id_sector=${idSector}`)
        if (idDestino !== undefined) req.push(`id_destino=${idDestino}`)

        pedidoJson({
            url: `stock/consultamovimientos?${req.join('&')}`, method: 'get',
            ok: res => {
                if (res.movimientos === undefined) navigate('../')
                setDatos(res)
            }
        })
    }, [pedidoJson, idProducto, idDestino, idSector, navigate])

    const cargaTooltipMovimientos = (idMovimiento: number, idOrdenDeCompra: number): void => {
        if (idOrdenDeCompra !== 0) {
            setInfoTooltip({
                urlPreview: `previews?tipo=ordenesdecompra&id_ordendecompra=${idOrdenDeCompra}`
            })
        } else {
            setInfoTooltip({
                urlPreview: `previews?tipo=movimientosstock&id_movimiento=${idMovimiento}`
            })
        }
    }

    const descargaOrdenDeCompra = (idOrdenDecompra: number, nombreArchivo: string): void => {
        pedidoBlob({
            url: `archivos?tipo=ordenesdecompra&id_ordendecompra=${idOrdenDecompra}`,
            ok: res => funcionDescarga(res, nombreArchivo)
        })
    }

    const descargaMovimientoStock = (idMovimientoStock: number, nombreArchivo: string): void => {
        pedidoBlob({
            url: `archivos?tipo=movimientosstock&id_movimiento=${idMovimientoStock}`,
            ok: res => funcionDescarga(res, nombreArchivo)
        })
    }

    if (datos === undefined) {
        return <TarjetaCarga error={errorCarga} onReintentar={actualizar}
                             onVolver={() => navigate('../')}/>
    } else {
        const excelMovimientos = (): void => {
            const params: Array<string> = []
            if (idProducto !== undefined) params.push(`id_producto=${idProducto}`)
            if (idSector !== undefined) params.push(`id_sector=${idSector}`)
            if (idDestino !== undefined) params.push(`id_destino=${idDestino}`)

            pedidoBlob({
                url: 'excel/stockmovimientos?' + params.join('&'),
                ok: res => funcionDescarga(res, 'movimientos.xlsx')
            })
        }

        return (
            <div>
                <BarraSuperior>
                    <ToolbarBtn>
                        <Boton toolbar titulo='Volver' icono='fas fa-arrow-left' className='btn-secondary'
                               onClick={() => navigate('../')}/>
                        {datos.movimientos.length ? <BotonesExportar onExcel={excelMovimientos}/> : <></>}
                    </ToolbarBtn>
                </BarraSuperior>
                <CuerpoPrincipal sinEspacio>
                    <h4>
                        <i className='fas fa-clipboard-list text-primary me-2'/>
                        Consulta de Movimientos ({datos.titulo_filtros})
                    </h4>
                </CuerpoPrincipal>
                <table className='table table-responsive table-striped'>
                    <thead>
                    <tr>
                        <th className='ps-3'>Movimiento</th>
                        <th>Producto</th>
                        <th>Sector</th>
                        <th>Proveedor / Destino</th>
                        <th>Cantidad</th>
                        <th className='text-nowrap'>Stock parcial</th>
                        <th>Costo</th>
                        <th>Comentarios</th>
                        <th>Documento</th>
                        <th>Usuario</th>
                    </tr>
                    </thead>
                    <tbody>
                    {datos.movimientos.length ? datos.movimientos.map((m, k) => (
                        <tr key={k} className='small'>
                            <td className='text-nowrap ps-3'>
                                {m.tipo_movimiento === 1 ? (
                                    <span className='text-success text-nowrap'>
                                        <i className='fas fa-plus me-2'/>Agregar
                                    </span>
                                ) : m.tipo_movimiento === 2 ? (
                                    <span className='text-info text-nowrap'>
                                        <i className='fas fa-arrow-turn-up me-2'/>Asignar
                                    </span>
                                ) : (<></>)}
                                <br/>
                                <span className='small text-muted text-nowrap'>
                                    {m.fecha} {m.hora}
                                </span>
                            </td>
                            <td>{m.producto}</td>
                            <td>{m.sector}</td>
                            <td>{m.tipo_movimiento === 1 ? (
                                <>
                                    {m.proveedor}{m.id_ordendecompra !== 0 && (
                                    <><br/><span className='text-muted small'>{m.ordendecompra}</span></>
                                )}
                                </>
                            ) : m.destino}</td>
                            <td className='text-nowrap'>{m.cantidad_movimiento} {m.unidaddemedida}</td>
                            <td className={m.cantidad_parcial === 0 ? 'text-nowrap text-danger' : 'text-nowrap'}>
                                {m.cantidad_parcial} {m.unidaddemedida}
                            </td>
                            <td className='text-nowrap'>
                                {m.costo === 0 ? '' : (
                                    <>
                                        {m.costo_fmt}<br/>
                                        <span className='small text-muted'>{m.costo_unitario_fmt}</span>
                                    </>
                                )}
                            </td>
                            <td>{m.comentarios}</td>
                            <td>
                                {m.documento !== '' && (
                                    <span className='enlacespan text-primary'
                                          style={{'cursor': 'pointer'}}
                                          onClick={e => {
                                              e.stopPropagation()
                                              if (m.id_ordendecompra === 0) {
                                                  descargaMovimientoStock(m.id, m.documento)
                                              } else {
                                                  descargaOrdenDeCompra(m.id_ordendecompra, m.documento)
                                              }
                                          }}
                                          onMouseEnter={() => {
                                              cargaTooltipMovimientos(m.id, m.id_ordendecompra)
                                          }}
                                          onMouseLeave={() => setInfoTooltip(undefined)}>
                                              <i className='fas fa-file-download me-2'/>Descargar
                                              </span>
                                )}
                            </td>
                            <td className='text-nowrap'>
                                <strong>{m.usuario}</strong><br/>
                                {m.nombre_usuario}
                            </td>
                        </tr>
                    )) : (
                        <TrInfo colSpan={10} texto='No se encuentran movimientos para mostrar'/>
                    )}
                    </tbody>
                </table>
                {infoTooltip !== undefined && <Tooltip datos={infoTooltip}/>}
            </div>
        )
    }
}

export default StockConsultaMovimientos