import React, {useEffect, useState} from "react";
import Boton from "../controles/Boton";
import ToolbarBtn from "../controles/ToolbarBtn";
import TarjetaCarga from "../controles/TarjetaCarga";
import Modal from "../controles/Modal";
import TarjetaSeccion from "../controles/TarjetaSeccion";
import InputTexto from "../controles/InputTexto";
import ModalBotones from "../controles/ModalBotones";
import InputSelect, {OpcionSelect} from "../controles/InputSelect";
import ModalBotonCerrar from "../controles/ModalBotonCerrar";
import TrInfo from "../controles/TrInfo";
import {useNavigate} from "react-router-dom";
import BarraSuperior from "../controles/BarraSuperior";
import CuerpoPrincipal from "../controles/CuerpoPrincipal";
import {useGsBackend} from "../funcionesApi";

type Sector = {
    id: number,
    descripcion: string,
    observaciones: string,
    id_responsable: number,
    responsable: string,
    eliminable: boolean
}

type InfoSectores = {
    sectores: Array<Sector>
    empleados: Array<OpcionSelect>
}

type ValidacionEdicion = {
    descripcion: boolean,
    id_responsable: boolean
}

type ExistenciasSector = {
    producto: string,
    existencias: number,
    unidaddemedida: string
}

type InfoExistenciasSector = {
    sector: string,
    existencias_sector: Array<ExistenciasSector>
}

const StockSectores: React.FC = () => {
    const validacionOk: ValidacionEdicion = {descripcion: true, id_responsable: true}
    const [datos, setDatos] = useState<InfoSectores | undefined>(undefined)
    const [editando, setEditando] = useState<number | undefined>(undefined)
    const [editDescripcion, setEditDescripcion] = useState<string>('')
    const [editObservaciones, setEditObservaciones] = useState<string>('')
    const [editResponsable, setEditResponsable] = useState<number>(0)
    const [eliminable, setEliminable] = useState<boolean>(false)
    const [validacion, setValidacion] = useState<ValidacionEdicion>(validacionOk)
    const [infoExistencias, setInfoExistencias] = useState<InfoExistenciasSector | undefined>(undefined)
    const navigate = useNavigate()

    const {pedidoJson, errorCarga, actualizar, cargando} = useGsBackend()

    useEffect(() => {
        pedidoJson({url: 'stock/sectores', method: 'get', ok: setDatos})
    }, [pedidoJson])

    const editarHandler = (id?: number): void => {
        if (datos === undefined) return
        if (id === undefined) {
            setEditando(undefined)
        } else if (id === 0) {
            setEditDescripcion('')
            setEditObservaciones('')
            setEditResponsable(0)
            setValidacion(validacionOk)
            setEditando(0)
        } else {
            const item = datos?.sectores.filter(d => d.id === id)[0]
            if (item === undefined) return
            setEditDescripcion(item.descripcion)
            setEditObservaciones(item.observaciones)
            setEditResponsable(item.id_responsable)
            setEliminable(item.eliminable)
            setValidacion(validacionOk)
            setEditando(id)
        }
    }

    const guardarHandler = async (eliminar?: boolean): Promise<void> => {
        if (editando === undefined) return

        const vld: ValidacionEdicion = {
            descripcion: editDescripcion !== '',
            id_responsable: editResponsable !== 0
        }
        setValidacion(vld)
        if (!vld.descripcion || !vld.id_responsable) return

        const fd = new FormData()
        fd.append('accion', editando === 0 ? 'nuevo' : eliminar ? 'eliminar' : 'editar')
        fd.append('id', editando.toString())
        fd.append('descripcion', editDescripcion)
        fd.append('observaciones', editObservaciones)
        fd.append('id_responsable', editResponsable.toString())

        pedidoJson({
            url: 'stock/sectores', method: 'post', body: fd,
            ok: () => {
                editarHandler(undefined)
                actualizar()
            }
        })
    }

    const existenciasHandler = async (idSector: number): Promise<void> => {
        pedidoJson({
            url: `stock/sectores/existenciassector/${idSector}`, method: 'get', ok: setInfoExistencias
        })
    }

    if (datos === undefined) {
        return (
            <TarjetaCarga error={errorCarga} onReintentar={actualizar}
                          onVolver={() => navigate('../../')}/>
        )
    } else {
        return (
            <div>
                <BarraSuperior>
                    <ToolbarBtn>
                        <Boton toolbar titulo='Volver' icono='fas fa-arrow-left' className='btn-secondary'
                               onClick={() => navigate('../../')}/>
                        <Boton toolbar titulo='Alta de Sector' icono='fas fa-plus'
                               onClick={() => editarHandler(0)}/>
                    </ToolbarBtn>
                </BarraSuperior>
                <CuerpoPrincipal sinEspacio>
                    <h4>
                        <i className='fas fa-warehouse text-primary me-2'/>
                        Sectores
                    </h4>
                </CuerpoPrincipal>
                <table className='table table-responsive table-striped table-hover'>
                    <thead>
                    <tr>
                        <th style={{width: '33%'}} className='ps-3'>Descripción</th>
                        <th style={{width: '33%'}}>Observaciones</th>
                        <th style={{width: '33%'}}>Responsable</th>
                        <th>Acciones</th>
                    </tr>
                    </thead>
                    <tbody>
                    {datos.sectores.length ? datos.sectores.map((d, k) => (
                        <tr key={k} style={{cursor: 'pointer'}} onClick={() => editarHandler(d.id)}>
                            <td className='fw-bold ps-3'>{d.descripcion}</td>
                            <td>{d.observaciones}</td>
                            <td>{d.responsable}</td>
                            <td className='text-nowrap'>
                                <Boton chico toolbar titulo='Existencias' icono='fas fa-boxes'
                                       onClick={e => {
                                           e.stopPropagation()
                                           existenciasHandler(d.id).catch(err => alert(err))
                                       }}/>
                                <Boton chico toolbar titulo='Movimientos' icono='fas fa-clipboard-list'
                                       onClick={e => {
                                           e.stopPropagation()
                                           navigate(`${d.id}/movimientos`)
                                       }}/>
                            </td>
                        </tr>
                    )) : (
                        <TrInfo colSpan={4} texto='No se encuentran sectores para mostrar'/>
                    )}
                    </tbody>
                </table>
                {editando !== undefined && (
                    <Modal className='w-50'>
                        <h4>{editando === 0 ? 'Alta de Sector' : 'Editar Sector'}</h4>
                        <TarjetaSeccion>
                            <div className='row mb-2'>
                                <div className='col-md-3 pt-1'>Descripción</div>
                                <div className='col-md-9'>
                                    <InputTexto value={editDescripcion} onChange={setEditDescripcion}
                                                autoFocus invalido={!validacion.descripcion}/>
                                </div>
                            </div>
                            <div className='row mb-2'>
                                <div className='col-md-3 pt-1'>Observaciones</div>
                                <div className='col-md-9'>
                                    <InputTexto value={editObservaciones} onChange={setEditObservaciones}/>
                                </div>
                            </div>
                            <div className='row mb-2'>
                                <div className='col-md-3 pt-1'>Responsable</div>
                                <div className='col-md-9'>
                                    <InputSelect value={editResponsable} onChange={setEditResponsable}
                                                 opciones={datos.empleados} invalido={!validacion.id_responsable}/>
                                </div>
                            </div>
                        </TarjetaSeccion>
                        <ModalBotones editando={editando > 0} soloEditar={editando > 0 && !eliminable}
                                      onCancelar={() => editarHandler(undefined)} guardando={cargando}
                                      onEliminar={() => guardarHandler(true).catch(err => alert(err))}
                                      onGuardar={guardarHandler}/>
                    </Modal>
                )}
                {infoExistencias !== undefined && (
                    <Modal className='w-50'>
                        <h4>{infoExistencias.sector}</h4>
                        <table className='table table-responsive table-striped table-bordered table-sm'>
                            <thead>
                            <tr>
                                <th style={{width: '75%'}}>Producto</th>
                                <th style={{width: '25%'}}>Existencias</th>
                            </tr>
                            </thead>
                            <tbody>
                            {infoExistencias.existencias_sector.length ?
                                infoExistencias.existencias_sector.map((e, k) => (
                                    <tr key={k}>
                                        <td>{e.producto}</td>
                                        <td>{e.existencias} {e.unidaddemedida}</td>
                                    </tr>
                                )) : (
                                    <TrInfo colSpan={2} texto='No se registran existencias en este sector'/>
                                )}
                            </tbody>
                        </table>
                        <ModalBotonCerrar onCerrar={() => setInfoExistencias(undefined)}/>
                    </Modal>
                )}
            </div>
        )
    }
}

export default StockSectores