import React, {useEffect, useState} from "react";
import BodyCarga from "../controles/BodyCarga";
import CheckEstado from "../controles/CheckEstado";
import Modulos from "../modulos";
import Modal from "../controles/Modal";
import ModalBotones from "../controles/ModalBotones";
import InputTexto from "../controles/InputTexto";
import InputSelect, {OpcionSelect} from "../controles/InputSelect";
import Boton from "../controles/Boton";
import CuerpoPrincipal from "../controles/CuerpoPrincipal";
import {useGsBackend} from "../funcionesApi";

type Usuario = {
    id: number,
    usuario: string,
    nombre: string,
    apellido: string,
    email: string,
    ultimoacceso: string,
    habilitado: boolean,
    modulos: Array<string>,
    administrador: boolean,
    solo_consulta: boolean
}

type InfoSuscripcion = {
    cantidad_usuarios: number,
    maximo_usuarios: number,
    usuarios_disponibles: number
}

type Validaciones = {
    usuario: boolean,
    clave: boolean,
    nombre: boolean,
    apellido: boolean,
    email: boolean
}

const ConfiguracionUsuarios: React.FC = () => {
    const validacionOk: Validaciones = {
        usuario: true,
        clave: true,
        nombre: true,
        apellido: true,
        email: true
    }

    const [editando, setEditando] = useState<number | undefined>(undefined)
    const [usuarios, setUsuarios] = useState<Array<Usuario> | undefined>(undefined)
    const [prefijo, setPrefijo] = useState<string>('')
    const [infoSuscripcion, setInfoSuscripcion] = useState<InfoSuscripcion>({
        cantidad_usuarios: 0,
        maximo_usuarios: 0,
        usuarios_disponibles: 0
    } as InfoSuscripcion)
    const [modulosDisponibles, setModulosDisponibles] = useState<Array<string>>([] as Array<string>)
    const [editUsuario, setEditUsuario] = useState<string>('')
    const [editPassword, setEditPassword] = useState<string>('')
    const [editNombre, setEditNombre] = useState<string>('')
    const [editApellido, setEditApellido] = useState<string>('')
    const [editEmail, setEditEmail] = useState<string>('')
    const [editHabilitado, setEditHabilitado] = useState<number>(1)
    const [editTipoDeUsuario, setEditTipoDeUsuario] = useState<number>(1)
    const [editUltimoAcceso, setEditUltimoAcceso] = useState<string>('')
    const [editModulos, setEditModulos] = useState<Array<string>>([] as Array<string>)
    const [validaciones, setValidaciones] = useState<Validaciones>(validacionOk)

    const opcionesSiNo = [
        {id: 1, descripcion: 'Sí'},
        {id: 2, descripcion: 'No'}
    ] as Array<OpcionSelect>

    const tiposDeUsuario = [
        {id: 1, descripcion: 'Usuario normal'},
        {id: 2, descripcion: 'Sólo consulta'},
        {id: 3, descripcion: 'Administrador'}
    ] as Array<OpcionSelect>

    const {pedidoJson, errorCarga, actualizar, cargando} = useGsBackend()

    useEffect(() => {
        pedidoJson({
            url: 'tablas/usuarios', method: 'get', sinErrorDispatch: true, ok: res => {
                setUsuarios(res.usuarios)
                setPrefijo(res.prefijo)
                setInfoSuscripcion({
                    cantidad_usuarios: res.cantidad_usuarios,
                    maximo_usuarios: res.maximo_usuarios,
                    usuarios_disponibles: res.maximo_usuarios - res.cantidad_usuarios
                } as InfoSuscripcion)
                setModulosDisponibles(res.modulos_disponibles)
            }
        })
    }, [pedidoJson])

    const editarHandler = (arg: number | undefined): void => {
        if (usuarios === undefined) return

        if (arg === undefined) {
            setEditando(undefined)
        } else if (arg === 0) {
            setEditModulos([] as Array<string>)
            editUsuarioHandler('')
            setEditPassword('')
            setEditEmail('')
            setEditNombre('')
            setEditApellido('')
            setEditHabilitado(1)
            setEditTipoDeUsuario(1)
            setEditUltimoAcceso('')
            setValidaciones(validacionOk)
            setEditando(0)
        } else {
            const datos = usuarios.filter(u => u.id === arg)[0]
            if (datos === undefined) return

            setEditModulos([...datos.modulos])
            editUsuarioHandler(datos.usuario)
            setEditPassword('')
            setEditEmail(datos.email)
            setEditNombre(datos.nombre)
            setEditApellido(datos.apellido)
            setEditHabilitado(datos.habilitado ? 1 : 2)
            setEditTipoDeUsuario(datos.administrador ? 3 : datos.solo_consulta ? 2 : 1)
            setEditUltimoAcceso(datos.ultimoacceso)
            setValidaciones(validacionOk)
            setEditando(arg)
        }
    }

    const moduloHandler = (arg: string): void => {
        let actuales = [...editModulos]
        if (actuales.includes(arg)) {
            actuales = actuales.filter(t => t !== arg)
        } else {
            actuales.push(arg)
        }
        setEditModulos(actuales)
    }

    const editUsuarioHandler = (arg: string): void => {
        // Verificar que el prefijo sea el correcto
        const pre = `${prefijo}.`
        if (arg.startsWith(pre)) {
            setEditUsuario(arg)
        } else if (arg.length <= pre.length) {
            setEditUsuario(pre)
        } else {
            const pos = arg.indexOf('.')
            if (pos === -1) {
                setEditUsuario(pre + arg)
            } else {
                setEditUsuario(pre + arg.substring(pos + 1))
            }
        }
    }

    const guardarHandler = async (eliminar?: boolean): Promise<void> => {
        if (editando === undefined) return

        // Validar campos
        const pre = `${prefijo}.`
        const verif: Validaciones = {
            usuario: editUsuario !== '' && editUsuario !== pre && editUsuario.length > pre.length,
            clave: editando === 0 ? editPassword !== '' : true,
            nombre: editNombre !== '',
            apellido: editApellido !== '',
            email: editEmail !== ''
        }
        setValidaciones(verif)
        if (Object.values(verif).some(v => !v)) return

        const fd = new FormData()
        fd.append('accion', eliminar ? 'eliminar' : editando === 0 ? 'nuevo' : 'editar')
        fd.append('id', editando.toString())
        fd.append('usuario', editUsuario)
        fd.append('clave', editPassword)
        fd.append('nombre', editNombre)
        fd.append('apellido', editApellido)
        fd.append('email', editEmail)
        fd.append('habilitado', editHabilitado === 1 ? '1' : '0')
        fd.append('admin', editTipoDeUsuario === 3 ? '1' : '0')
        fd.append('solo_consulta', editTipoDeUsuario === 2 ? '1' : '0')
        fd.append('modulos', editModulos.join(','))

        pedidoJson({
            url: 'tablas/usuarios', method: 'post', body: fd,
            ok: () => {
                editarHandler(undefined)
                actualizar()
            }
        })
    }

    if (usuarios !== undefined) {
        return (
            <>
                <CuerpoPrincipal>
                    <h4>Usuarios del Sistema</h4>
                    <div className='row'>
                        <div className='col-auto'>
                            <Boton chico icono='fas fa-plus' titulo='Nuevo usuario'
                                   onClick={() => editarHandler(0)}/>
                        </div>
                        <div className='col'>
                            <i className='fas fa-info-circle text-info me-2'/>
                            <strong>{infoSuscripcion.cantidad_usuarios} de {infoSuscripcion.maximo_usuarios} </strong>
                            usuarios utilizados. {infoSuscripcion.usuarios_disponibles > 0 ?
                            <>
                                Quedan {infoSuscripcion.usuarios_disponibles} usuarios
                                disponibles en su suscripción.
                            </> : <>No quedan usuarios disponibles en su suscripción</>}

                        </div>
                    </div>
                </CuerpoPrincipal>
                <table className='table table-responsive table-striped table-hover'>
                    <thead>
                    <tr>
                        <th className='ps-3'>Usuario</th>
                        <th>Nombre</th>
                        <th>Apellido</th>
                        <th>E-Mail</th>
                        <th>Habilitado</th>
                        <th>Módulos habilitados</th>
                    </tr>
                    </thead>
                    <tbody>
                    {usuarios.length ? usuarios.map((u, i) =>
                        <tr className='cursorpointer' key={i} onClick={() => editarHandler(u.id)}>
                            <td className='ps-3'>
                                <strong>{u.usuario}</strong>
                                {u.administrador ? (
                                    <div className='small'>
                                        <i className='fas fa-users-cog text-warning me-2'/>
                                        Administrador
                                    </div>
                                ) : u.solo_consulta ? (
                                    <div className='small'>
                                        <i className='fas fa-search text-info me-2'/>
                                        Usuario de Consulta
                                    </div>
                                ) : <></>}
                            </td>
                            <td>{u.nombre}</td>
                            <td>{u.apellido}</td>
                            <td>{u.email}</td>
                            <td><CheckEstado estado={u.habilitado} textosino={true}/></td>
                            <td>
                                {u.modulos.filter(m => modulosDisponibles.includes(m)).length ? (
                                    <ul className='list-unstyled small m-0'>
                                        {u.modulos
                                            .filter(m => modulosDisponibles.includes(m))
                                            .map((m, k) => <li key={k}>
                                                {Modulos.filter(e => e.clave === m)[0]?.titulo || '(N/D)'}
                                            </li>)}
                                    </ul>
                                ) : <span className='text-muted small'>(Ninguno habilitado)</span>}
                            </td>
                        </tr>) : <tr>
                        <td colSpan={6}>
                            <i className='fas fa-exclamation-triangle text-warning me-2'/>
                            No se encontraron usuarios
                        </td>
                    </tr>}
                    </tbody>
                </table>
                {editando !== undefined && <Modal className='w-75'>
                    <div className='row'>
                        <div className='col-md-7'>
                            <h4>Datos del Usuario</h4>
                            <div className='row'>
                                <div className='col-md-6'>
                                    <InputTexto titulado titulo='Usuario' autoFocus invalido={!validaciones.usuario}
                                                value={editUsuario} onChange={editUsuarioHandler}/>
                                </div>
                                <div className='col-md-6'>
                                    <InputTexto titulado titulo='Contraseña' clave invalido={!validaciones.clave}
                                                placeholder={editando !== 0 ? '(Dejar en blanco para no cambiar)' : ''}
                                                value={editPassword} onChange={setEditPassword}/>
                                </div>
                            </div>
                            <div className='row'>
                                <div className='col-md-6'>
                                    <InputTexto titulado titulo='Nombre' invalido={!validaciones.nombre}
                                                value={editNombre} onChange={setEditNombre}/>
                                </div>
                                <div className='col-md-6'>
                                    <InputTexto titulado titulo='Apellido' invalido={!validaciones.apellido}
                                                value={editApellido} onChange={setEditApellido}/>
                                </div>
                            </div>
                            <div className='row'>
                                <div className='col-md-6'>
                                    <InputTexto titulado titulo='E-Mail' invalido={!validaciones.email}
                                                value={editEmail} onChange={setEditEmail}/>
                                </div>
                                <div className='col-md-3'>
                                    <InputSelect titulado titulo='Habilitar' sincero
                                                 value={editHabilitado} onChange={setEditHabilitado}
                                                 opciones={opcionesSiNo}/>
                                </div>
                                <div className='col-md-3'>
                                    <InputSelect titulado titulo='Tipo de Usuario' sincero
                                                 value={editTipoDeUsuario} onChange={setEditTipoDeUsuario}
                                                 opciones={tiposDeUsuario}/>
                                </div>
                            </div>
                            {editUltimoAcceso !== '' &&
                                <div className='mt-2'>
                                    <i className='fas fa-info-circle text-info me-2'/>
                                    <strong>Último acceso al Sistema: </strong>{editUltimoAcceso}
                                </div>}
                            {editHabilitado !== 1 &&
                                <div className='mt-2'>
                                    <i className='fas fa-times-circle text-danger me-2'/>
                                    El usuario no estará habilitado para acceder al Sistema
                                </div>}
                            {editTipoDeUsuario === 3 &&
                                <div className='mt-2'>
                                    <i className='fas fa-exclamation-triangle text-warning me-2'/>
                                    El usuario tendrá permisos de Administrador
                                </div>}
                        </div>
                        <div className='col-md-5'>
                            <h4>Módulos habilitados</h4>
                            <div className='list-group'>
                                {Modulos.filter(m => modulosDisponibles.includes(m.clave))
                                    .map((m, i) => {
                                        const activa = editModulos.includes(m.clave)
                                        return <li className={'list-group-item list-group-item-action ' +
                                            'small cursorpointer' + (activa ? ' list-group-item-success' : '')} key={i}
                                                   onClick={() => moduloHandler(m.clave)}>
                                            <input type='checkbox' className='form-check-input me-2' checked={activa}
                                                   readOnly style={{cursor: 'pointer'}}/>
                                            <i className={m.icono + ' me-2 text-primary'}/>{m.titulo}
                                        </li>
                                    })}
                            </div>
                        </div>
                    </div>
                    <ModalBotones editando={editando !== 0} guardando={cargando}
                                  onCancelar={() => editarHandler(undefined)}
                                  onGuardar={() => guardarHandler()}
                                  onEliminar={() => guardarHandler(true)}/>
                </Modal>}
            </>
        )
    } else {
        return <BodyCarga error={errorCarga} onReintentar={actualizar}/>
    }
}

export default ConfiguracionUsuarios