import React, {useEffect, useState} from "react";
import {
    DatosEmpleado,
    DatosHorarioSemanal,
    FichadaLegajoItem, InasistenciaLegajoItem,
    RequisitoLegajoItem,
    SancionLegajoItem,
    VacacionesLegajoItem
} from "./interfacesLegajo";
import FotoPlaceholder from "./foto-placeholder.jpg";
import Boton from "../controles/Boton";
import EstadoVacaciones from "./EstadoVacaciones";
import EstadoMensaje from "../controles/EstadoMensaje";
import RequisitosLegajo from "./RequisitosLegajo";
import InasistenciasLegajo from "./InasistenciasLegajo";
import SancionesLegajo from "./SancionesLegajo";
import HorarioSemanal from "./HorarioSemanal";
import FichadasLegajo from "./FichadasLegajo";
import FVOpcional from "../controles/FVOpcional";
import FHOpcional from "../controles/FHOpcional";
import BotonIcono from "../controles/BotonIcono";
import VacacionesLegajo from "./VacacionesLegajo";
import BodyCarga from "../controles/BodyCarga";
import IconoCarga from "../controles/IconoCarga";
import {useAppSelector} from "../redux/hooks";
import {useNavigate} from "react-router-dom";
import CuerpoPrincipal from "../controles/CuerpoPrincipal";
import {useGsBackend} from "../funcionesApi";

type Props = {
    idEmpleado: number,
}

const ResumenLegajo: React.FC<Props> = ({idEmpleado}) => {
    const [datos, setDatos] = useState<DatosEmpleado>({} as DatosEmpleado)
    const [horarioSemanal, setHorarioSemanal] = useState<Array<DatosHorarioSemanal>>([] as Array<DatosHorarioSemanal>)
    const [sanciones, setSanciones] = useState<Array<SancionLegajoItem>>([] as Array<SancionLegajoItem>)
    const [fichadas, setFichadas] = useState<Array<FichadaLegajoItem>>([] as Array<FichadaLegajoItem>)
    const [requisitos, setRequisitos] = useState<Array<RequisitoLegajoItem>>([] as Array<RequisitoLegajoItem>)
    const [vacaciones, setVacaciones] = useState<Array<VacacionesLegajoItem>>([] as Array<VacacionesLegajoItem>)
    const [inasistencias, setInasistencias] =
        useState<Array<InasistenciaLegajoItem>>([] as Array<InasistenciaLegajoItem>)
    const [tieneFoto, setTieneFoto] = useState<boolean>(false)
    const [fotoEmpleado, setFotoEmpleado] = useState<string>('')
    const edita = useAppSelector(state => state.auth.edicion)
    const navigate = useNavigate()
    const numIdEmpleado = idEmpleado

    const {pedidoJson, pedidoBlob, errorCarga, actualizar} = useGsBackend()
    useEffect(() => {
        pedidoJson({
            url: `legajos/empleados/${numIdEmpleado}`, sinErrorDispatch: true,
            ok: res => {
                // Si no habia un Id valido volver a la lista de empleados
                if (res.empleado === undefined) navigate('../../')

                setDatos(res.empleado)
                setHorarioSemanal(res.horariosemanal)
                setSanciones(res.sanciones)
                setFichadas(res.fichadas)
                setRequisitos(res.requisitos)
                setVacaciones(res.vacaciones)
                setInasistencias(res.inasistencias)

                // Cargar foto del empleado
                if (res.empleado.foto !== undefined && res.empleado.foto !== '') {
                    setTieneFoto(true)
                    pedidoBlob({
                        url: `archivos?tipo=fotos_empleados&id_empleado=${numIdEmpleado}`,
                        ok: rf => setFotoEmpleado(URL.createObjectURL(rf))
                    })
                }
            }
        })
    }, [numIdEmpleado, navigate, pedidoJson, pedidoBlob])

    if (datos.apellido !== undefined) {
        const nombreCompleto = `${datos.apellido}, ${datos.nombre}`

        return (
            <CuerpoPrincipal>
                <div className='row'>
                    <div className='col-md-2'>
                        {tieneFoto ? (
                            fotoEmpleado !== '' ? <p>
                                <img src={fotoEmpleado} className='w-100' alt={nombreCompleto}/>
                            </p> : <div className='border mb-3 pt-3'>
                                <IconoCarga chico/>
                                <div className='mb-3 text-center'>Cargando foto</div>
                            </div>
                        ) : <p><img src={FotoPlaceholder} className='w-100' alt={nombreCompleto}/></p>}
                        {edita && <p>
                            <Boton chico titulo='Editar Legajo' icono='fas fa-edit'
                                   className='btn-primary w-100' onClick={() => navigate('editar')}/>
                        </p>}
                        <table className='table table-sm table-responsive table-bordered table-striped small'>
                            <tbody>
                            <FVOpcional titulo='Teléfono' valor={datos.telefono}/>
                            <FVOpcional titulo='Domicilio' valor={datos.domicilio}/>
                            </tbody>
                        </table>
                    </div>
                    <div className='col-md-10'>
                        <div className='row'>
                            <div className='col-sm-7'>
                                {
                                    !datos.activo && <h5 className='mb-0 text-danger'>
                                        <i className='fas fa-info-circle me-2'/>
                                        Inactivo
                                    </h5>
                                }
                                <h3 className={datos.activo ? '' : 'text-muted'}>{nombreCompleto}</h3>
                                <EstadoVacaciones vacaciones={datos.vacaciones}/>
                                <EstadoMensaje estado={datos.estado} mensajes={datos.alertas}
                                               mensajeOk='Todo está en orden'/>
                                <h4 className='mt-3'>
                                    Requisitos&nbsp;
                                    <BotonIcono onClick={() => navigate('requisitos')}/>
                                </h4>
                                <RequisitosLegajo id_empleado={numIdEmpleado} requisitos={requisitos}/>
                                {vacaciones.length ? (
                                    <>
                                        <h4 className='mt-3'>
                                            Próximas Vacaciones&nbsp;
                                            <BotonIcono onClick={() => navigate('vacaciones')}/>
                                        </h4>
                                        <VacacionesLegajo vacaciones={vacaciones}/>
                                    </>
                                ) : <></>}
                                {datos.tipo_horario !== 1 && <>
                                    <h4 className='mt-3'>
                                        Inasistencias del mes&nbsp;
                                        <BotonIcono onClick={() => navigate('horario')}/>
                                    </h4>
                                    <InasistenciasLegajo inasistencias={inasistencias}/>
                                </>}
                                <h4 className='mt-3'>
                                    Sanciones&nbsp;
                                    <BotonIcono onClick={() => navigate('sanciones')}/>
                                </h4>
                                <SancionesLegajo sanciones={sanciones}/>
                            </div>
                            <div className='col-sm-5'>
                                <h4>Nro. Legajo: {datos.nro_legajo}</h4>
                                <table className='table table-sm table-responsive table-bordered table-striped small'>
                                    <tbody>
                                    <FHOpcional titulo='Motivo de la Baja' valor={datos.motivo_baja}/>
                                    <FHOpcional titulo='Fecha de la Baja' valor={datos.fecha_baja}/>
                                    <FHOpcional titulo='Categoría' valor={datos.categoria}/>
                                    <FHOpcional titulo='Ubicación' valor={datos.ubicacion}/>
                                    <FHOpcional titulo='CUIL' valor={datos.cuil}/>
                                    <FHOpcional titulo='Estado Civil' valor={datos.estadocivil}/>
                                    <FHOpcional titulo='Fecha de Nacimiento' valor={datos.fechanacimiento}/>
                                    <FHOpcional titulo='Fecha de Inicio' valor={datos.fechainicio}/>
                                    <FHOpcional titulo='Banco' valor={datos.banco}/>
                                    <FHOpcional titulo='Cuenta Haberes' valor={datos.cuenta_haberes}/>
                                    <FHOpcional titulo='Antigüedad' valor={datos.antiguedad}/>
                                    <FHOpcional titulo='Días de Vacaciones' valor={datos.diasdevacaciones}/>
                                    </tbody>
                                </table>
                                {datos.tipo_horario !== 1 && <>
                                    <h4 className='mt-3'>Horario Semanal</h4>
                                    <HorarioSemanal horariosemanal={horarioSemanal}/>
                                    <hr/>
                                    <h4>
                                        Últimas Fichadas&nbsp;
                                        <BotonIcono onClick={() => navigate('horario')}/>
                                    </h4>
                                    <FichadasLegajo fichadas={fichadas}/>
                                </>}
                            </div>
                        </div>
                    </div>
                </div>
            </CuerpoPrincipal>
        )
    } else {
        return <BodyCarga error={errorCarga} onReintentar={actualizar}/>
    }
}

export default ResumenLegajo
