import React from "react";

type Props = {
    titulo: string,
    valor: string
}

const FVOpcional: React.FC<Props> = ({titulo, valor}) => {
    return valor === '' ? <></> :
        <>
            <tr>
                <td className='fw-bold'>{titulo}</td>
            </tr>
            <tr>
                <td>{valor}</td>
            </tr>
        </>
}

export default FVOpcional
