import React, {useEffect, useState} from "react";
import Modal from "../controles/Modal";
import ModalBotones from "../controles/ModalBotones";
import IconoCarga from "../controles/IconoCarga";
import BotonIcono from "../controles/BotonIcono";
import InputTexto from "../controles/InputTexto";
import {useGsBackend} from "../funcionesApi";

type DatosVacaciones = {
    id: number,
    anios_desde: number,
    dias: number
}

type Props = {
    onCancelar: () => void
}

const ConfiguracionVacaciones: React.FC<Props> = ({onCancelar}) => {
    const [datos, setDatos] = useState<Array<DatosVacaciones>>([] as Array<DatosVacaciones>)
    const [nuevoAnios, setNuevoAnios] = useState<string>('0')
    const [nuevoDias, setNuevoDias] = useState<string>('0')
    const [validoAnios, setValidoAnios] = useState<boolean>(true)

    const {pedidoJson, actualizar, cargando} = useGsBackend()
    useEffect(() => {
        pedidoJson({url: 'vacaciones/configuracion', ok: res => setDatos(res.tabla_vacaciones)})
    }, [pedidoJson])

    const edicionHandler = async (eliminar: number | undefined): Promise<void> => {
        if (!eliminar) {
            const aniosOk = nuevoAnios !== '0'
            setValidoAnios(aniosOk)
            if (!aniosOk) return
        }

        const fd = new FormData()
        fd.append('accion', eliminar ? 'eliminar' : 'nuevo')
        fd.append('id', eliminar ? eliminar.toString() : '0')
        fd.append('anios', nuevoAnios)
        fd.append('dias', nuevoDias)

        pedidoJson({
            url: 'vacaciones/configuracion', method: 'post', body: fd,
            ok: () => {
                setNuevoAnios('0')
                setNuevoDias('0')
                actualizar()
            }
        })
    }

    return (
        <Modal className='w-25'>
            <h4>Configuración de Vacaciones</h4>
            {cargando ? <IconoCarga/> : (
                <table className='table table-responsive table-striped table-bordered table-sm'>
                    <thead>
                    <tr>
                        <th style={{width: '50%'}}>Años desde</th>
                        <th style={{width: '50%'}}>Días</th>
                        <th>&nbsp;</th>
                    </tr>
                    </thead>
                    <tbody>
                    {datos.map((d, i) => (
                        <tr key={i}>
                            <td className='text-end'>{d.anios_desde}</td>
                            <td className='text-end'>{d.dias}</td>
                            <td className='text-end'>
                                <BotonIcono icono='fas fa-trash' estilo='btn-light text-danger'
                                            onClick={() => edicionHandler(d.id)}/>
                            </td>
                        </tr>
                    ))}
                    <tr>
                        <td>
                            <InputTexto numerico chico value={nuevoAnios} onChange={setNuevoAnios}
                                        invalido={!validoAnios}/>
                        </td>
                        <td>
                            <InputTexto numerico chico value={nuevoDias} onChange={setNuevoDias}/>
                        </td>
                        <td>
                            <BotonIcono icono='fas fa-plus' estilo='btn-light text-success'
                                        onClick={() => edicionHandler(undefined)}/>
                        </td>
                    </tr>
                    </tbody>
                </table>
            )}
            <ModalBotones soloCancelar onCancelar={onCancelar}/>
        </Modal>
    )
}

export default ConfiguracionVacaciones