import React, {useState} from "react";
import Modal from "../controles/Modal";
import ModalBotones from "../controles/ModalBotones";
import TarjetaSeccion from "../controles/TarjetaSeccion";
import InputTexto from "../controles/InputTexto";
import InputFecha from "../controles/InputFecha";
import InputSelect, {OpcionSelect} from "../controles/InputSelect";
import InputArchivo from "../controles/InputArchivo";
import {useGsBackend} from "../funcionesApi";
import {LocalDate} from "js-joda";

type Props = {
    onGuardar: () => void,
    onCancelar: () => void,
    proveedores: Array<OpcionSelect>,
    destinos: Array<OpcionSelect>,
    sectores: Array<OpcionSelect>
}

type Edicion = {
    descripcion: string,
    fecha_inicio: string,
    fecha_fin_estimada: string,
    id_sector: number,
    id_destino: number,
    cantidad: number,
    id_proveedor: number,
    observaciones: string,
    documento?: File
}

type Validacion = {
    descripcion: boolean,
    cantidad: boolean,
}

const TrazabilidadNuevo: React.FC<Props> = (
    {onGuardar, onCancelar, proveedores, destinos, sectores}
) => {
    const fechaActual = new Date().toISOString().substring(0, 10)
    const edicionInicial: Edicion = {
        descripcion: '', id_sector: 0, id_destino: 0, cantidad: 0, id_proveedor: 0, observaciones: '',
        fecha_inicio: fechaActual, fecha_fin_estimada: fechaActual
    }
    const validacionInicial: Validacion = {
        descripcion: true, cantidad: true
    }
    const [edicion, setEdicion] = useState<Edicion>(edicionInicial)
    const [valid, setValid] = useState<Validacion>(validacionInicial)
    const {pedidoJson} = useGsBackend()

    const fechaHandler = (campo: 'inicio' | 'fin', valor: string): void => {
        if (campo === 'inicio') {
            const nuevoValor = valor === '' ? fechaActual : valor
            const ld = LocalDate
            const fi = ld.parse(nuevoValor)
            const ff = ld.parse(edicion.fecha_fin_estimada)
            setEdicion({
                ...edicion,
                fecha_inicio: nuevoValor,
                fecha_fin_estimada: fi.isAfter(ff) ? nuevoValor : edicion.fecha_fin_estimada
            })
        } else if (campo === 'fin') {
            if (valor === '') {
                setEdicion({...edicion, fecha_fin_estimada: edicion.fecha_inicio})
            } else {
                const ld = LocalDate
                const fi = ld.parse(edicion.fecha_inicio)
                const ff = ld.parse(valor)
                const nuevoValor = ff.isBefore(fi) ? edicion.fecha_inicio : valor
                setEdicion({...edicion, fecha_fin_estimada: nuevoValor})
            }
        }
    }

    const guardarHandler = () => {
        const vld: Validacion = {
            descripcion: edicion.descripcion !== '',
            cantidad: edicion.cantidad !== 0
        }
        setValid(vld)
        if (!vld.descripcion || !vld.cantidad) return

        const fd = new FormData()
        fd.append('accion', 'nuevo')
        fd.append('descripcion', edicion.descripcion)
        fd.append('cantidad', edicion.cantidad.toString())
        fd.append('fecha_inicio', edicion.fecha_inicio)
        fd.append('fecha_fin_estimada', edicion.fecha_fin_estimada)
        fd.append('id_sector', edicion.id_sector.toString())
        fd.append('id_destino', edicion.id_destino.toString())
        fd.append('id_proveedor', edicion.id_proveedor.toString())
        fd.append('observaciones', edicion.observaciones)
        if (edicion.documento !== undefined) fd.append('archivo', edicion.documento, edicion.documento.name)

        pedidoJson({
            url: 'trazabilidad/nuevo', method: 'post', body: fd,
            ok: onGuardar
        })
    }

    return (
        <Modal className='w-75'>
            <h4>Nuevo elemento</h4>
            <TarjetaSeccion>
                <div className='row'>
                    <InputTexto titulado titulo='Descripción' value={edicion.descripcion} autoFocus
                                onChange={v => setEdicion({...edicion, descripcion: v})}
                                invalido={!valid.descripcion}/>
                </div>
                <div className='row'>
                    <div className='col-md-4'>
                        <InputTexto numerico value={edicion.cantidad.toString()} izquierda
                                    titulado titulo='Cantidad' invalido={!valid.cantidad}
                                    onChange={v => setEdicion({...edicion, cantidad: parseInt(v) || 0})}/>
                    </div>
                    <div className='col-md-4'>
                        <InputFecha value={edicion.fecha_inicio} titulado titulo='Fecha de Inicio'
                                    onChange={v => fechaHandler('inicio', v)}/>
                    </div>
                    <div className='col-md-4'>
                        <InputFecha value={edicion.fecha_fin_estimada} titulado titulo='Fecha de Finalización'
                                    onChange={v => fechaHandler('fin', v)}/>
                    </div>
                </div>
                <div className='row'>
                    <div className='col-md-4'>
                        <InputSelect titulado titulo='Sector' value={edicion.id_sector} opciones={sectores}
                                     onChange={v => setEdicion({...edicion, id_sector: v})}/>
                    </div>
                    <div className='col-md-4'>
                        <InputSelect titulado titulo='Origen / Maquinaria'
                                     value={edicion.id_destino} opciones={destinos}
                                     onChange={v => setEdicion({...edicion, id_destino: v})}/>
                    </div>
                    <div className='col-md-4'>
                        <InputSelect titulado titulo='Proveedor' value={edicion.id_proveedor} opciones={proveedores}
                                     onChange={v => setEdicion({...edicion, id_proveedor: v})}/>
                    </div>
                </div>
            </TarjetaSeccion>
            <TarjetaSeccion>
                <div className='row mb-2'>
                    <div className='col-md-3 pt-1'>Observaciones</div>
                    <div className='col-md-9'>
                        <InputTexto value={edicion.observaciones}
                                    onChange={v => setEdicion({...edicion, observaciones: v})}/>
                    </div>
                </div>
                <div className='row'>
                    <div className='col-md-3 pt-1'>
                        Documento <small className='text-muted'>(Opcional)</small>
                    </div>
                    <div className='col-md-9'>
                        <InputArchivo onChange={v => setEdicion({...edicion, documento: v})}/>
                    </div>
                </div>
            </TarjetaSeccion>
            <ModalBotones onCancelar={onCancelar} onGuardar={guardarHandler}/>
        </Modal>
    )
}

export default TrazabilidadNuevo