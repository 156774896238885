import React, {useCallback, useEffect, useState} from "react";
import BarraSuperior from "../controles/BarraSuperior";
import ToolbarBtn from "../controles/ToolbarBtn";
import Boton from "../controles/Boton";
import TrazabilidadNuevo from "./TrazabilidadNuevo";
import InputSelect, {OpcionSelect} from "../controles/InputSelect";
import {useGsBackend} from "../funcionesApi";
import BodyCarga from "../controles/BodyCarga";
import CuerpoPrincipal from "../controles/CuerpoPrincipal";
import InputTexto from "../controles/InputTexto";
import TrInfo from "../controles/TrInfo";
import TextoND from "../controles/TextoND";
import VistaEstadosTrazabilidad from "./VistaEstadosTrazabilidad";
import estadosTrazabilidad from "./estadosTrazabilidad";
import {useNavigate} from "react-router-dom";

export interface ElementoTrazabilidad {
    id: number,
    cod_seguimiento: string,
    descripcion: string,
    cantidad: number,
    fecha_inicio: string,
    fecha_inicio_fmt: string,
    fecha_fin_estimada: string,
    fecha_fin_estimada_fmt: string,
    fecha_fin_real: string,
    fecha_fin_real_fmt: string,
    diferencia_fecha_fin: number,
    id_sector: number,
    sector: string,
    id_destino: number,
    destino: string,
    id_proveedor: number,
    proveedor: string,
    fecha_ultimo_movimiento_fmt: string,
    estado: number,
    observaciones: string,
    usuario: string,
    nombre_usuario: string
}

type Datos = {
    elementos: Array<ElementoTrazabilidad>,
    proveedores: Array<OpcionSelect>,
    destinos: Array<OpcionSelect>,
    sectores: Array<OpcionSelect>
}

type Filtros = {
    descripcion: string,
    id_proveedor: number,
    id_sector: number,
    id_destino: number,
    estado: number
}

const Trazabilidad: React.FC = () => {
    const filtroInicial: Filtros = {descripcion: '', id_destino: 0, id_proveedor: 0, id_sector: 0, estado: 0}
    const [datos, setDatos] = useState<Datos | undefined>(undefined)
    const [filtros, setFiltros] = useState<Filtros>(filtroInicial)
    const [muestra, setMuestra] = useState<Array<ElementoTrazabilidad>>([])
    const [nuevo, setNuevo] = useState<boolean>(false)
    const navigate = useNavigate()

    const {pedidoJson, errorCarga, actualizar} = useGsBackend()

    useEffect(() => {
        pedidoJson({url: 'trazabilidad', method: 'get', ok: setDatos})
    }, [pedidoJson])

    const filtrar = useCallback((el: Array<ElementoTrazabilidad>): void => {
        if (filtros.descripcion !== '') el = el.filter(d => d.descripcion.toLocaleLowerCase()
                .includes(filtros.descripcion.toLocaleLowerCase()) ||
            d.cod_seguimiento.toLocaleLowerCase().includes(filtros.descripcion.toLocaleLowerCase()))
        if (filtros.id_proveedor !== 0) el = el.filter(d => d.id_proveedor === filtros.id_proveedor)
        if (filtros.id_destino !== 0) el = el.filter(d => d.id_destino === filtros.id_destino)
        if (filtros.id_sector !== 0) el = el.filter(d => d.id_sector === filtros.id_sector)
        if (filtros.estado !== 0) el = el.filter(d => d.estado === filtros.estado)

        setMuestra(el)
    }, [filtros])

    useEffect(() => {
        if (datos !== undefined) filtrar(datos.elementos)
    }, [filtrar, datos])

    const vistaDiferenciaFecha = (diferencia: number): string => {
        if (diferencia < 0) {
            return `Hace ${-diferencia} días`
        } else if (diferencia > 0) {
            return `En ${diferencia} días`
        } else {
            return 'Hoy'
        }
    }

    return (
        <div>
            <BarraSuperior>
                <ToolbarBtn>
                    <Boton toolbar titulo='Nuevo elemento' icono='fas fa-plus' onClick={() => setNuevo(true)}/>
                    <Boton toolbar titulo='Configuración' icono='fas fa-cog'/>
                </ToolbarBtn>
            </BarraSuperior>
            {datos === undefined ? (
                <BodyCarga error={errorCarga} onReintentar={actualizar}/>
            ) : (
                <>
                    <CuerpoPrincipal sinEspacio>
                        <div className='d-flex align-items-center'>
                            <div className='pe-3'><i className='fas fa-search text-primary'/></div>
                            <div className='flex-grow-1'>
                                <div className='row g-2 mb-2'>
                                    <div className='col-md-8'>
                                        <InputTexto chico titulo='Código o Descripción' value={filtros.descripcion}
                                                    placeholder='(Todos los elementos)'
                                                    onChange={v => setFiltros({...filtros, descripcion: v})}/>
                                    </div>
                                    <div className='col-md-4'>
                                        <InputSelect chico titulo='Estado' textocero='(Todos)' value={filtros.estado}
                                                     onChange={v => setFiltros({...filtros, estado: v})}
                                                     opciones={estadosTrazabilidad.map(e => {
                                                         return {
                                                             id: e.id,
                                                             descripcion: e.descripcion
                                                         } as OpcionSelect
                                                     })}/>
                                    </div>
                                </div>
                                <div className='row g-2 mb-2'>
                                    <div className='col-md-4'>
                                        <InputSelect chico titulo='Sector' textocero='(Todos)' forzarCero
                                                     value={filtros.id_sector} opciones={datos.sectores}
                                                     onChange={v => setFiltros({...filtros, id_sector: v})}/>
                                    </div>
                                    <div className='col-md-4'>
                                        <InputSelect chico titulo='Origen / Maquinaria' textocero='(Todos)' forzarCero
                                                     value={filtros.id_destino} opciones={datos.destinos}
                                                     onChange={v => setFiltros({...filtros, id_destino: v})}/>
                                    </div>
                                    <div className='col-md-4'>
                                        <InputSelect chico titulo='Proveedor' textocero='(Todos)' forzarCero
                                                     value={filtros.id_proveedor} opciones={datos.proveedores}
                                                     onChange={v => setFiltros({...filtros, id_proveedor: v})}/>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </CuerpoPrincipal>
                    <table className='table table-responsive table-striped'>
                        <thead>
                        <tr>
                            <th className='ps-3'>Código y Descripción</th>
                            <th>Ingreso</th>
                            <th>Sector</th>
                            <th>Origen</th>
                            <th>Proveedor</th>
                            <th>Finalización</th>
                            <th>Estado</th>
                            <th>Observaciones</th>
                        </tr>
                        </thead>
                        <tbody>
                        {muestra.length ? muestra.map((d, k) => (
                            <tr key={k} className='small celdahover' onClick={() => navigate(d.id.toString())}>
                                <td>
                                    <div className='d-flex align-items-center'>
                                        <div className='ps-2 pe-3'>
                                            <span className='badge bg-dark'>{d.cod_seguimiento}</span>
                                        </div>
                                        <div className='flex-grow-1'>
                                            <strong>{d.descripcion}</strong><br/>
                                            <small>
                                                {d.cantidad === 1 ? '1 unidad' : `${d.cantidad} unidades`}
                                            </small>
                                        </div>
                                    </div>
                                </td>
                                <td>
                                    {d.fecha_inicio_fmt}<br/>
                                    <small><strong>{d.usuario}</strong> ({d.nombre_usuario})</small>
                                </td>
                                <td><TextoND texto={d.sector}/></td>
                                <td><TextoND texto={d.destino}/></td>
                                <td><TextoND texto={d.proveedor}/></td>
                                {d.fecha_fin_real_fmt !== '' ? (
                                    <td>{d.fecha_fin_real_fmt}</td>
                                ) : (
                                    <td>
                                        {d.fecha_fin_estimada_fmt}<br/>
                                        <small>
                                            <i className='fas fa-caret-right text-muted me-2'/>
                                            <strong>{vistaDiferenciaFecha(d.diferencia_fecha_fin)}</strong>
                                        </small>
                                    </td>
                                )}
                                <td><VistaEstadosTrazabilidad estado={d.estado}/></td>
                                <td>{d.observaciones}</td>
                            </tr>
                        )) : (
                            <TrInfo colSpan={8} texto='No hay elementos para mostrar'/>
                        )}
                        </tbody>
                    </table>
                    {nuevo && (
                        <TrazabilidadNuevo proveedores={datos.proveedores} destinos={datos.destinos}
                                           sectores={datos.sectores}
                                           onGuardar={() => {
                                               setNuevo(false)
                                               actualizar()
                                           }}
                                           onCancelar={() => setNuevo(false)}/>
                    )}
                </>
            )}
        </div>
    )
}

export default Trazabilidad