import React from "react";

type BotonProps = {
    className?: string,
    chico?: boolean,
    toolbar?: boolean,
    icono?: string,
    titulo: string,
    onClick?: React.MouseEventHandler<HTMLButtonElement>,
    onMouseEnter?: React.MouseEventHandler<HTMLButtonElement>,
    onMouseLeave?: React.MouseEventHandler<HTMLButtonElement>,
    disabled?: boolean
}

/**
 * Funcion generadora de un botón simple, con o sin ícono
 * @param props
 * @returns {JSX.Element}
 * @constructor
 */
const Boton: React.FC<BotonProps> = (
    {
        className, chico, toolbar, icono, titulo,
        onClick, onMouseEnter, onMouseLeave, disabled
    }
) => {
    const estilo = 'btn ' + (className ? className : 'btn-primary') + ' ' +
        ((chico || toolbar) && 'btn-sm')

    const boton = <button type='button' className={estilo} disabled={disabled} onClick={onClick}
                          onMouseEnter={onMouseEnter} onMouseLeave={onMouseLeave}>
        {icono && <i className={icono + ' me-2'}/>}
        {titulo}
    </button>

    if (toolbar) {
        return (
            <div className='btn-group btn-group-sm me-2'>{boton}</div>
        )
    } else {
        return boton
    }
}

export default Boton
