import React, {useEffect} from 'react'
import './css/bs-plantas.scss'
import './css/App.css'
import '../node_modules/@fortawesome/fontawesome-free/css/all.css'
import {useAppSelector} from "./redux/hooks";
import {AuthState} from "./redux/authReducer";
import PantallaPrincipal from "./interfaz/PantallaPrincipal";
import PantallaLogin from "./login/PantallaLogin";
import {Outlet} from "react-router-dom";
import VistaModo from "./controles/VistaModo";

const App: React.FC = () => {
    const auth: AuthState = useAppSelector(state => state.auth)

    const prevenirMenuContextual = (e: MouseEvent): void => e.preventDefault()

    useEffect(() => {
        document.addEventListener('contextmenu', prevenirMenuContextual, false)
        return () => document.removeEventListener('contextmenu', prevenirMenuContextual, false)
    })

    return (
        <div>
            <VistaModo/>
            {auth.token === undefined ? (
                <PantallaLogin/>
            ) : (
                <PantallaPrincipal outlet={<Outlet/>}/>
            )}
        </div>
    )
}

export default App
