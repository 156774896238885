import React, {useEffect, useMemo, useState} from "react";
import {GsRequestParams, useGsBackend} from "../funcionesApi";

export interface InfoTooltip {
    texto?: string,
    urlPreview?: string
}

type Props = {
    datos: InfoTooltip
}

type Posicion = {
    x: number,
    y: number
}

const Tooltip: React.FC<Props> = ({datos}) => {
    const [pos, setPos] = useState<Posicion | undefined>(undefined)
    const [img, setImg] = useState<string | undefined>(undefined)

    const infoApi = useMemo<GsRequestParams>(() => {
        return {url: datos.urlPreview, ok: res => setImg(res.datos)} as GsRequestParams
    }, [datos.urlPreview])
    const {pedidoJson} = useGsBackend()

    useEffect(() => {
        pedidoJson(infoApi)
    }, [pedidoJson, infoApi])

    useEffect(() => {
        document.addEventListener('mousemove', actualizarPosicion)
        return () => {
            document.removeEventListener('mousemove', actualizarPosicion)
        }
    })

    const actualizarPosicion = (e: MouseEvent) =>
        setPos({x: e.clientX, y: e.clientY + 30} as Posicion)

    const contenido = (): React.ReactNode => {
        const hayPreview = datos.urlPreview !== undefined
        if (hayPreview && img !== undefined && img !== '') {
            return <img src={`data:image/webp;base64,${img}`} alt='Previsualización' className='rounded-1'/>
        } else if (hayPreview && img === '') {
            return (
                <div className='text-center'>
                    <div className='text-nowrap py-2'>
                        <i className='fas fa-search text-primary me-2'/>
                        <i className='fas fa-caret-right text-muted me-3'/>
                        <i className='fas fa-file text-muted'/>
                    </div>
                    <div style={{fontSize: '0.75em'}}>
                        Previsualización<br/>
                        aún no disponible
                    </div>
                </div>
            )
        } else if (hayPreview && img === undefined) {
            return <div className='spinner-border spinner-border-sm text-primary mx-2 my-1'/>
        } else {
            return <small>{datos.texto}</small>
        }
    }

    return pos === undefined ? <></> : (
        <div className='bg-white border shadow-sm rounded-3 p-1'
             style={{
                 position: 'fixed', left: pos.x, top: pos.y, zIndex: 9999,
                 transform: 'translatex(-30%)'
             }}>{contenido()}</div>
    )
}

export default Tooltip