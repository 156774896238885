import React from "react";
import {AuthState} from "../redux/authReducer";
import {useAppSelector} from "../redux/hooks";
import LogoSistemas from "./LogoSistemas";

const LogoCliente: React.FC = () => {
    const auth: AuthState = useAppSelector(state => state.auth)
    if (auth.logo === undefined || auth.logo === '') {
        return <LogoSistemas chico/>
    } else {
        return (
            <img src={`data:image/png;base64,${auth.logo}`} alt={auth.cliente} style={{width: '140px'}}/>
        )
    }
}

export default LogoCliente