import Modulos from "../modulos";
import React, {useEffect, useRef} from "react";
import {useAppDispatch, useAppSelector} from "../redux/hooks";
import {AuthState} from "../redux/authReducer";
import LogoCliente from "../controles/LogoCliente";
import BotonDesloguear from "../controles/BotonDesloguear";
import {setAlturaHeader} from "../redux/estadoReducer";

type TituloPaginaProps = {
    pagina: string
}

const TituloPagina: React.FC<TituloPaginaProps> = ({pagina}) => {
    const auth: AuthState = useAppSelector(state => state.auth)
    const dispatch = useAppDispatch()

    const tituloRef = useRef<HTMLDivElement>(null)
    const alturaActual = useRef<number>(0)

    useEffect(() => {
        const rh = () => {
            if (tituloRef.current === null) return

            const h = tituloRef.current.clientHeight
            if (alturaActual.current !== h) {
                alturaActual.current = h
                dispatch(setAlturaHeader(h))
            }
        }
        rh()
        window.addEventListener('resize', rh)
        return () => window.removeEventListener('resize', rh)
    })

    const info = Modulos.filter(mod => mod.clave === pagina)
    if (info.length === 1) {
        const mod = info[0]

        return (
            <div className='sticky-top bg-white px-3 border-bottom' ref={tituloRef}>
                <div className='row pt-3 pb-2'>
                    <div className='col'>
                        <div className='row g-3'>
                            <div className='col-auto text-primary' style={{fontSize: '2em'}}>
                                <i className={mod.icono}/>
                            </div>
                            <div className='col-auto'>
                                <div style={{fontSize: '2em'}}>{mod.titulo}</div>
                            </div>
                        </div>
                    </div>
                    <div className='col-auto text-nowrap text-end small'>
                        <div><strong>{auth.nombrecompleto}</strong></div>
                        <BotonDesloguear/>
                    </div>
                    <div className='col-auto'><LogoCliente/></div>
                </div>
            </div>
        )
    } else {
        return <></>
    }
}

export default TituloPagina
