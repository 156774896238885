import React, {useState} from "react";
import Modal from "../controles/Modal";
import ModalBotones from "../controles/ModalBotones";
import TarjetaSeccion from "../controles/TarjetaSeccion";
import InputTexto from "../controles/InputTexto";
import InputSelect, {OpcionSelect} from "../controles/InputSelect";
import estadosTrazabilidad from "./estadosTrazabilidad";
import InputArchivo from "../controles/InputArchivo";
import {useGsBackend} from "../funcionesApi";

type Props = {
    idTrazabilidad: number,
    estadoPrevio: number,
    onCancelar: () => void,
    onGuardar: () => void
}

type Edicion = {
    descripcion: string,
    nuevo_estado: number,
    documento?: File
}

type Validacion = {
    descripcion: boolean
}

const TrazabilidadIngresarMovimiento: React.FC<Props> = (
    {idTrazabilidad, estadoPrevio, onCancelar, onGuardar}
) => {
    const [edicion, setEdicion] = useState<Edicion>({descripcion: '', nuevo_estado: estadoPrevio})
    const [valid, setValid] = useState<Validacion>({descripcion: true})
    const {pedidoJson} = useGsBackend()

    const guardarHandler = (): void => {
        const vld: Validacion = {
            descripcion: edicion.descripcion !== ''
        }
        setValid(vld)
        if (!vld.descripcion) return

        const fd = new FormData()
        fd.append('accion', 'nuevo')
        fd.append('descripcion', edicion.descripcion)
        fd.append('estado_actualizado', edicion.nuevo_estado.toString())
        if (edicion.documento !== undefined) fd.append('archivo', edicion.documento, edicion.documento.name)

        pedidoJson({
            url: `trazabilidad/${idTrazabilidad}/nuevomovimiento`, method: 'post', body: fd,
            ok: onGuardar
        })
    }

    return (
        <Modal className='w-50'>
            <h4>Ingresar Movimiento</h4>
            <TarjetaSeccion>
                <div className='row mb-2'>
                    <div className='col-lg-3 pt-1'>Descripción</div>
                    <div className='col-lg-9'>
                        <InputTexto autoFocus value={edicion.descripcion} invalido={!valid.descripcion}
                                    onChange={v => setEdicion({...edicion, descripcion: v})}/>
                    </div>
                </div>
                <div className='row mb-2'>
                    <div className='col-lg-3 pt-1'>Estado Actualizado</div>
                    <div className='col-lg-9'>
                        <InputSelect value={edicion.nuevo_estado} sincero
                                     opciones={estadosTrazabilidad.map(e => {
                                         return {id: e.id, descripcion: e.descripcion} as OpcionSelect
                                     })}
                                     onChange={v => setEdicion({...edicion, nuevo_estado: v})}/>
                    </div>
                </div>
                <div className='row'>
                    <div className='col-md-3 pt-1'>
                        Documento <small className='text-muted'>(Opcional)</small>
                    </div>
                    <div className='col-md-9'>
                        <InputArchivo onChange={v => setEdicion({...edicion, documento: v})}/>
                    </div>
                </div>
            </TarjetaSeccion>
            <ModalBotones onCancelar={onCancelar} onGuardar={guardarHandler}/>
        </Modal>
    )
}

export default TrazabilidadIngresarMovimiento