import React, {useState} from "react";
import ModalConfirmarCancelar from "../controles/ModalConfirmarCancelar";
import Modal from "../controles/Modal";
import ModalBotones from "../controles/ModalBotones";
import InputSelect, {OpcionSelect} from "../controles/InputSelect";
import InputFecha from "../controles/InputFecha";
import {useAppDispatch} from "../redux/hooks";
import {setNotificacion} from "../redux/estadoReducer";
import {useGsBackend} from "../funcionesApi";

type Props = {
    empleado: string,
    idEmpleado: number,
    tiposNovedades: Array<OpcionSelect>
    onCancelar: () => void,
    onCarga: () => void
}

type Validacion = {
    tipo: boolean,
    desde: boolean,
    hasta: boolean
}

type InfoConfirmacion = {
    desde: string,
    hasta: string,
    dias: number
}

const CargaNovedadRango: React.FC<Props> = (
    {empleado, idEmpleado, onCancelar, onCarga, tiposNovedades}
) => {
    const [confirmar, setConfirmar] = useState<boolean>(false)
    const [desde, setDesde] = useState<string>('')
    const [hasta, setHasta] = useState<string>('')
    const [tipo, setTipo] = useState<number>(0)
    const [valida, setValida] = useState<Validacion>({tipo: true, desde: true, hasta: true} as Validacion)
    const [info, setInfo] = useState<InfoConfirmacion>({desde: '', hasta: '', dias: 0})
    const dispatch = useAppDispatch()

    const {pedidoJson, cargando} = useGsBackend()

    const validarHandler = (idEmpleado: number): void => {
        let v: Validacion = {
            tipo: tipo !== 0,
            desde: desde !== '',
            hasta: hasta !== ''
        }

        setValida(v)
        if (!v.tipo || !v.desde || !v.hasta) {
            return
        }

        const fd = new FormData()
        fd.append('desde', desde)
        fd.append('hasta', hasta)

        pedidoJson({
            url: `legajos/verificarcargamasivanovedades/${idEmpleado}`, method: 'post',
            body: fd, sinNotificacion: true,
            ok: res => {
                if (res.dias > 0) {
                    setInfo({
                        desde: res.desde,
                        hasta: res.hasta,
                        dias: res.dias
                    } as InfoConfirmacion)
                    setConfirmar(true)
                } else {
                    dispatch(setNotificacion({
                        tipo: 'error',
                        mensaje: 'Ningún dia laborable sin novedades entre esas fechas'
                    }))
                }
            }
        })
    }

    const guardarHandler = async (idEmpleado: number): Promise<void> => {
        const fd = new FormData()
        fd.append('id_tiponovedad', tipo.toString())
        fd.append('desde', desde)
        fd.append('hasta', hasta)

        pedidoJson({
            url: `legajos/cargamasivanovedades/${idEmpleado}`, method: 'post', body: fd, ok: onCarga
        })
    }

    const titulo = 'Carga de Novedades por Rango'
    if (confirmar) {
        const descripcion = tiposNovedades.filter(n => n.id === tipo)[0].descripcion
        const diasAlerta = 15
        return <ModalConfirmarCancelar titulo={titulo}
                                       descripcion={
                                           `Usted está a punto de realizar la siguiente carga masiva de novedades.
                                           Esta acción no se puede deshacer.`
                                       }
                                       contenido={
                                           <>
                                               <div className='row mb-2'>
                                                   <div className='col-sm-4'>Empleado</div>
                                                   <div className='col-sm-8 fw-bold'>{empleado}</div>
                                               </div>
                                               <div className='row'>
                                                   <div className='col-sm-4'>Días a cargar</div>
                                                   <div className={'col-sm-8 fw-bold' +
                                                       (info.dias > diasAlerta ? ' text-danger' : '')}>
                                                       {info.dias} {info.dias === 1 ? 'día' : 'días'}
                                                       {info.dias > diasAlerta &&
                                                           <i className='fas fa-exclamation-triangle ms-2'/>}
                                                   </div>
                                               </div>
                                               <div className='row mb-2'>
                                                   <div className='col-sm-8 offset-sm-4 small'>
                                                       <div>{descripcion}</div>
                                                       <div>Desde {info.desde} hasta {info.hasta}</div>
                                                   </div>
                                               </div>
                                           </>
                                       }
                                       onConfirmar={() => guardarHandler(idEmpleado)}
                                       onCancelar={() => setConfirmar(false)}/>
    } else {
        return <Modal className='w-50'>
            <h4>{titulo}</h4>
            <div className='row mb-2 pt-1'>
                <div className='col-sm-3 fw-bold'>Empleado</div>
                <div className='col-sm-9'>{empleado}</div>
            </div>
            <div className='row mb-2'>
                <div className='col-sm-3 fw-bold pt-1'>Tipo</div>
                <div className='col-sm-9'>
                    <InputSelect value={tipo} onChange={setTipo} opciones={tiposNovedades}
                                 invalido={!valida.tipo}/>
                </div>
            </div>
            <div className='row mb-2 pt-1'>
                <div className='col-sm-3 fw-bold'>Fechas</div>
                <div className='col-sm-9'>
                    <div className='row mb-2'>
                        <div className='col-6'>
                            <InputFecha value={desde} onChange={setDesde} titulo='Desde'
                                        invalido={!valida.desde}/>
                        </div>
                        <div className='col-6'>
                            <InputFecha value={hasta} onChange={setHasta} titulo='Hasta'
                                        invalido={!valida.hasta}/>
                        </div>
                    </div>
                </div>
            </div>
            <ModalBotones onCancelar={onCancelar} onGuardar={() => validarHandler(idEmpleado)}
                          guardando={cargando}
                          tituloNuevo='Realizar carga'/>
        </Modal>
    }
}

export default CargaNovedadRango