import React, {useEffect, useState} from "react";
import {useAppSelector} from "../redux/hooks";
import Boton from "../controles/Boton";
import Modal from "../controles/Modal";
import ModalBotones from "../controles/ModalBotones";
import TextoND from "../controles/TextoND";
import InputTexto from "../controles/InputTexto";
import InputArchivo from "../controles/InputArchivo";
import {urlDescargaSancion} from "../descargas/descargasLegajo";
import ToolbarBtn from "../controles/ToolbarBtn";
import TarjetaCarga from "../controles/TarjetaCarga";
import BotonesExportar from "../controles/BotonesExportar";
import Tooltip, {InfoTooltip} from "../controles/Tooltip";
import {useNavigate, useParams} from "react-router-dom";
import BarraSuperior from "../controles/BarraSuperior";
import CuerpoPrincipal from "../controles/CuerpoPrincipal";
import {useGsBackend} from "../funcionesApi";
import {funcionDescarga} from "../descargas/descargas";

type DatosEmp = {
    apellido: string,
    nombre: string,
    sanciones_anio: number
}

type Sancion = {
    id: number,
    fecha: string,
    motivo: string,
    documento: string,
    usuario: string,
    nombre_usuario: string
}

const SancionesEmpleado: React.FC = () => {
    const [empleado, setEmpleado] = useState<DatosEmp | undefined>(undefined)
    const [sanciones, setSanciones] = useState<Array<Sancion>>([] as Array<Sancion>)
    const [editarSancion, setEditarSancion] = useState<number | undefined>(undefined)
    const [edicionMotivo, setEdicionMotivo] = useState<string>('')
    const [edicionDocumento, setEdicionDocumento] = useState<string>('')
    const [edicionFechaCarga, setEdicionFechaCarga] = useState<string>('')
    const [edicionArchivo, setEdicionArchivo] = useState<File | undefined>(undefined)
    const [validoMotivo, setValidoMotivo] = useState<boolean>(true)
    const [infoTooltip, setInfoTooltip] = useState<InfoTooltip | undefined>(undefined)
    const edita = useAppSelector(state => state.auth.edicion)
    const navigate = useNavigate()
    const params = useParams()
    const numIdEmpleado = parseInt(params.idEmpleado || '0')

    const {pedidoJson, pedidoBlob, errorCarga, actualizar, cargando} = useGsBackend()
    useEffect(() => {
        pedidoJson({
            url: `legajos/sanciones/${numIdEmpleado}`, sinErrorDispatch: true,
            ok: res => {
                // Si no habia un id valido volver a Legajos
                if (res.empleado === undefined) navigate('../../')

                setEmpleado(res.empleado)
                setSanciones(res.sanciones)
            }
        })
    }, [pedidoJson, numIdEmpleado, navigate])

    const edicionHandler = (idSancion: number | undefined): void => {
        setValidoMotivo(true)
        setEdicionArchivo(undefined)
        if (idSancion === undefined) {
            setEditarSancion(undefined)
        } else if (idSancion === 0) {
            setEdicionMotivo('')
            setEdicionDocumento('')
            setEditarSancion(0)
        } else {
            const s = sanciones.filter(s => s.id === idSancion)[0]
            setEdicionMotivo(s.motivo)
            setEdicionDocumento(s.documento)
            setEdicionFechaCarga(s.fecha)
            setEditarSancion(idSancion)
        }
    }

    const guardarHandler = async (eliminar: boolean = false): Promise<void> => {
        if (editarSancion === undefined) return

        const motivoOk = edicionMotivo !== ''
        setValidoMotivo(motivoOk)
        if (!motivoOk) return

        const fd = new FormData()
        fd.append('accion', eliminar ? 'eliminar' : 'nuevo')
        fd.append('id', editarSancion.toString())
        fd.append('motivo', edicionMotivo)
        if (edicionArchivo !== undefined) fd.append('archivo', edicionArchivo, edicionArchivo.name)

        pedidoJson({
            url: `legajos/sanciones/${numIdEmpleado}`, method: 'post', body: fd,
            ok: () => {
                edicionHandler(undefined)
                actualizar()
            }
        })
    }

    const cargaTooltipSanciones = (idSancion: number): void => {
        setInfoTooltip({
            urlPreview: `previews?tipo=sanciones&id_sancion=${idSancion}`
        })
    }

    if (empleado !== undefined) {
        const nombreCompleto = `${empleado.apellido}, ${empleado.nombre}`

        const excelSanciones = (): void => {
            pedidoBlob({
                url: `excel/sanciones?empleado=${numIdEmpleado}`,
                ok: res => funcionDescarga(res, 'sanciones.xlsx')
            })
        }

        return (
            <div>
                <BarraSuperior>
                    <ToolbarBtn>
                        <Boton toolbar titulo='Volver al Legajo' icono='fas fa-arrow-left'
                               className='btn-secondary' onClick={() => navigate('../')}/>
                        {edita && <Boton toolbar titulo='Nueva Sanción' icono='fas fa-exclamation-triangle'
                                         onClick={() => edicionHandler(0)}/>}
                        {sanciones.length ? <BotonesExportar onExcel={excelSanciones}/> : <></>}
                    </ToolbarBtn>
                </BarraSuperior>
                <CuerpoPrincipal>
                    <h4>
                        <strong>{nombreCompleto}</strong> - Sanciones del Empleado
                    </h4>
                    {empleado.sanciones_anio === 0 ? (
                        <div className='badge bg-light text-success'>
                            <i className='fas fa-check me-2'/>
                            No se registran sanciones en este año
                        </div>
                    ) : (
                        <div className='badge bg-warning'>
                            <i className='fas fa-exclamation-triangle me-2'/>
                            {empleado.sanciones_anio}
                            {empleado.sanciones_anio === 1 ? ' sanción ' : ' sanciones '}
                            en este año
                        </div>
                    )}
                </CuerpoPrincipal>
                <table className={'table table-responsive table-striped' + (edita ? ' table-hover' : '')}>
                    <thead>
                    <tr>
                        <th className='ps-3'>Fecha</th>
                        <th>Motivo</th>
                        <th>Documento</th>
                        <th>Usuario</th>
                    </tr>
                    </thead>
                    <tbody>
                    {
                        sanciones.length ?
                            sanciones.map((s, i) => (
                                <tr className={edita ? 'cursorpointer' : ''} key={i}
                                    onClick={() => {
                                        if (edita) edicionHandler(s.id)
                                    }}>
                                    <td className='ps-3'>{s.fecha}</td>
                                    <td>{s.motivo}</td>
                                    <td className='text-muted'>
                                        {s.documento !== '' ?
                                            <span className='enlacespan text-primary'
                                                  style={{'cursor': 'pointer'}}
                                                  onClick={e => {
                                                      e.stopPropagation()
                                                      pedidoBlob({
                                                          url: urlDescargaSancion(numIdEmpleado, s.id),
                                                          ok: res => funcionDescarga(res, s.documento)
                                                      })
                                                  }}
                                                  onMouseEnter={() => cargaTooltipSanciones(s.id)}
                                                  onMouseLeave={() => setInfoTooltip(undefined)}>
                                              <i className='fas fa-file-download me-2'/>Descargar
                                              </span>
                                            : <TextoND/>}
                                    </td>
                                    <td className='small'>
                                        <strong>{s.usuario}</strong><br/>{s.nombre_usuario}
                                    </td>
                                </tr>
                            ))
                            :
                            <tr>
                                <td colSpan={4}>No hay ninguna sanción para mostrar</td>
                            </tr>
                    }
                    </tbody>
                </table>
                {editarSancion !== undefined && (
                    <Modal className='w-50'>
                        <h4>{editarSancion === 0 ? 'Nueva Sanción' : 'Ver Sanción'}</h4>
                        {editarSancion === 0 ? (
                            <>
                                <div className='row mb-2'>
                                    <div className='col-sm-3 fw-bold pt-1'>Motivo</div>
                                    <div className='col-sm-9'>
                                        <InputTexto value={edicionMotivo} onChange={setEdicionMotivo} autoFocus
                                                    invalido={!validoMotivo}/>
                                    </div>
                                </div>
                                <div className='row mb-2'>
                                    <div className='col-sm-3 fw-bold pt-1'>
                                        Documento<br/>
                                        <small className='text-muted'>(Opcional)</small>
                                    </div>
                                    <div className='col-sm-9'>
                                        <InputArchivo onChange={e => setEdicionArchivo(e)}/>
                                    </div>
                                </div>
                                <ModalBotones onGuardar={() => guardarHandler()} guardando={cargando}
                                              onCancelar={() => edicionHandler(undefined)}/>
                            </>
                        ) : (
                            <>
                                <div className='row mb-2'>
                                    <div className='col-sm-3 fw-bold'>Fecha de Carga</div>
                                    <div className='col-sm-9'>{edicionFechaCarga}</div>
                                </div>
                                <div className='row mb-2'>
                                    <div className='col-sm-3 fw-bold'>Motivo</div>
                                    <div className='col-sm-9'>{edicionMotivo}</div>
                                </div>
                                {edicionDocumento !== '' && (
                                    <div className='row mb-2'>
                                        <div className='col-sm-3 fw-bold'>Documento</div>
                                        <div className='col-sm-9'>
                                            <Boton titulo={edicionDocumento} icono='fas fa-download' chico
                                                   onClick={() => {
                                                       pedidoBlob({
                                                           url: urlDescargaSancion(numIdEmpleado, editarSancion),
                                                           ok: res => funcionDescarga(res, edicionDocumento)
                                                       })
                                                   }}/>
                                        </div>
                                    </div>
                                )}
                                <ModalBotones soloVista guardando={cargando}
                                              onEliminar={() => guardarHandler(true)}
                                              onCancelar={() => edicionHandler(undefined)}/>
                            </>
                        )}
                    </Modal>
                )}
                {infoTooltip !== undefined && <Tooltip datos={infoTooltip}/>}
            </div>
        )
    } else {
        return <TarjetaCarga error={errorCarga} onReintentar={actualizar} onVolver={() => navigate('../')}/>
    }
}

export default SancionesEmpleado
