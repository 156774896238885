import React from "react";
import {FichadaLegajoItem} from "./interfacesLegajo";

type FichadasLegajoProps = {
    fichadas: Array<FichadaLegajoItem>
}

const FichadasLegajo: React.FC<FichadasLegajoProps> = ({fichadas}) => {
    if (!fichadas || fichadas.length === 0) {
        return <>No registra</>
    } else {
        return (
            <table className='table table-sm table-striped table-bordered small'>
                <thead>
                <tr>
                    <th>Fecha y Hora</th>
                    <th className='text-center'>Tipo</th>
                </tr>
                </thead>
                <tbody>
                {fichadas.map((f: FichadaLegajoItem, i: number) => (
                    <tr key={i}>
                        <td>{f.fechayhora}</td>
                        <td className='text-center'>
                            {
                                f.entrada ? <i className='fas fa-sign-in-alt'/> :
                                    <i className='fas fa-sign-out-alt text-muted'/>
                            }
                        </td>
                    </tr>
                ))}
                </tbody>
            </table>
        )
    }
}

export default FichadasLegajo
