import React, {useEffect, useState} from "react";
import Boton from "../controles/Boton";
import ToolbarBtn from "../controles/ToolbarBtn";
import EdicionTiposProductos from "./EdicionTiposProductos";
import InputSelect, {OpcionSelect} from "../controles/InputSelect";
import TarjetaCarga from "../controles/TarjetaCarga";
import Modal from "../controles/Modal";
import TarjetaSeccion from "../controles/TarjetaSeccion";
import InputTexto from "../controles/InputTexto";
import ModalBotones from "../controles/ModalBotones";
import TrInfo from "../controles/TrInfo";
import {useNavigate} from "react-router-dom";
import BarraSuperior from "../controles/BarraSuperior";
import CuerpoPrincipal from "../controles/CuerpoPrincipal";
import {useGsBackend} from "../funcionesApi";

type Producto = {
    id: number,
    descripcion: string,
    id_tipodeproducto: number,
    tipodeproducto: string,
    id_proveedor: number,
    proveedor: string,
    id_unidaddemedida: number,
    unidaddemedida: string,
    id_sectorpredeterminado: number,
    sectorpredeterminado: string,
    observaciones: string,
    eliminable: boolean
}

type InfoProductos = {
    productos: Array<Producto>,
    tiposdeproductos: Array<OpcionSelect>,
    unidadesdemedida: Array<OpcionSelect>,
    sectores: Array<OpcionSelect>,
    proveedores: Array<OpcionSelect>
}

type ValidacionEdicion = {
    descripcion: boolean,
    id_tipodeproducto: boolean,
    id_unidaddemedida: boolean,
    id_sectorpredeterminado: boolean
}

const StockProductos: React.FC = () => {
    const validacionOk: ValidacionEdicion =
        {descripcion: true, id_tipodeproducto: true, id_unidaddemedida: true, id_sectorpredeterminado: true}
    const [datos, setDatos] = useState<InfoProductos | undefined>(undefined)
    const [editando, setEditando] = useState<number | undefined>(undefined)
    const [editDescripcion, setEditDescripcion] = useState<string>('')
    const [editTipoDeProducto, setEditTipoDeProducto] = useState<number>(0)
    const [editProveedor, setEditProveedor] = useState<number>(0)
    const [editUnidadDeMedida, setEditUnidadDeMedida] = useState<number>(0)
    const [editSectorPredeterminado, setEditSectorPredeterminado] = useState<number>(0)
    const [editObservaciones, setEditObservaciones] = useState<string>('')
    const [valida, setValida] = useState<ValidacionEdicion>(validacionOk)
    const [eliminable, setEliminable] = useState<boolean>(true)
    const [editarTipos, setEditarTipos] = useState<boolean>(false)
    const navigate = useNavigate()

    const {pedidoJson, errorCarga, actualizar, cargando} = useGsBackend()

    useEffect(() => {
        pedidoJson({url: 'stock/productos', method: 'get', ok: setDatos})
    }, [pedidoJson])

    const editarHandler = (id?: number): void => {
        if (datos === undefined) return
        if (id === undefined) {
            setEditando(undefined)
        } else if (id === 0) {
            setEditDescripcion('')
            setEditUnidadDeMedida(0)
            setEditTipoDeProducto(0)
            setEditSectorPredeterminado(0)
            setEditProveedor(0)
            setEditObservaciones('')
            setValida(validacionOk)
            setEditando(0)
        } else {
            const item = datos?.productos.filter(p => p.id === id)[0]
            if (item === undefined) return
            setEditDescripcion(item.descripcion)
            setEditUnidadDeMedida(item.id_unidaddemedida)
            setEditTipoDeProducto(item.id_tipodeproducto)
            setEditSectorPredeterminado(item.id_sectorpredeterminado)
            setEditProveedor(item.id_proveedor)
            setEditObservaciones(item.observaciones)
            setEliminable(item.eliminable)
            setValida(validacionOk)
            setEditando(id)
        }
    }

    const guardarHandler = async (eliminar?: boolean): Promise<void> => {
        if (editando === undefined) return

        const vld: ValidacionEdicion = {
            descripcion: editDescripcion !== '',
            id_unidaddemedida: editUnidadDeMedida !== 0,
            id_tipodeproducto: editTipoDeProducto !== 0,
            id_sectorpredeterminado: editSectorPredeterminado !== 0
        }
        setValida(vld)
        if (!vld.descripcion || !vld.id_unidaddemedida || !vld.id_tipodeproducto ||
            !vld.id_sectorpredeterminado) return

        const fd = new FormData()
        fd.append('accion', editando === 0 ? 'nuevo' : eliminar ? 'eliminar' : 'editar')
        fd.append('id', editando.toString())
        fd.append('descripcion', editDescripcion)
        fd.append('observaciones', editObservaciones)
        fd.append('id_tipodeproducto', editTipoDeProducto.toString())
        fd.append('id_unidaddemedida', editUnidadDeMedida.toString())
        fd.append('id_sectorpredeterminado', editSectorPredeterminado.toString())
        fd.append('id_proveedor', editProveedor.toString())

        pedidoJson({
            url: 'stock/productos', method: 'post', body: fd,
            ok: () => {
                editarHandler(undefined)
                actualizar()
            }
        })
    }

    if (datos === undefined) {
        return (
            <TarjetaCarga error={errorCarga} onReintentar={actualizar}
                          onVolver={() => navigate('../')}/>
        )
    } else {
        return (
            <div>
                <BarraSuperior>
                    <ToolbarBtn>
                        <Boton toolbar titulo='Volver' icono='fas fa-arrow-left' className='btn-secondary'
                               onClick={() => navigate('../')}/>
                        <Boton toolbar titulo='Alta de Producto' icono='fas fa-plus'
                               onClick={() => editarHandler(0)}/>
                        <Boton toolbar titulo='Configurar Tipos de Productos' icono='fas fa-cog'
                               onClick={() => setEditarTipos(true)}/>
                    </ToolbarBtn>
                </BarraSuperior>
                <CuerpoPrincipal sinEspacio>
                    <h4>
                        <i className='fas fa-boxes-packing text-primary me-2'/>
                        Configuración de Productos
                    </h4>
                </CuerpoPrincipal>
                <table className='table table-responsive table-striped table-hover'>
                    <thead>
                    <tr>
                        <th className='ps-3'>Producto</th>
                        <th>Unidad de Medida</th>
                        <th>Proveedor</th>
                        <th>Sector Predeterminado</th>
                        <th>Observaciones</th>
                    </tr>
                    </thead>
                    <tbody>
                    {datos.productos.length ? datos.productos.map((p, k) => (
                        <tr key={k} style={{cursor: 'pointer'}} onClick={() => editarHandler(p.id)}>
                            <td className='ps-3'>
                                <strong>{p.descripcion}</strong><br/>
                                <small>{p.tipodeproducto}</small>
                            </td>
                            <td>{p.unidaddemedida}</td>
                            <td>{p.proveedor}</td>
                            <td>{p.sectorpredeterminado}</td>
                            <td>{p.observaciones}</td>
                        </tr>
                    )) : (
                        <TrInfo colSpan={5} texto='No se encuentran productos para mostrar'/>
                    )}
                    </tbody>
                </table>
                {editarTipos && (
                    <EdicionTiposProductos onCancelar={() => {
                        setEditarTipos(false)
                        actualizar()
                    }}/>
                )}
                {editando !== undefined && (
                    <Modal className='w-50'>
                        <h4>{editando === 0 ? 'Alta de Producto' : 'Editar Producto'}</h4>
                        <TarjetaSeccion>
                            <div className='row mb-2'>
                                <div className='col-md-3 pt-1'>Descripción</div>
                                <div className='col-md-9'>
                                    <InputTexto value={editDescripcion} onChange={setEditDescripcion}
                                                autoFocus invalido={!valida.descripcion}/>
                                </div>
                            </div>
                            <div className='row mb-2'>
                                <div className='col-md-3 pt-1'>Tipo de Producto</div>
                                <div className='col-md-9'>
                                    <InputSelect value={editTipoDeProducto} onChange={setEditTipoDeProducto}
                                                 opciones={datos.tiposdeproductos}
                                                 invalido={!valida.id_tipodeproducto}/>
                                </div>
                            </div>
                            <div className='row mb-2'>
                                <div className='col-md-3 pt-1'>Unidad de Medida</div>
                                <div className='col-md-9'>
                                    <InputSelect value={editUnidadDeMedida} onChange={setEditUnidadDeMedida}
                                                 opciones={datos.unidadesdemedida}
                                                 invalido={!valida.id_unidaddemedida}/>
                                </div>
                            </div>
                            <div className='row mb-2'>
                                <div className='col-md-3 pt-1'>Proveedor</div>
                                <div className='col-md-9'>
                                    <InputSelect value={editProveedor} onChange={setEditProveedor}
                                                 opciones={datos.proveedores} forzarCero
                                                 textocero='(Sin especificar)'/>
                                </div>
                            </div>
                            <div className='row mb-2'>
                                <div className='col-md-3 pt-1'>Sector Predeterminado</div>
                                <div className='col-md-9'>
                                    <InputSelect value={editSectorPredeterminado}
                                                 onChange={setEditSectorPredeterminado}
                                                 opciones={datos.sectores}
                                                 invalido={!valida.id_sectorpredeterminado}/>
                                </div>
                            </div>
                            <div className='row mb-2'>
                                <div className='col-md-3 pt-1'>Observaciones</div>
                                <div className='col-md-9'>
                                    <InputTexto value={editObservaciones} onChange={setEditObservaciones}/>
                                </div>
                            </div>
                        </TarjetaSeccion>
                        <ModalBotones editando={editando > 0} soloEditar={editando > 0 && !eliminable}
                                      onCancelar={() => editarHandler(undefined)} guardando={cargando}
                                      onEliminar={() => guardarHandler(true).catch(err => alert(err))}
                                      onGuardar={guardarHandler}/>
                    </Modal>
                )}
            </div>
        )
    }
}

export default StockProductos