export type Dia = {
    numero: number,
    desc_corta: string,
    desc_larga: string
}

export default [
    {numero: 0, desc_corta: 'Dom', desc_larga: 'Domingo'},
    {numero: 1, desc_corta: 'Lun', desc_larga: 'Lunes'},
    {numero: 2, desc_corta: 'Mar', desc_larga: 'Martes'},
    {numero: 3, desc_corta: 'Mie', desc_larga: 'Miércoles'},
    {numero: 4, desc_corta: 'Jue', desc_larga: 'Jueves'},
    {numero: 5, desc_corta: 'Vie', desc_larga: 'Viernes'},
    {numero: 6, desc_corta: 'Sab', desc_larga: 'Sábado'}
] as Array<Dia>
