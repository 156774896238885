import React, {useState} from "react";
import {ModoServidor, ModoState} from "../redux/modoReducer";
import {useAppSelector} from "../redux/hooks";
import OpcionesDesarrollo from "../configuracion/OpcionesDesarrollo";

const VistaModo: React.FC = () => {
    const [opcionesDesa, setOpcionesDesa] = useState<boolean>(false)
    const modo: ModoState = useAppSelector(state => state.modo)

    switch (modo.modoServidor) {
        case ModoServidor.Desa:
            return (
                <>
                    <div className='position-fixed top-0 start-50 translate-middle-x w-25 mt-2'
                         style={{zIndex: 99999}}>
                        <div className='p-2 bg-info shadow-sm rounded-2 text-light mb-3 fw-bold small text-center'
                             style={{cursor: 'pointer'}} onClick={() => setOpcionesDesa(true)}>
                            <i className='fas fa-code me-2'/>
                            Versión Desarrollo
                        </div>
                    </div>
                    {opcionesDesa && (
                        <OpcionesDesarrollo onCerrar={() => setOpcionesDesa(false)}/>
                    )}
                </>
            )
        case ModoServidor.Test:
            return (
                <div className='position-fixed top-0 start-50 translate-middle-x w-25 mt-2'
                     style={{zIndex: 99999}}>
                    <div className='p-2 bg-danger shadow-sm rounded-2 text-light mb-3 fw-bold small text-center'>
                        <i className='fas fa-terminal me-2'/>
                        Versión Testing
                    </div>
                </div>
            )
        default:
            return <></>
    }
}

export default VistaModo