import React, {useState} from "react";
import ToolbarBtn from "../../controles/ToolbarBtn";
import Boton from "../../controles/Boton";
import NovedadesPorTipo from "./NovedadesPorTipo";
import {reportesLegajos} from "./reportes";
import FichadasDelDia from "./FichadasDelDia";
import BotonIcono from "../../controles/BotonIcono";
import {Navigate, useNavigate, useParams} from "react-router-dom";
import BarraSuperior from "../../controles/BarraSuperior";
import CuerpoPrincipal from "../../controles/CuerpoPrincipal";

const ReportesLegajos: React.FC = () => {
    const reporteInicial = 'novedades'
    const [contraer, setContraer] = useState<boolean>(false)
    const navigate = useNavigate()
    const params = useParams()

    const reporte = params.reporte
    if (reporte === undefined) return <Navigate to={`../${reporteInicial}`} replace/>

    const reporteRouter = (): React.ReactNode => {
        switch (reporte) {
            case 'novedades':
                return <NovedadesPorTipo/>
            case 'fichadas':
                return <FichadasDelDia/>
            default:
                return <Navigate to={`../${reporteInicial}`} replace/>
        }
    }

    return (
        <div>
            <BarraSuperior>
                <ToolbarBtn>
                    <Boton toolbar titulo='Volver a Legajos' icono='fas fa-arrow-left'
                           className='btn-secondary' onClick={() => navigate('../../')}/>
                </ToolbarBtn>
            </BarraSuperior>
            <CuerpoPrincipal>
                <div className='row g-3'>
                    <div className={contraer ? 'col-auto' : 'col-3'}>
                        {contraer ? <div className='row'>
                            <div className='col'>
                                <h4><i className='fas fa-chart-pie text-primary'/></h4>
                            </div>
                            <div className='col-auto px-0'>
                                <BotonIcono icono='fas fa-chevron-right' onClick={() => setContraer(false)}
                                            estilo='btn-secondary'/>
                            </div>
                        </div> : <>
                            <div className='row'>
                                <div className='col'>
                                    <h4><i className='fas fa-chart-pie text-primary me-2'/>Reportes</h4>
                                </div>
                                <div className='col-auto'>
                                    <BotonIcono icono='fas fa-chevron-left' onClick={() => setContraer(true)}
                                                estilo='btn-secondary'/>
                                </div>
                            </div>
                            <ul className='nav flex-column nav-pills mb-3'>
                                {reportesLegajos.map((r, i) => (
                                    <li className='nav-item' key={i}>
                                        <div
                                            className={'nav-link cursorpointer' + (reporte === r.codigo ? ' active' : '')}
                                            onClick={() => navigate(`../${r.codigo}`)}>
                                            {r.titulo}
                                        </div>
                                    </li>
                                ))}
                            </ul>
                        </>}
                    </div>
                    <div className={contraer ? 'col' : 'col-9'}>
                        <h4>{reportesLegajos.filter(r => r.codigo === reporte)[0]?.titulo}</h4>
                        {reporteRouter()}
                    </div>
                </div>
            </CuerpoPrincipal>
        </div>
    )
}

export default ReportesLegajos