import React, {useEffect, useState} from "react";
import {OpcionSelect} from "../controles/InputSelect";
import BodyCarga from "../controles/BodyCarga";
import InputFecha from "../controles/InputFecha";
import TrInfo from "../controles/TrInfo";
import CuerpoPrincipal from "../controles/CuerpoPrincipal";
import {useGsBackend} from "../funcionesApi";

type Registro = {
    usuario: string
    fecha_y_hora: string
    registro: string
}

type Info = {
    registros: Array<Registro>
    usuarios: Array<OpcionSelect>
}

const RegistroAuditoria: React.FC = () => {
    const hoy = (): string => new Date().toISOString().slice(0, 10)
    const [fecha, setFecha] = useState<string>(hoy)
    const [datos, setDatos] = useState<Info | undefined>(undefined)

    const {errorCarga, pedidoJson} = useGsBackend()
    useEffect(() => {
        pedidoJson({url: `tablas/auditoria?fecha=${fecha}`, ok: setDatos})
    }, [pedidoJson, fecha])

    const fechaHandler = (arg: string): void => {
        setFecha(arg === '' ? hoy : arg)
    }

    if (datos !== undefined) {
        return (
            <>
                <CuerpoPrincipal sinEspacio>
                    <h4>Registro de Auditoría</h4>
                    <div className='row'>
                        <div className='col-sm-6 mb-3'>
                            <InputFecha value={fecha} onChange={e => fechaHandler(e)} titulo='Fecha' chico/>
                        </div>
                    </div>
                </CuerpoPrincipal>
                <table className='table table-responsive table-striped table-sm'>
                    <thead>
                    <tr>
                        <th className='ps-3'>Usuario</th>
                        <th>Fecha y Hora</th>
                        <th>Acción</th>
                    </tr>
                    </thead>
                    <tbody>
                    {datos.registros.length ? datos.registros.map((r, k) => (
                        <tr key={k} className='small'>
                            <td className='ps-3'>{r.usuario}</td>
                            <td>{r.fecha_y_hora}</td>
                            <td>{r.registro}</td>
                        </tr>
                    )) : (
                        <TrInfo colSpan={3} texto='No hay registros para mostrar en la fecha seleccionada'/>
                    )}
                    </tbody>
                </table>
            </>
        )
    } else {
        return <BodyCarga error={errorCarga} onReintentar={() => setFecha(fecha)}/>
    }
}

export default RegistroAuditoria