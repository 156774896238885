import EstadoMensaje from "../controles/EstadoMensaje";
import CheckEstado from "../controles/CheckEstado";
import TextoND from "../controles/TextoND";
import React from "react";
import {InasistenciaLegajoItem} from "./interfacesLegajo";

type InasistenciasLegajoProps = {
    inasistencias: Array<InasistenciaLegajoItem>
}

const InasistenciasLegajo: React.FC<InasistenciasLegajoProps> = ({inasistencias}) => {
    if (!inasistencias || inasistencias.length === 0) {
        return <EstadoMensaje estado={true} mensajeOk='No registra inasistencias en este mes'/>
    } else {

        return (
            <>
                <table className='table table-bordered table-striped table-sm small'>
                    <thead>
                    <tr>
                        <th>Fecha</th>
                        <th>Justificada</th>
                        <th>Descripción</th>
                    </tr>
                    </thead>
                    <tbody>
                    {inasistencias.map((i: InasistenciaLegajoItem, k) => (
                        <tr key={k}>
                            <td>{i.fecha}</td>
                            <td><CheckEstado textosino estado={i.justificada}/></td>
                            <td><TextoND texto={i.descripcion}/></td>
                        </tr>
                    ))}
                    </tbody>
                </table>
            </>
        )
    }
}

export default InasistenciasLegajo
