import {createSlice, PayloadAction} from "@reduxjs/toolkit";

export enum ModoServidor {
    Desa,
    Test,
    Prod
}

export interface ModoState {
    modoServidor?: ModoServidor
}

export const modoSlice = createSlice({
    name: 'estado',
    initialState: {
        modo: undefined
    } as ModoState,
    reducers: {
        setModo: (state, action: PayloadAction<ModoState>) => {
            state.modoServidor = action.payload.modoServidor
        }
    }
})

export const {setModo} = modoSlice.actions

export default modoSlice.reducer
