import React from "react";
import './PantallaCargando.css'

const PantallaCargando: React.FC = () => {
    return (
        <div className='pantallacargando'>
            <div className='card iconocargando rounded-3 shadow'>
                <div className='card-body py-4 px-5 text-center'>
                    <div className='spinner-border mb-2 text-primary'
                         style={{width: '2rem', height: '2rem'}}/>
                    <div className='text-muted small fw-bold'>Cargando</div>
                </div>
            </div>
        </div>
    )
}

export default PantallaCargando
