import React from "react";

type Props = {
    className?: string,
    caret?: boolean,
    reducido?: boolean,
    muted?: boolean
}

const LogoApp: React.FC<Props> = (
    {className, caret, reducido, muted}
) => {
    const titulo = 'integrion'

    return (
        <div className={'fuentetitulo' + (className !== undefined ? ` ${className}` : '')}>
            {caret && <i className='fas fa-caret-right text-muted me-2'/>}
            <span className={muted ? 'text-muted' : 'text-info'}>gs.</span>
            <span className='text-primary'>{reducido ? titulo.charAt(0) : titulo}</span>
        </div>
    )
}

export default LogoApp