import React from "react";
import estadosTrazabilidad from "./estadosTrazabilidad";

type Props = {
    estado: number
}

const VistaEstadosTrazabilidad: React.FC<Props> = ({estado}) => {
    const infoEstado = estadosTrazabilidad.filter(e => e.id === estado)[0]

    return (
        <>
            {infoEstado !== undefined ? (
                <div className='text-nowrap'>
                    <i className={`fas fa-square me-2 text-${infoEstado.color}`}/>
                    {infoEstado.descripcion}
                </div>
            ) : (
                <div className='text-muted'>N/D</div>
            )}
        </>
    )
}

export default VistaEstadosTrazabilidad