import {createSlice, PayloadAction} from "@reduxjs/toolkit";

export interface InfoNotificacion {
    mensaje: string,
    tipo: 'success' | 'error' | 'warning' | 'info'
}

interface EstadoState {
    cargando: boolean,
    error_sistema: string | undefined,
    notificacion: InfoNotificacion | undefined,
    altura_header: number
}

export const estadoSlice = createSlice({
    name: 'estado',
    initialState: {
        cargando: false,
        error_sistema: undefined,
        notificacion: undefined,
        altura_header: 0
    } as EstadoState,
    reducers: {
        setCargando: (state, action: PayloadAction<boolean>) => {
            state.cargando = action.payload
        },
        setErrorSistema: (state, action: PayloadAction<string | undefined>) => {
            state.error_sistema = action.payload
        },
        setNotificacion: (state, action: PayloadAction<InfoNotificacion | undefined>) => {
            state.notificacion = action.payload
        },
        setAlturaHeader: (state, action: PayloadAction<number>) => {
            state.altura_header = action.payload
        }
    }
})

export const {setCargando, setErrorSistema, setNotificacion, setAlturaHeader} = estadoSlice.actions

export default estadoSlice.reducer
