import React from "react";
import {useAppSelector} from "../redux/hooks";

const BarraSuperior: React.FC<{ children: React.ReactNode }> = ({children}) => {
    const altura = useAppSelector(state => state.estado.altura_header)

    return (
        <div className='bg-light border-bottom px-3 py-2 sticky-top' style={{top: altura + 1}}>
            {children}
        </div>
    )
}

export default BarraSuperior