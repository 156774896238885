import React, {useEffect, useState} from "react";
import IconoCarga from "../../controles/IconoCarga";
import InputFecha from "../../controles/InputFecha";
import InputSelect, {OpcionSelect} from "../../controles/InputSelect";
import {useAppSelector} from "../../redux/hooks";
import BotonIcono from "../../controles/BotonIcono";
import Modal from "../../controles/Modal";
import InputTexto from "../../controles/InputTexto";
import InputArchivo from "../../controles/InputArchivo";
import ModalBotones from "../../controles/ModalBotones";
import {DateTimeFormatter, LocalDate} from "js-joda";
import TrInfo from "../../controles/TrInfo";
import {useGsBackend} from "../../funcionesApi";

type Presentes = {
    id_empleado: number,
    empleado: string,
    ingreso: string,
    novedad: string,
    en_vacaciones: boolean
}

type SoloNombres = {
    id_empleado: number,
    empleado: string,
    novedad: string,
    en_vacaciones: boolean
}

type Info = {
    presentes: Array<Presentes>
    ausentes: Array<SoloNombres>
    sin_tarjeta: Array<SoloNombres>
    ubicaciones: Array<OpcionSelect>
    tipos_novedades: Array<OpcionSelect>
}

type EmpleadoIngreso = {
    id_empleado: number,
    empleado: string,
    fecha: string
}

const FichadasDelDia: React.FC = () => {
    const hoy = (): string => new Date().toISOString().slice(0, 10)
    const [datos, setDatos] = useState<Info | undefined>(undefined)
    const [fecha, setFecha] = useState<string>(hoy)
    const [ubicacion, setUbicacion] = useState<number>(0)
    const [ingreso, setIngreso] = useState<EmpleadoIngreso | undefined>(undefined)
    const [editTipo, setEditTipo] = useState<number>(0)
    const [editComentarios, setEditComentarios] = useState<string>('')
    const [editArchivo, setEditArchivo] = useState<File | undefined>(undefined)
    const [validoTipo, setValidoTipo] = useState<boolean>(true)
    const edita = useAppSelector(state => state.auth.edicion)

    const {pedidoJson, actualizar, cargando} = useGsBackend()
    useEffect(() => {
        pedidoJson({url: `reportes/fichadas_dia?fecha=${fecha}&ubicacion=${ubicacion}`, ok: setDatos})
    }, [pedidoJson, fecha, ubicacion])

    const fechaHandler = (arg: string): void => {
        if (arg === '') {
            setFecha(hoy)
            return
        }

        // No permitir fechas posteriores a hoy
        const fechaObj = new Date(arg)
        if (fechaObj.setHours(0, 0, 0, 0) >= new Date().setHours(0, 0, 0, 0)) {
            setFecha(hoy)
        } else {
            setFecha(arg)
        }
    }

    const vistaNovedad = (arg?: string): React.ReactNode => {
        if (arg === undefined || arg === '') {
            return <></>
        } else {
            return <div className='small text-muted'><i className='fas fa-info-circle me-1'/>{arg}</div>
        }
    }

    const vistaVacaciones = (arg: boolean): React.ReactNode => {
        return !arg ? <></> :
            <div className='small text-muted'><i className='fas fa-plane me-1'/>En Vacaciones</div>
    }

    const cargarNovedadHandler = async (): Promise<void> => {
        const vt = editTipo !== 0
        setValidoTipo(vt)
        if (!vt || ingreso === undefined) return

        const fd = new FormData()
        fd.append('accion', 'editar')
        fd.append('fecha', ingreso.fecha)
        fd.append('tipo', editTipo.toString())
        fd.append('comentarios', editComentarios)
        if (editArchivo !== undefined) fd.append('archivo', editArchivo, editArchivo.name)

        pedidoJson({
            url: `legajos/horarios/${ingreso.id_empleado}`, method: 'post', body: fd,
            ok: () => {
                setIngreso(undefined)
                actualizar()
            }
        })
    }

    const modalCarga = (): React.ReactNode => {
        return ingreso === undefined || datos === undefined ? <></> : (
            <Modal className='w-50'>
                <h4>Carga de Novedades</h4>
                <div className='row mb-2 pt-1'>
                    <div className='col-sm-3 fw-bold'>Empleado</div>
                    <div className='col-sm-9'>{ingreso.empleado}</div>
                </div>
                <div className='row mb-2 pt-1'>
                    <div className='col-sm-3 fw-bold'>Fecha</div>
                    <div className='col-sm-9'>
                        {LocalDate.parse(ingreso.fecha, DateTimeFormatter.ISO_LOCAL_DATE)
                            .format(DateTimeFormatter.ofPattern('dd/MM/yyyy'))}
                    </div>
                </div>
                <div className='row mb-2'>
                    <div className='col-sm-3 fw-bold pt-1'>Tipo</div>
                    <div className='col-sm-9'>
                        <InputSelect value={editTipo} onChange={setEditTipo} opciones={datos.tipos_novedades}
                                     invalido={!validoTipo}/>
                    </div>
                </div>
                {editTipo !== 0 && (
                    <>
                        <div className='row mb-2'>
                            <div className='col-sm-3'>
                                <span className='fw-bold'>Comentarios</span><br/>
                                <span className='text-muted small'>(Opcional)</span>
                            </div>
                            <div className='col-sm-9'>
                                <InputTexto value={editComentarios} onChange={setEditComentarios}/>
                            </div>
                        </div>
                        <div className='row mb-2'>
                            <div className='col-sm-3'>
                                <span className='fw-bold'>Adjuntar documento</span><br/>
                                <span className='text-muted small'>(Opcional)</span>
                            </div>
                            <div className='col-sm-9'>
                                <InputArchivo onChange={e => setEditArchivo(e)}/>
                            </div>
                        </div>
                    </>
                )}
                <ModalBotones soloEditar guardando={cargando}
                              onGuardar={() => cargarNovedadHandler()}
                              onCancelar={() => setIngreso(undefined)}/>
            </Modal>
        )
    }

    if (datos !== undefined) {
        return <div>
            <div className='row g-2 mb-2'>
                <div className='col-sm-6'>
                    <InputFecha value={fecha} onChange={e => fechaHandler(e)} titulo='Fecha' chico/>
                </div>
                <div className='col-sm-6'>
                    <InputSelect value={ubicacion} onChange={u => setUbicacion(u)} titulo='Ubicación'
                                 chico opciones={datos.ubicaciones}/>
                </div>
            </div>
            <div className='row'>
                <div className='col-lg-4'>
                    <table className='table table-sm table-responsive table-bordered table-striped small'>
                        <thead>
                        <tr>
                            <th colSpan={2} className='text-success'>
                                <i className='fas fa-check-circle me-2'/>
                                Presentes: {datos.presentes.length}
                            </th>
                        </tr>
                        <tr>
                            <th>Empleado</th>
                            <th className='text-center'>Ingreso</th>
                        </tr>
                        </thead>
                        <tbody>
                        {datos.presentes.length ? datos.presentes.map((e, k) => <tr key={k}>
                            <td>{e.empleado}{vistaNovedad(e.novedad)}{vistaVacaciones(e.en_vacaciones)}</td>
                            <td className='text-center'>{e.ingreso}</td>
                        </tr>) : <TrInfo colSpan={2} texto='Sin datos para mostrar'/>}
                        </tbody>
                    </table>
                </div>
                <div className='col-lg-4'>
                    <table className='table table-sm table-responsive table-bordered table-striped small'>
                        <thead>
                        <tr>
                            <th colSpan={2} className='text-danger'>
                                <i className='fas fa-times-circle me-2'/>
                                Ausentes: {datos.ausentes.length}
                            </th>
                        </tr>
                        <tr>
                            <th>Empleado</th>
                        </tr>
                        </thead>
                        <tbody>
                        {datos.ausentes.length ? datos.ausentes.map((e, k) => <tr key={k}>
                            <td>
                                {e.empleado}{vistaNovedad(e.novedad)}{vistaVacaciones(e.en_vacaciones)}
                                {edita && (e.novedad === undefined || e.novedad === '') && !e.en_vacaciones && (
                                    <BotonIcono icono='fas fa-calendar-plus' estilo='btn-link p-0 m-0 float-end'
                                                onClick={() => {
                                                    setEditArchivo(undefined)
                                                    setEditComentarios('')
                                                    setEditTipo(0)
                                                    setValidoTipo(true)
                                                    setIngreso({
                                                        id_empleado: e.id_empleado,
                                                        empleado: e.empleado,
                                                        fecha: fecha
                                                    } as EmpleadoIngreso)
                                                }}/>
                                )}
                            </td>
                        </tr>) : <TrInfo colSpan={2} texto='Sin datos para mostrar'/>}
                        </tbody>
                    </table>
                </div>
                <div className='col-lg-4'>
                    <table className='table table-sm table-responsive table-bordered table-striped small'>
                        <thead>
                        <tr>
                            <th colSpan={2} className='text-warning'>
                                <i className='fas fa-exclamation-circle me-2'/>
                                Falta registrar tarjeta: {datos.sin_tarjeta.length}
                            </th>
                        </tr>
                        <tr>
                            <th>Empleado</th>
                        </tr>
                        </thead>
                        <tbody>
                        {datos.sin_tarjeta.length ? datos.sin_tarjeta.map((e, k) => <tr key={k}>
                            <td>{e.empleado}{vistaNovedad(e.novedad)}{vistaVacaciones(e.en_vacaciones)}</td>
                        </tr>) : <TrInfo colSpan={2} texto='Sin datos para mostrar'/>}
                        </tbody>
                    </table>
                </div>
            </div>
            {modalCarga()}
        </div>
    } else {
        return <IconoCarga/>
    }
}

export default FichadasDelDia