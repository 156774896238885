export const getMes = (mes: number): string => {
    const meses: Array<string> = [
        'Enero',
        'Febrero',
        'Marzo',
        'Abril',
        'Mayo',
        'Junio',
        'Julio',
        'Agosto',
        'Septiembre',
        'Octubre',
        'Noviembre',
        'Diciembre'
    ]

    if (meses[mes - 1].length) {
        return meses[mes - 1]
    } else {
        return 'Error'
    }
}