import React from "react";

type ToolbarBtnProps = {
    children: React.ReactNode,
    derecha?: boolean
}

const ToolbarBtn: React.FC<ToolbarBtnProps> = (
    {children, derecha}
) => {
    return (
        <div className='btn-toolbar'>
            {derecha ? (
                <div className='ms-auto'>{children}</div>
            ) : children}
        </div>
    )
}

export default ToolbarBtn
