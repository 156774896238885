import React from "react";

type Props = {
    titulo: string,
    valor: string | number
}

const FHOpcional: React.FC<Props> = ({titulo, valor}) => {
    return valor === '' ? <></> :
        <>
            <tr>
                <td className='text-nowrap fw-bold'>{titulo}</td>
                <td>{valor}</td>
            </tr>
        </>
}

export default FHOpcional
