import React, {useEffect, useState} from "react";
import {useAppSelector} from "../redux/hooks";
import BotonIcono from "../controles/BotonIcono";
import {getMes} from "../meses";
import './HorarioEmpleado.css'
import Modal from "../controles/Modal";
import ModalBotones from "../controles/ModalBotones";
import InputSelect, {OpcionSelect} from "../controles/InputSelect";
import InputTexto from "../controles/InputTexto";
import InputArchivo from "../controles/InputArchivo";
import Boton from "../controles/Boton";
import {urlDescargaNovedad} from "../descargas/descargasLegajo";
import RegistroProcesoFichadas from "./RegistroProcesoFichadas";
import ToolbarBtn from "../controles/ToolbarBtn";
import TarjetaCarga from "../controles/TarjetaCarga";
import BotonesExportar from "../controles/BotonesExportar";
import FichadasEmpleado from "./FichadasEmpleado";
import CargaNovedadRango from "./CargaNovedadRango";
import Tooltip, {InfoTooltip} from "../controles/Tooltip";
import TrInfo from "../controles/TrInfo";
import {useNavigate, useParams} from "react-router-dom";
import BarraSuperior from "../controles/BarraSuperior";
import CuerpoPrincipal from "../controles/CuerpoPrincipal";
import {useGsBackend} from "../funcionesApi";
import {funcionDescarga} from "../descargas/descargas";

type DatosEmp = {
    apellido: string,
    nombre: string,
    tipo_horario: number
}

type Fichada = {
    hora: string,
    entrada: boolean
}

type Horario = {
    fecha: string,
    fecha_fmt: string,
    fuera_mes: boolean,
    dia_semana: boolean,
    laborable: boolean,
    horario: string,
    inconsistencia: boolean,
    inasistencia: boolean,
    novedad: boolean,
    id_novedad: number,
    id_tipo_novedad: number,
    tipo_novedad: string,
    comentarios_novedad: string,
    usuario_novedad: string,
    nombreyapellido_novedad: string,
    archivo_novedad: string,
    pasado_futuro: number,
    fichadas: Array<Fichada>,
    vacaciones: boolean
}

type Periodo = {
    anio: number,
    mes: number
}

const HorarioEmpleado: React.FC = () => {
    const [editando, setEditando] = useState<string | undefined>(undefined)
    const [empleado, setEmpleado] = useState<DatosEmp | undefined>(undefined)
    const [horarios, setHorarios] = useState<Array<Horario>>([] as Array<Horario>)
    const [tiposNovedades, setTiposNovedades] = useState<Array<OpcionSelect>>([] as Array<OpcionSelect>)
    const [editId, setEditId] = useState<number>(0)
    const [editFecha, setEditFecha] = useState<Array<string>>(['', ''] as Array<string>)
    const [editTipo, setEditTipo] = useState<number>(0)
    const [editComentarios, setEditComentarios] = useState<string>('')
    const [editArchivo, setEditArchivo] = useState<File | undefined>(undefined)
    const [editDocumento, setEditDocumento] = useState<string>('')
    const [registroProcesoCarga, setRegistroProcesoCarga] = useState<boolean>(false)
    const [cargaNovedadRango, setCargaNovedadRango] = useState<boolean>(false)
    const [mostrarEdicionFichadas, setMostrarEdicionFichadas] = useState<boolean>(false)
    const [mostrarEdicionHorario, setMostrarEdicionHorario] = useState<boolean>(false)
    const [forzarActualizacion, setForzarActualizacion] = useState<boolean>(false)
    const [infoTooltip, setInfoTooltip] = useState<InfoTooltip | undefined>(undefined)
    const edita = useAppSelector(state => state.auth.edicion)
    const params = useParams()
    const navigate = useNavigate()
    const numIdEmpleado = parseInt(params.idEmpleado || '0')

    const fecha = new Date()
    const [periodo, setPeriodo] = useState<Periodo>({
        anio: fecha.getFullYear(),
        mes: fecha.getMonth() + 1
    } as Periodo)

    const {pedidoJson, pedidoBlob, errorCarga, actualizar, cargando} = useGsBackend()
    useEffect(() => {
        pedidoJson({
            url: `legajos/horarios/${numIdEmpleado}?anio=${periodo.anio}&mes=${periodo.mes}`,
            sinErrorDispatch: true, ok: res => {
                // Si no habia un id valido volver a Legajos
                if (res.empleado === undefined) navigate('../../')

                setEmpleado(res.empleado)
                setHorarios(res.horario)
                setTiposNovedades(res.tipos_novedades)
            }
        })
    }, [pedidoJson, numIdEmpleado, periodo, navigate])

    const periodoHandler = (diferencia: number): void => {
        if (diferencia !== 1 && diferencia !== -1) return

        let mes = periodo.mes + diferencia
        let anio = periodo.anio
        if (mes < 1) {
            anio--
            mes = 12
        } else if (mes > 12) {
            anio++
            mes = 1
        }

        setPeriodo({
            anio: anio,
            mes: mes
        } as Periodo)
    }

    const editarHandler = (fecha: string | undefined): void => {
        setEditArchivo(undefined)
        if (fecha === undefined) {
            if (forzarActualizacion) actualizar()
            setEditando(undefined)
        } else {
            const item = horarios.filter(h => h.fecha === fecha)[0]
            if (item === undefined) return

            setEditId(item.id_novedad)
            setEditFecha([item.fecha_fmt, item.fecha])
            setEditTipo(item.id_tipo_novedad)
            setEditComentarios(item.comentarios_novedad)
            setEditDocumento(item.archivo_novedad)
            setMostrarEdicionFichadas(item.pasado_futuro !== 2)
            setMostrarEdicionHorario(item.pasado_futuro === 0)
            setEditando(fecha)
        }

        // Restablecer el pedido de actualizacion forzada
        setForzarActualizacion(false)
    }

    const guardarHandler = async (): Promise<void> => {
        if (editando === undefined) return

        const fd = new FormData()
        fd.append('accion', 'editar')
        fd.append('fecha', editFecha[1])
        fd.append('tipo', editTipo.toString())
        fd.append('comentarios', editComentarios)
        if (editArchivo !== undefined) fd.append('archivo', editArchivo, editArchivo.name)

        pedidoJson({
            url: `legajos/horarios/${numIdEmpleado}`, method: 'post', body: fd,
            ok: () => {
                actualizar()
                editarHandler(undefined)
            }
        })
    }

    if (empleado !== undefined) {
        const nombreCompleto = `${empleado.apellido}, ${empleado.nombre}`

        const vistaMes = () => {
            let grupos = [] as Array<Array<Horario>>
            for (let i = 0; i < horarios.length; i += 7) grupos.push(horarios.slice(i, i + 7))

            return grupos.map((g, i) => (
                <tr style={{height: '80px'}} key={i}>
                    {g.map((h, k) => (
                        <td style={{width: '14%'}} className={(h.fuera_mes ? 'bg-primary bg-opacity-50' :
                            (h.pasado_futuro === 1 ? 'bg-info bg-opacity-50' :
                                h.novedad ? 'bg-success bg-opacity-25' :
                                    h.vacaciones ? 'bg-info bg-opacity-25' :
                                        !h.laborable ? 'bg-secondary bg-opacity-25' : '')
                            + (edita ? ' celdahover' : '')) + ' py-0 px-1'}
                            onClick={() => {
                                if (edita && !h.fuera_mes) editarHandler(h.fecha)
                            }} key={k}>
                            <div className='text-start small'>{h.fecha_fmt.substring(0, 2)}</div>
                            {h.fuera_mes ? '' : h.pasado_futuro === 1 ? 'Hoy' :
                                h.vacaciones || empleado.tipo_horario === 1 ? '-' :
                                    (h.pasado_futuro === 0 && !(h.horario === '00:00' && !h.laborable) ?
                                        h.horario : '-')}<br/>
                            {!h.fuera_mes && (
                                h.novedad ? <i className='fas fa-check text-success'/> :
                                    h.vacaciones ? <i className='fas fa-plane text-info'/> :
                                        h.pasado_futuro === 1 ? <i className='fas fa-calendar-alt text-primary'/> :
                                            h.inconsistencia ?
                                                <i className='fas fa-exclamation-triangle text-warning'/> :
                                                h.inasistencia ? <i className='fas fa-times-circle text-danger'/> :
                                                    <></>
                            )}
                        </td>
                    ))}
                </tr>
            ))
        }

        const tablaMes = () => {
            const hr = horarios.filter(h => empleado.tipo_horario === 1 ? !h.fuera_mes && h.novedad : (
                !h.fuera_mes && (((h.pasado_futuro !== 2 &&
                        (h.laborable || h.horario !== '00:00')) &&
                    !(h.vacaciones && h.pasado_futuro !== 1)) || h.novedad))
            )

            const enlaceDescarga = (h: Horario): React.ReactNode => {
                return h.archivo_novedad === '' ? <></> : (
                    <div className='small ms-3'>
                        <span className='enlacespan text-primary' style={{cursor: 'pointer'}}
                              onClick={e => {
                                  e.stopPropagation()
                                  pedidoBlob({
                                      url: urlDescargaNovedad(numIdEmpleado, h.id_novedad),
                                      ok: res => funcionDescarga(res, h.archivo_novedad)
                                  })
                              }}
                              onMouseEnter={() => {
                                  setInfoTooltip({
                                      urlPreview: `previews?tipo=novedades&id_novedad=${h.id_novedad}`
                                  })
                              }}
                              onMouseLeave={() => setInfoTooltip(undefined)}>
                            <i className='fas fa-file-download me-2'/>{h.archivo_novedad}
                        </span>
                    </div>
                )
            }

            const muestraComentarios = (h: Horario): React.ReactNode => {
                return h.comentarios_novedad === '' ? <></> : (
                    <div className='small ms-3'>{h.comentarios_novedad}</div>
                )
            }

            if (hr.length) {
                return hr.map((h, i) => (
                    <tr className={(h.pasado_futuro === 1 ? 'bg-info bg-opacity-50' :
                        h.novedad ? 'bg-success bg-opacity-25' : '') + (edita ? ' cursorpointer' : '')}
                        onClick={() => {
                            if (edita) editarHandler(h.fecha)
                        }} key={i}>
                        <td>{h.fecha_fmt}</td>
                        {h.pasado_futuro === 1 ? (
                            <td>
                                <i className='fas fa-calendar-alt text-primary me-1'/>
                                {h.novedad ? h.tipo_novedad : 'Hoy'}
                                {muestraComentarios(h)}
                                {enlaceDescarga(h)}
                            </td>
                        ) : (
                            <td>
                                {h.novedad ? (
                                        <>
                                            <i className='fas fa-check text-success me-1'/>
                                            {h.tipo_novedad}
                                            {muestraComentarios(h)}
                                            {enlaceDescarga(h)}
                                        </>
                                    ) :
                                    h.inconsistencia ? <>
                                            <i className='fas fa-exclamation-triangle text-warning me-1'/>
                                            Inconsistencia
                                        </> :
                                        h.inasistencia ? <>
                                                <i className='fas fa-times-circle text-danger me-1'/>
                                                Inasistencia
                                            </> :
                                            <></>}
                            </td>
                        )}
                        {empleado.tipo_horario !== 1 && (<td>
                            <div className='row'>
                                {h.fichadas.map((f, i) => (
                                    <div className='col-3 text-nowrap' key={i}>
                                        <i className={(f.entrada ? 'fas fa-sign-in-alt text-primary' :
                                            'fas fa-sign-out-alt text-muted') + ' me-1'}/>
                                        {f.hora}
                                    </div>
                                ))}
                            </div>
                        </td>)}
                    </tr>
                ))
            } else {
                return (
                    <TrInfo colSpan={3} texto='No hay horario ni novedades para este período en el Sistema'/>
                )
            }
        }

        const modalEdicion = () => {
            if (editando !== undefined) {
                return (
                    <Modal className='w-50'>
                        <h4>Carga de Novedades</h4>
                        <div className='row mb-2 pt-1'>
                            <div className='col-sm-3 fw-bold'>Empleado</div>
                            <div className='col-sm-9'>{nombreCompleto}</div>
                        </div>
                        <div className='row mb-2 pt-1'>
                            <div className='col-sm-3 fw-bold'>Fecha</div>
                            <div className='col-sm-9'>{editFecha[0]}</div>
                        </div>
                        <div className='row mb-2'>
                            <div className='col-sm-3 fw-bold pt-1'>Tipo</div>
                            <div className='col-sm-9'>
                                <InputSelect value={editTipo} onChange={setEditTipo} opciones={tiposNovedades}/>
                            </div>
                        </div>
                        {editTipo !== 0 && (
                            <>
                                <div className='row mb-2'>
                                    <div className='col-sm-3'>
                                        <span className='fw-bold'>Comentarios</span><br/>
                                        <span className='text-muted small'>(Opcional)</span>
                                    </div>
                                    <div className='col-sm-9'>
                                        <InputTexto value={editComentarios} onChange={setEditComentarios}/>
                                    </div>
                                </div>
                                {editDocumento !== '' && (
                                    <div className='row mb-2'>
                                        <div className='col-sm-3 pt-1 fw-bold'>Documento adjunto</div>
                                        <div className='col-sm-9'>
                                            <Boton titulo={editDocumento} icono='fas fa-download' chico
                                                   onClick={() => {
                                                       pedidoBlob({
                                                           url: urlDescargaNovedad(numIdEmpleado, editId),
                                                           ok: res => funcionDescarga(res, editDocumento)
                                                       })
                                                   }}/>
                                        </div>
                                    </div>
                                )}
                                <div className='row mb-2'>
                                    <div className='col-sm-3'>
                                        <span className='fw-bold'>
                                            {editDocumento === '' ? 'Adjuntar documento' : 'Nuevo documento'}
                                        </span><br/>
                                        <span className='text-muted small'>(Opcional)</span>
                                    </div>
                                    <div className='col-sm-9'>
                                        <InputArchivo onChange={e => setEditArchivo(e)}/>
                                    </div>
                                </div>
                            </>
                        )}
                        {empleado.tipo_horario !== 1 && mostrarEdicionFichadas &&
                            <FichadasEmpleado empleado={numIdEmpleado} fecha={editFecha[1]}
                                              mostrarHorario={mostrarEdicionHorario}
                                              onChange={() => setForzarActualizacion(true)}/>}
                        <ModalBotones soloEditar guardando={cargando}
                                      onGuardar={() => guardarHandler()}
                                      onCancelar={() => editarHandler(undefined)}/>
                    </Modal>
                )
            } else {
                return <></>
            }
        }

        return (
            <div>
                <BarraSuperior>
                    <ToolbarBtn>
                        <Boton toolbar titulo='Volver al Legajo' icono='fas fa-arrow-left'
                               className='btn-secondary' onClick={() => navigate('../')}/>
                        {edita && <Boton toolbar titulo='Cargar novedad por rango' icono='fas fa-calendar-plus'
                                         onClick={() => setCargaNovedadRango(true)}/>}
                        {edita && <Boton toolbar titulo='Registro del Proceso de Fichadas' icono='fas fa-list'
                                         onClick={() => setRegistroProcesoCarga(true)}/>}
                        <BotonesExportar onExcel={() => alert('Exportación a Excel en Desarrollo')}
                                         onPdf={() => alert('Exportación a PDF en Desarrollo')}/>
                    </ToolbarBtn>
                </BarraSuperior>
                <CuerpoPrincipal>
                    <h4>
                        <strong>{nombreCompleto}</strong> - Novedades, horario y fichadas del Empleado
                    </h4>
                    <div className='row'>
                        <div className='col-md-6'>
                            <table className='table table-responsive table-bordered table-sm small text-center'>
                                <thead>
                                <tr>
                                    <th>
                                        <BotonIcono onClick={() => periodoHandler(-1)}
                                                    icono='fas fa-arrow-left' estilo='btn-primary'/>
                                    </th>
                                    <th colSpan={5} className='align-middle'>
                                        {getMes(periodo.mes)} de {periodo.anio}
                                    </th>
                                    <th>
                                        <BotonIcono onClick={() => periodoHandler(1)}
                                                    icono='fas fa-arrow-right' estilo='btn-primary'/>
                                    </th>
                                </tr>
                                <tr>
                                    <th>Dom</th>
                                    <th>Lun</th>
                                    <th>Mar</th>
                                    <th>Mié</th>
                                    <th>Jue</th>
                                    <th>Vie</th>
                                    <th>Sáb</th>
                                </tr>
                                </thead>
                                <tbody>{vistaMes()}</tbody>
                            </table>
                        </div>
                        <div className='col-md-6'>
                            <table className={'table table-responsive table-bordered table-striped table-sm small' +
                                (edita ? ' table-hover' : '')}>
                                <thead>
                                <tr>
                                    <th>Fecha</th>
                                    <th>Estado</th>
                                    {empleado.tipo_horario !== 1 && <th>Registro</th>}
                                </tr>
                                </thead>
                                <tbody>
                                {tablaMes()}
                                </tbody>
                            </table>
                        </div>
                    </div>
                </CuerpoPrincipal>
                {modalEdicion()}
                {registroProcesoCarga && <RegistroProcesoFichadas
                    onCerrar={() => setRegistroProcesoCarga(false)}/>}
                {cargaNovedadRango && <CargaNovedadRango empleado={empleado.nombre} idEmpleado={numIdEmpleado}
                                                         onCancelar={() => setCargaNovedadRango(false)}
                                                         onCarga={() => {
                                                             setCargaNovedadRango(false)
                                                             actualizar()
                                                         }}
                                                         tiposNovedades={tiposNovedades}/>}
                {infoTooltip !== undefined && <Tooltip datos={infoTooltip}/>}
            </div>
        )
    } else {
        return <TarjetaCarga error={errorCarga} onReintentar={actualizar} onVolver={() => navigate('../')}/>
    }
}

export default HorarioEmpleado
