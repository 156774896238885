import React, {useEffect, useState} from "react";
import IconoCarga from "../../controles/IconoCarga";
import InputFecha from "../../controles/InputFecha";
import TrCarga from "../../controles/TrCarga";
import {useGsBackend} from "../../funcionesApi";

type Info = {
    cantidad: number,
    porcentaje: number,
    tipo_novedad: string
}

const NovedadesPorTipo: React.FC = () => {
    const inicio = (): string => new Date().getFullYear().toString() + '-01-01'
    const hoy = (): string => new Date().toISOString().slice(0, 10)
    const [datos, setDatos] = useState<Array<Info> | undefined>(undefined)
    const [grafico, setGrafico] = useState<string | undefined>(undefined)
    const [fechaDesde, setFechaDesde] = useState<string>(inicio)
    const [fechaHasta, setFechaHasta] = useState<string>(hoy)

    const {pedidoJson, cargando} = useGsBackend()
    useEffect(() => {
        pedidoJson({
            url: 'reportes/novedades_tipo?' +
                'desde=' + encodeURIComponent(fechaDesde) + '&' +
                'hasta=' + encodeURIComponent(fechaHasta),
            ok: res => {
                const novs: Array<Info> = res.novedades_tipo
                setDatos(novs)
                setGrafico(novs.length ? res.grafico : undefined)
            }
        })
    }, [pedidoJson, fechaDesde, fechaHasta])

    const desdeHandler = (arg: string): void => {
        setFechaDesde(arg === '' ? inicio : arg)
    }

    const hastaHandler = (arg: string): void => {
        setFechaHasta(arg === '' ? hoy : arg)
    }

    if (datos !== undefined) {
        return <div className='row g-3'>
            <div className='col-xxl-6'>
                <div className='row g-2 mb-2'>
                    <div className='col-sm-6'>
                        <InputFecha value={fechaDesde} onChange={desdeHandler} titulo='Desde' chico/>
                    </div>
                    <div className='col-sm-6'>
                        <InputFecha value={fechaHasta} onChange={hastaHandler} titulo='Hasta' chico/>
                    </div>
                </div>
                <table className='table table-responsive table-bordered table-striped table-sm'>
                    <thead>
                    <tr>
                        <th>Cantidad</th>
                        <th>Porcentaje</th>
                        <th>Tipo</th>
                    </tr>
                    </thead>
                    <tbody>
                    {cargando ? <TrCarga colSpan={3}/> : datos.length ? datos.map((d, i) => (
                        <tr key={i}>
                            <td className='text-end'>{d.cantidad}</td>
                            <td className='text-end'>{d.porcentaje}%</td>
                            <td>{d.tipo_novedad}</td>
                        </tr>
                    )) : (
                        <tr>
                            <td colSpan={3}>
                                <i className='fas fa-times-circle me-2 text-danger'/>
                                Sin novedades para mostrar en el período seleccionado
                            </td>
                        </tr>
                    )}
                    </tbody>
                </table>
            </div>
            <div className='col-xxl-6'>
                {grafico !== undefined &&
                    <img src={`data:image/png;base64,${grafico}`} alt='Gráfico'
                         className='img-fluid border'/>}
            </div>
        </div>
    } else {
        return <IconoCarga/>
    }
}

export default NovedadesPorTipo
