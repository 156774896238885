import React from "react";

type EstadoVacacionesProps = {
    vacaciones: boolean
}

const EstadoVacaciones: React.FC<EstadoVacacionesProps> = ({vacaciones}) => {
    if (vacaciones) {
        return (
            <div className='row mb-2'>
                <div className='col-auto'>
                    <i className='fas fa-plane text-info'/>
                </div>
                <div className='col p-0 text-info'>
                    En Vacaciones
                </div>
            </div>
        )
    } else {
        return <></>
    }
}

export default EstadoVacaciones
