import React, {useEffect, useState} from "react";
import Boton from "../controles/Boton";
import ToolbarBtn from "../controles/ToolbarBtn";
import TarjetaCarga from "../controles/TarjetaCarga";
import Modal from "../controles/Modal";
import ModalBotones from "../controles/ModalBotones";
import CheckEstado from "../controles/CheckEstado";
import InputTexto from "../controles/InputTexto";
import TarjetaSeccion from "../controles/TarjetaSeccion";
import InputSelect, {OpcionSelect} from "../controles/InputSelect";
import StockCargarOrden from "./StockCargarOrden";
import TrInfo from "../controles/TrInfo";
import {useNavigate} from "react-router-dom";
import BarraSuperior from "../controles/BarraSuperior";
import CuerpoPrincipal from "../controles/CuerpoPrincipal";
import {useGsBackend} from "../funcionesApi";

export interface Proveedor {
    id: number,
    razon_social: string,
    direccion: string,
    contacto: string,
    email: string,
    telefono: string,
    observaciones: string,
    habilitado: boolean,
    eliminable: boolean
}

type InfoProveedores = {
    proveedores: Array<Proveedor>
}

type ValidacionEdicion = {
    razon_social: boolean,
    direccion: boolean
}

const StockProveedores: React.FC = () => {
    const validacionOk: ValidacionEdicion = {razon_social: true, direccion: true}
    const [datos, setDatos] = useState<InfoProveedores | undefined>(undefined)
    const [editando, setEditando] = useState<number | undefined>(undefined)
    const [editRazonSocial, setEditRazonSocial] = useState<string>('')
    const [editDireccion, setEditDireccion] = useState<string>('')
    const [editContacto, setEditContacto] = useState<string>('')
    const [editEmail, setEditEmail] = useState<string>('')
    const [editTelefono, setEditTelefono] = useState<string>('')
    const [editObservaciones, setEditObservaciones] = useState<string>('')
    const [editHabilitado, setEditHabilitado] = useState<number>(1)
    const [eliminable, setEliminable] = useState<boolean>(false)
    const [cargarOrden, setCargarOrden] = useState<Proveedor | undefined>(undefined)
    const [validacion, setValidacion] = useState<ValidacionEdicion>(validacionOk)
    const navigate = useNavigate()

    const opcionesHabilitado = [
        {id: 1, descripcion: 'Sí'},
        {id: 2, descripcion: 'No'}
    ] as Array<OpcionSelect>

    const {pedidoJson, errorCarga, actualizar, cargando} = useGsBackend()

    useEffect(() => {
        pedidoJson({url: 'stock/proveedores', method: 'get', ok: setDatos})
    }, [pedidoJson])

    const editarHandler = (id: number | undefined): void => {
        if (id === undefined) {
            setEditando(undefined)
        } else if (id === 0) {
            setEditRazonSocial('')
            setEditDireccion('')
            setEditContacto('')
            setEditEmail('')
            setEditTelefono('')
            setEditObservaciones('')
            setEditHabilitado(1)
            setValidacion(validacionOk)
            setEditando(id)
        } else if (id > 0) {
            const item = datos?.proveedores.filter(p => p.id === id)[0]
            if (item === undefined) return
            setEditRazonSocial(item.razon_social)
            setEditDireccion(item.direccion)
            setEditContacto(item.contacto)
            setEditEmail(item.email)
            setEditTelefono(item.telefono)
            setEditObservaciones(item.observaciones)
            setEditHabilitado(item.habilitado ? 1 : 2)
            setEliminable(item.eliminable)
            setValidacion(validacionOk)
            setEditando(id)
        }
    }

    const guardarHandler = async (eliminar?: boolean): Promise<void> => {
        if (editando === undefined) return

        const vld: ValidacionEdicion = {
            razon_social: editRazonSocial !== '',
            direccion: editDireccion !== ''
        }
        setValidacion(vld)
        if (!vld.razon_social || !vld.direccion) return

        const fd = new FormData()
        fd.append('accion', editando === 0 ? 'nuevo' : eliminar ? 'eliminar' : 'editar')
        fd.append('id', editando.toString())
        fd.append('razon_social', editRazonSocial)
        fd.append('direccion', editDireccion)
        fd.append('contacto', editContacto)
        fd.append('email', editEmail)
        fd.append('telefono', editTelefono)
        fd.append('observaciones', editObservaciones)
        fd.append('habilitado', editHabilitado === 1 ? '1' : '0')

        pedidoJson({
            url: 'stock/proveedores', method: 'post', body: fd,
            ok: () => {
                editarHandler(undefined)
                actualizar()
            }
        })
    }

    if (datos === undefined) {
        return (
            <TarjetaCarga error={errorCarga} onReintentar={actualizar}
                          onVolver={() => navigate('../../')}/>
        )
    } else {
        return (
            <div>
                <BarraSuperior>
                    <ToolbarBtn>
                        <Boton toolbar titulo='Volver' icono='fas fa-arrow-left' className='btn-secondary'
                               onClick={() => navigate('../../')}/>
                        <Boton toolbar titulo='Alta de Proveedor' icono='fas fa-plus'
                               onClick={() => editarHandler(0)}/>
                    </ToolbarBtn>
                </BarraSuperior>
                <CuerpoPrincipal sinEspacio>
                    <h4>
                        <i className='fas fa-truck text-primary me-2'/>
                        Proveedores
                    </h4>
                </CuerpoPrincipal>
                <table className='table table-responsive table-striped table-hover'>
                    <thead>
                    <tr>
                        <th style={{width: '30%'}} className='ps-3'>Proveedor</th>
                        <th style={{width: '30%'}}>Contacto</th>
                        <th style={{width: '30%'}}>Observaciones</th>
                        <th style={{width: '10%'}}>Habilitado</th>
                        <th>Acciones</th>
                    </tr>
                    </thead>
                    <tbody>
                    {datos.proveedores.length ? datos.proveedores.map((p, k) => (
                        <tr key={k} style={{cursor: 'pointer'}} onClick={() => editarHandler(p.id)}>
                            <td className='ps-3'>
                                <div className='fw-bold'>{p.razon_social}</div>
                                <div className='small'>
                                    <i className='fas fa-location-dot text-muted me-2'/>
                                    {p.direccion}
                                </div>
                            </td>
                            <td className='small'>
                                {p.contacto !== '' && (
                                    <div className='fw-bold'>
                                        {p.contacto}
                                    </div>
                                )}
                                {p.email !== '' && (
                                    <div>
                                        <i className='fas fa-envelope text-muted me-2'/>
                                        {p.email}
                                    </div>
                                )}
                                {p.telefono !== '' && (
                                    <div>
                                        <i className='fas fa-phone text-muted me-2'/>
                                        {p.telefono}
                                    </div>
                                )}
                            </td>
                            <td>
                                {p.observaciones}
                            </td>
                            <td>
                                <CheckEstado estado={p.habilitado} textosino/>
                            </td>
                            <td className='text-nowrap'>
                                {p.habilitado && (
                                    <Boton chico toolbar titulo='Orden de Compra' icono='fas fa-cart-shopping'
                                           onClick={e => {
                                               e.stopPropagation()
                                               setCargarOrden(p)
                                           }}/>
                                )}
                                <Boton chico toolbar titulo='Historial' icono='fas fa-clock' onClick={e => {
                                    e.stopPropagation()
                                    navigate(`${p.id}/historial`)
                                }}/>
                            </td>
                        </tr>
                    )) : (
                        <TrInfo colSpan={5} texto='No se encuentran proveedores para mostrar'/>
                    )}
                    </tbody>
                </table>
                {editando !== undefined && (
                    <Modal className='w-75'>
                        <h4>{editando === 0 ? 'Alta de Proveedor' : 'Editar Proveedor'}</h4>
                        <TarjetaSeccion>
                            <div className='row'>
                                <div className='col-md-5'>
                                    <InputTexto titulado titulo='Razón Social' value={editRazonSocial}
                                                onChange={setEditRazonSocial} autoFocus
                                                invalido={!validacion.razon_social}/>
                                </div>
                                <div className='col-md-7'>
                                    <InputTexto titulado titulo='Dirección' value={editDireccion}
                                                onChange={setEditDireccion}
                                                invalido={!validacion.direccion}/>
                                </div>
                            </div>
                        </TarjetaSeccion>
                        <TarjetaSeccion>
                            <div className='row'>
                                <div className='col-md-4'>
                                    <InputTexto titulado titulo='Contacto' value={editContacto}
                                                onChange={setEditContacto}/>
                                </div>
                                <div className='col-md-4'>
                                    <InputTexto titulado titulo='E-Mail' value={editEmail}
                                                onChange={setEditEmail}/>
                                </div>
                                <div className='col-md-4'>
                                    <InputTexto titulado titulo='Teléfono' value={editTelefono}
                                                onChange={setEditTelefono}/>
                                </div>
                            </div>
                            <div className='row'>
                                <div className='col-md-8'>
                                    <InputTexto titulado titulo='Observaciones' value={editObservaciones}
                                                onChange={setEditObservaciones}/>
                                </div>
                                <div className='col-md-4'>
                                    <InputSelect titulado titulo='Habilitado ' value={editHabilitado}
                                                 onChange={setEditHabilitado} opciones={opcionesHabilitado}/>
                                </div>
                            </div>
                        </TarjetaSeccion>
                        <ModalBotones editando={editando > 0} soloEditar={editando > 0 && !eliminable}
                                      onCancelar={() => editarHandler(undefined)} guardando={cargando}
                                      onEliminar={() => guardarHandler(true).catch(err => alert(err))}
                                      onGuardar={guardarHandler}/>
                    </Modal>
                )}
                {cargarOrden && (
                    <StockCargarOrden proveedor={cargarOrden}
                                      onCancelar={() => setCargarOrden(undefined)}
                                      onGuardar={() => {
                                          setCargarOrden(undefined)
                                          actualizar()
                                      }}/>
                )}
            </div>
        )
    }
}

export default StockProveedores