import React, {useEffect, useState} from "react";
import Modal from "../controles/Modal";
import ModalBotones from "../controles/ModalBotones";
import {Proveedor} from "./StockProveedores";
import TarjetaSeccion from "../controles/TarjetaSeccion";
import InputTexto from "../controles/InputTexto";
import InputArchivo from "../controles/InputArchivo";
import InputSelect, {OpcionSelect} from "../controles/InputSelect";
import BotonIcono from "../controles/BotonIcono";
import InputMoneda from "../controles/InputMoneda";
import {formatoMoneda} from "../controles/formatoMoneda";
import TrInfo from "../controles/TrInfo";
import {useGsBackend} from "../funcionesApi";

type Props = {
    proveedor: Proveedor,
    onCancelar: () => void,
    onGuardar: () => void
}

type ProductoOrden = {
    id: number,
    id_producto: number,
    desc_producto: string,
    id_sector: number,
    desc_sector: string,
    cantidad: number,
    cantidad_fmt: string,
    costo: number,
    costo_fmt: string
}

type Producto = {
    id: number,
    descripcion: string,
    unidaddemedida: string,
    id_sectorpredeterminado: number
}

type InfoCargaOrden = {
    productos: Array<Producto>,
    sectores: Array<OpcionSelect>
}

type ValidRenglon = {
    producto: boolean,
    sector: boolean,
    cantidad: boolean,
    costo: boolean
}

const StockCargarOrden: React.FC<Props> = ({proveedor, onCancelar, onGuardar}) => {
    const validRenglonOk: ValidRenglon = {producto: true, sector: true, cantidad: true, costo: true}
    const [datos, setDatos] = useState<InfoCargaOrden | undefined>(undefined)
    const [nroFactura, setNroFactura] = useState<string>('')
    const [comentarios, setComentarios] = useState<string>('')
    const [documento, setDocumento] = useState<File | undefined>(undefined)
    const [productosOrden, setProductosOrden] = useState<Array<ProductoOrden>>([] as Array<ProductoOrden>)
    const [nvoProducto, setNvoProducto] = useState<number>(0)
    const [nvoSector, setNvoSector] = useState<number>(0)
    const [nvoCantidad, setNvoCantidad] = useState<string>('0')
    const [nvoCosto, setNvoCosto] = useState<number>(0)
    const [nvoValid, setNvoValid] = useState<ValidRenglon>(validRenglonOk)
    const [validFactura, setValidFactura] = useState<boolean>(true)
    const [rngId, setRngId] = useState<number>(1)

    const {pedidoJson, cargando} = useGsBackend()

    useEffect(() => {
        pedidoJson({url: 'stock/ordenesdecompra/infocarga', method: 'get', ok: setDatos})
    }, [pedidoJson])

    const agregarHandler = (): void => {
        if (datos === undefined) return

        const vld: ValidRenglon = {
            producto: nvoProducto !== 0,
            sector: nvoSector !== 0,
            cantidad: nvoCantidad !== '0',
            costo: nvoCosto !== 0
        }
        setNvoValid(vld)
        if (!vld.producto || !vld.sector || !vld.cantidad || !vld.costo) return

        // Agregar producto a la lista
        const infoProd = datos.productos.filter(p => p.id === nvoProducto)[0]
        const infoDep = datos.sectores.filter(d => d.id === nvoSector)[0]
        const nvo: ProductoOrden = {
            id: rngId,
            id_producto: nvoProducto,
            desc_producto: infoProd?.descripcion || '',
            id_sector: nvoSector,
            desc_sector: infoDep?.descripcion || '',
            cantidad: parseFloat(nvoCantidad),
            cantidad_fmt: nvoCantidad + ' ' + (infoProd?.unidaddemedida || ''),
            costo: nvoCosto,
            costo_fmt: formatoMoneda(nvoCosto)
        }

        setRngId(rngId + 1)
        setProductosOrden(v => [...v, nvo])

        setNvoProducto(0)
        setNvoSector(0)
        setNvoCosto(0)
        setNvoCantidad('0')
    }

    const quitarHandler = (arg: number): void => {
        setProductosOrden(v => v.filter(p => p.id !== arg))
    }

    const guardarHandler = async (): Promise<void> => {
        const vld = nroFactura !== ''
        setValidFactura(vld)
        if (!vld) return

        const fd = new FormData()
        fd.append('accion', 'nuevo')
        fd.append('id_proveedor', proveedor.id.toString())
        fd.append('nro_factura', nroFactura)
        fd.append('comentarios', comentarios)
        if (documento !== undefined) fd.append('archivo', documento, documento.name)
        fd.append('datos_orden', JSON.stringify(productosOrden))

        pedidoJson({
            url: 'stock/ordenesdecompra/nueva', method: 'post', body: fd,
            ok: onGuardar
        })
    }

    if (datos === undefined) {
        return <></>
    } else {
        return (
            <Modal className='w-50'>
                <h4>Nueva Orden de Compra ({proveedor.razon_social})</h4>
                <TarjetaSeccion>
                    <div className='row mb-2'>
                        <div className='col-md-3 pt-1'>
                            Nro. Factura / Remito
                        </div>
                        <div className='col-md-9'>
                            <InputTexto value={nroFactura} onChange={setNroFactura} autoFocus
                                        invalido={!validFactura}/>
                        </div>
                    </div>
                    <div className='row mb-2'>
                        <div className='col-md-3 pt-1'>
                            Comentarios
                        </div>
                        <div className='col-md-9'>
                            <InputTexto value={comentarios} onChange={setComentarios}/>
                        </div>
                    </div>
                    <div className='row'>
                        <div className='col-md-3 pt-1'>
                            Documento
                        </div>
                        <div className='col-md-9'>
                            <InputArchivo onChange={e => setDocumento(e)}/>
                        </div>
                    </div>
                </TarjetaSeccion>
                <TarjetaSeccion>
                    <table className='table table-responsive table-bordered table-striped table-sm'>
                        <thead>
                        <tr>
                            <th style={{width: '40%'}}>Producto</th>
                            <th style={{width: '15%'}}>Cantidad</th>
                            <th style={{width: '30%'}}>Sector</th>
                            <th style={{width: '15%'}}>Costo</th>
                            <th>&nbsp;</th>
                        </tr>
                        </thead>
                        <tbody>
                        {productosOrden.length ? productosOrden.map((p, k) => (
                            <tr key={k}>
                                <td>{p.desc_producto}</td>
                                <td className='text-nowrap'>{p.cantidad_fmt}</td>
                                <td>{p.desc_sector}</td>
                                <td className='text-end text-nowrap'>{p.costo_fmt}</td>
                                <td>
                                    <BotonIcono onClick={() => quitarHandler(p.id)} estilo='btn-light text-danger'
                                                icono='fas fa-trash'/>
                                </td>
                            </tr>
                        )) : (
                            <TrInfo colSpan={5} texto='Ningún producto agregado a la Orden de compra'/>
                        )}
                        </tbody>
                        <thead>
                        <tr>
                            <th colSpan={3}>
                                Total: {productosOrden.length} {productosOrden.length === 1 ? 'Producto' : 'Productos'}
                            </th>
                            <th className='text-nowrap text-end'>
                                {formatoMoneda(productosOrden.map(p => p.costo)
                                    .reduce((s, v) => s + v, 0))}
                            </th>
                            <th>&nbsp;</th>
                        </tr>
                        <tr>
                            <th colSpan={5}>Agregar producto</th>
                        </tr>
                        </thead>
                        <tbody>
                        <tr>
                            <td>
                                <InputSelect chico value={nvoProducto} onChange={v => {
                                    setNvoSector(datos.productos
                                        .filter(d => d.id === v)[0]?.id_sectorpredeterminado || 0)
                                    setNvoProducto(v)
                                }}
                                             opciones={datos.productos.map(p => {
                                                 return {id: p.id, descripcion: p.descripcion} as OpcionSelect
                                             })} invalido={!nvoValid.producto}/>
                            </td>
                            <td>
                                <InputTexto value={nvoCantidad} onChange={setNvoCantidad} numerico chico
                                            invalido={!nvoValid.cantidad}/>
                            </td>
                            <td>
                                <InputSelect chico value={nvoSector} onChange={setNvoSector}
                                             opciones={datos.sectores} invalido={!nvoValid.sector}/>
                            </td>
                            <td>
                                <InputMoneda value={nvoCosto} onChange={setNvoCosto} chico
                                             invalido={!nvoValid.costo}/>
                            </td>
                            <td>
                                <BotonIcono onClick={agregarHandler} icono='fas fa-check' estilo='btn-success'/>
                            </td>
                        </tr>
                        </tbody>
                    </table>
                </TarjetaSeccion>
                <ModalBotones onCancelar={onCancelar} onGuardar={guardarHandler} guardando={cargando}
                              aceptarDisable={productosOrden.length === 0}/>
            </Modal>
        )
    }
}

export default StockCargarOrden