import React, {useCallback, useEffect} from "react";
import Boton from "./Boton";
import ToolbarBtn from "./ToolbarBtn";

type Props = {
    onCerrar: () => void,
}

const ModalBotonCerrar: React.FC<Props> = ({onCerrar}) => {
    const teclasEspeciales = useCallback(e => {
        if (e.keyCode === 27) {
            onCerrar()
        }
    }, [onCerrar])

    useEffect(() => {
        document.addEventListener('keydown', teclasEspeciales, false)
        return () => document.removeEventListener('keydown', teclasEspeciales, false)
    }, [teclasEspeciales])

    return (
        <ToolbarBtn derecha>
            <Boton toolbar className='btn-secondary' titulo='Cerrar' icono='fas fa-times'
                   onClick={() => {
                       onCerrar()
                   }}/>
        </ToolbarBtn>
    )
}

export default ModalBotonCerrar
