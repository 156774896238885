import React from "react";

type Props = {
    colSpan: number,
    texto: string,
    paddingStart?: boolean
}

const TrInfo: React.FC<Props> = ({colSpan, texto, paddingStart}) => {
    return <tr>
        <td colSpan={colSpan} className={paddingStart? 'ps-3' : ''}>
            <i className='fas fa-info-circle me-2 text-info'/>{texto}
        </td>
    </tr>
}

export default TrInfo