import React from "react";
import './TarjetaSeccion.css'

type Props = {
    children: React.ReactNode
}

const TarjetaSeccion: React.FC<Props> = ({children}) => {
    return (
        <div className='card sombra-ts mb-3'>
            <div className='card-body tarjeta-ts'>{children}</div>
        </div>
    )
}

export default TarjetaSeccion
