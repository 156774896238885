import EstadoMensaje from "../controles/EstadoMensaje";
import WarningMensaje from "../controles/WarningMensaje";
import {SancionLegajoItem} from "./interfacesLegajo";
import React from "react";

type SancionesLegajoProps = {
    sanciones: Array<SancionLegajoItem>
}

const SancionesLegajo: React.FC<SancionesLegajoProps> = ({sanciones}) => {
    if (!sanciones || sanciones.length === 0) {
        return <EstadoMensaje estado={true} mensajeOk='No registra sanciones en este año'/>
    } else {
        const mensajeSanciones: string =
            `${sanciones.length} ${sanciones.length === 1 ? 'sanción' : 'sanciones'} en este año`

        return (
            <>
                <WarningMensaje className='mb-2' mensaje={mensajeSanciones}/>
                <table className='table table-bordered table-sm table-striped small'>
                    <thead>
                    <tr>
                        <th>Fecha</th>
                        <th>Motivo</th>
                    </tr>
                    </thead>
                    <tbody>
                    {sanciones.map((s: SancionLegajoItem, i: number) => (
                        <tr key={i}>
                            <td>{s.fecha}</td>
                            <td>{s.motivo}</td>
                        </tr>
                    ))}
                    </tbody>
                </table>
            </>
        )
    }
}

export default SancionesLegajo
