import React from "react";
import {DatosVacaciones} from "./interfacesVacaciones";
import TrInfo from "../controles/TrInfo";

type Props = {
    vacaciones: Array<DatosVacaciones>,
    idFiltrado: number | undefined
}

const TablaVacaciones: React.FC<Props> = ({vacaciones, idFiltrado}) => {
    const vacs = vacaciones.filter(v => v.id_empleado === (idFiltrado === undefined ? v.id_empleado : idFiltrado))

    return (
        <table className='table table-responsive table-striped table-sm small border-top'>
            <thead>
            <tr>
                <th className='ps-3'>Empleado</th>
                <th>Estado</th>
                <th>Desde</th>
                <th>Hasta</th>
                <th>Días</th>
            </tr>
            </thead>
            <tbody>
            {vacs.length ? vacs.map((v, i) => (
                <tr key={i}>
                    <td className='ps-3'>{v.empleado}</td>
                    <td>
                        {v.actual ? (
                            <span className='text-primary'>
                                <i className='fas fa-plane me-1'/> En curso
                            </span>
                        ) : (
                            <span className='text-info'>
                                <i className='fas fa-calendar-alt me-1'/> Agendadas
                            </span>
                        )}
                    </td>
                    <td>{v.fecha_desde}</td>
                    <td>{v.fecha_hasta}</td>
                    <td className='text-end'>{v.dias}</td>
                </tr>
            )) : (
                <TrInfo colSpan={5} paddingStart
                        texto='No hay información de vacaciones planificadas para mostrar'/>
            )}
            </tbody>
        </table>
    )
}

export default TablaVacaciones