import React, {useEffect, useState} from "react";
import Modal from "../controles/Modal";
import IconoCarga from "../controles/IconoCarga";
import ModalBotones from "../controles/ModalBotones";
import {useGsBackend} from "../funcionesApi";

type DatosRegistro = {
    fechayhora: string,
    registro: string,
    tarjetas_no_encontradas: Array<string>
}

type Props = {
    onCerrar: () => void
}

const RegistroProcesoFichadas: React.FC<Props> = ({onCerrar}) => {
    const [datos, setDatos] = useState<DatosRegistro | undefined>(undefined)

    const {pedidoJson} = useGsBackend()
    useEffect(() => {
        pedidoJson({url: 'fichadas/control', ok: setDatos})
    }, [pedidoJson])

    return <Modal className='w-50'>
        <h4>Registro del Proceso de Fichadas</h4>
        {datos === undefined ? <IconoCarga espacio chico/> : <div>
            <div className='row mb-2'>
                <div className='col-sm-3 fw-bold'>Última ejecución</div>
                <div className='col-sm-9'>{datos.fechayhora}</div>
            </div>
            <div className='row mb-2'>
                <div className='col-sm-3 fw-bold'>Registro</div>
                <div className='col-sm-9'>{datos.registro}</div>
            </div>
            <div className='row mb-2'>
                <div className='col-sm-3 fw-bold'>Tarjetas no encontradas</div>
                <div className='col-sm-9'>
                    {datos.tarjetas_no_encontradas.length ?
                        <div className='row border'>
                            {datos.tarjetas_no_encontradas.map((t, i) =>
                                <div className='col-sm-3 border small text-center p-1'
                                     key={i}>{t}</div>
                            )}
                        </div> :
                        <>No hay tarjetas sin identificar</>}
                </div>
            </div>
        </div>}
        <ModalBotones soloCancelar onCancelar={onCerrar}/>
    </Modal>
}

export default RegistroProcesoFichadas