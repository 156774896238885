import React, {useEffect, useState} from "react";
import Modal from "../controles/Modal";
import ModalBotones from "../controles/ModalBotones";
import InputSelect, {OpcionSelect} from "../controles/InputSelect";
import InputFecha from "../controles/InputFecha";
import dias from "../dias";
import IconoCarga from "../controles/IconoCarga";
import {useGsBackend} from "../funcionesApi";

type Props = {
    idEmpleado: number,
    onActualizar: () => void,
    onCancelar: () => void
}

type Horarios = {
    dia: number,
    entrada: string,
    salida: string
}

const EdicionHorarios: React.FC<Props> = ({idEmpleado, onActualizar, onCancelar}) => {
    const [configuracion, setConfiguracion] = useState<number>(0)
    const [horarioFijo, setHorarioFijo] = useState<Horarios>({dia: -1, entrada: '', salida: ''} as Horarios)
    const [horariosDias, setHorariosDias] = useState<Array<Horarios>>(dias.map(d => {
        return {dia: d.numero, entrada: '', salida: ''} as Horarios
    }))
    const [invalidos, setInvalidos] = useState<Array<number>>([] as Array<number>)

    const configuraciones = [
        {id: 1, descripcion: 'Sin horario definido / Sin fichar'},
        {id: 2, descripcion: 'Horario fijo para los días hábiles'},
        {id: 3, descripcion: 'Horario personalizado por día'}
    ] as Array<OpcionSelect>

    const {pedidoJson, cargando} = useGsBackend()
    useEffect(() => {
        pedidoJson({
            url: `legajos/horariosteoricos?id_empleado=${idEmpleado}`,
            ok: res => {
                if (res.tipo_horario === undefined || res.horarios_teoricos === undefined) {
                    alert('Error de carga')
                } else {
                    setConfiguracion(res.tipo_horario)
                    const horarios: Array<Horarios> = res.horarios_teoricos
                    if (res.tipo_horario === 2) {
                        const fijo = horarios.filter(h => h.dia === -1)[0]
                        if (fijo !== undefined) setHorarioFijo(fijo)
                    } else if (res.tipo_horario === 3) {
                        const infoHr: Array<Horarios> = res.horarios_teoricos
                        const claves: Array<number> = infoHr.map(h => h.dia)
                        const previo: Array<number> = [0, 1, 2, 3, 4, 5, 6].filter(n => !claves.includes(n))
                        const nuevo: Array<Horarios> = previo.map(d => {
                            return {dia: d, entrada: '', salida: ''} as Horarios
                        }).concat(infoHr).sort((a, b) => a.dia - b.dia)
                        setHorariosDias(nuevo)
                    }
                }
            }
        })
    }, [idEmpleado, pedidoJson])

    const horariosDiasHandler = (dia: number, entrada: boolean, horario: string): void => {
        const nuevo: Horarios = {...horariosDias.filter(h => h.dia === dia)[0]}
        if (entrada) {
            nuevo.entrada = horario
        } else {
            nuevo.salida = horario
        }

        const salida: Array<Horarios> = [...horariosDias.filter(h => h.dia !== dia)]
        salida.push(nuevo)

        setHorariosDias(salida)
    }

    const guardarHandler = async (): Promise<void> => {
        // Validaciones
        const inv = [] as Array<number>
        if (configuracion === 2) {
            if (horarioFijo.salida === '' || horarioFijo.entrada === '') inv.push(-1)
        } else if (configuracion === 3) {
            // Verificar que no haya inconsistencias
            horariosDias.forEach(h => {
                if ((h.salida === '' && h.entrada !== '')
                    || (h.salida !== '' && h.entrada === '')) inv.push(h.dia)
            })

            // Verificar que no estén todos los horarios vacíos
            const prueba = horariosDias.filter(h => h.entrada !== '' && h.salida !== '')
            if (prueba.length === 0) horariosDias.forEach(h => inv.push(h.dia))
        }
        setInvalidos(inv)
        if (inv.length > 0) return

        const fd = new FormData()
        fd.append('accion', 'editar')
        fd.append('id_empleado', idEmpleado.toString())
        fd.append('tipo_horario', configuracion.toString())
        if (configuracion === 2) {
            fd.append('dia_-1_entrada', horarioFijo.entrada)
            fd.append('dia_-1_salida', horarioFijo.salida)
        } else if (configuracion === 3) {
            horariosDias.forEach(h => {
                fd.append(`dia_${h.dia}_entrada`, h.entrada)
                fd.append(`dia_${h.dia}_salida`, h.salida)
            })
        }

        pedidoJson({url: 'legajos/horariosteoricos', method: 'post', body: fd, ok: onActualizar})
    }

    return (
        <Modal className='w-50'>
            <h4>Editar horarios teóricos</h4>
            {cargando ? <IconoCarga/> : <>
                <InputSelect titulo='Configuración' value={configuracion} onChange={setConfiguracion}
                             opciones={configuraciones} className='mb-3'/>
                {configuracion === 2 && (
                    <div className='row'>
                        <div className='col-md-6 mb-3'>
                            <InputFecha hora value={horarioFijo.entrada} onChange={v => setHorarioFijo({
                                entrada: v, salida: horarioFijo.salida, dia: horarioFijo.dia
                            } as Horarios)} titulo='Horario de Entrada' invalido={invalidos.includes(-1)}/>
                        </div>
                        <div className='col-md-6 mb-3'>
                            <InputFecha hora value={horarioFijo.salida} onChange={v => setHorarioFijo({
                                entrada: horarioFijo.entrada, salida: v, dia: horarioFijo.dia
                            } as Horarios)} titulo='Horario de Salida' invalido={invalidos.includes(-1)}/>
                        </div>
                    </div>
                )}
                {configuracion === 3 && (
                    <table className='table table-responsive table-sm table-bordered table-striped'>
                        <thead>
                        <tr>
                            <th>Día</th>
                            <th>Horario de Entrada</th>
                            <th>Horario de Salida</th>
                        </tr>
                        </thead>
                        <tbody>
                        {dias.map((d, k) => (
                            <tr key={k}>
                                <td className='align-middle'>{d.desc_larga}</td>
                                <td>
                                    <InputFecha onChange={v => horariosDiasHandler(d.numero, true, v)}
                                                chico hora invalido={invalidos.includes(d.numero)}
                                                value={horariosDias.filter(h => h.dia === d.numero)[0].entrada}/>
                                </td>
                                <td>
                                    <InputFecha onChange={v => horariosDiasHandler(d.numero, false, v)}
                                                chico hora invalido={invalidos.includes(d.numero)}
                                                value={horariosDias.filter(h => h.dia === d.numero)[0].salida}/>
                                </td>
                            </tr>
                        ))}
                        </tbody>
                    </table>
                )}
                <ModalBotones soloEditar onGuardar={() => guardarHandler()} guardando={cargando}
                              onCancelar={() => onCancelar()}/>
            </>}
        </Modal>
    )
}

export default EdicionHorarios