import React, {useCallback, useEffect, useState} from "react";
import ToolbarBtn from "../controles/ToolbarBtn";
import Boton from "../controles/Boton";
import BodyCarga from "../controles/BodyCarga";
import Modal from "../controles/Modal";
import ModalBotones from "../controles/ModalBotones";
import TarjetaSeccion from "../controles/TarjetaSeccion";
import InputTexto from "../controles/InputTexto";
import InputSelect, {OpcionSelect} from "../controles/InputSelect";
import InputArchivo from "../controles/InputArchivo";
import InputMoneda from "../controles/InputMoneda";
import BotonesExportar from "../controles/BotonesExportar";
import TrInfo from "../controles/TrInfo";
import {useNavigate} from "react-router-dom";
import BarraSuperior from "../controles/BarraSuperior";
import CuerpoPrincipal from "../controles/CuerpoPrincipal";
import {useGsBackend} from "../funcionesApi";
import {funcionDescarga} from "../descargas/descargas";

type Sector = {
    id: number,
    descripcion: string,
    existencias: number
}

type Stock = {
    id: number,
    id_tipodeproducto: number,
    tipodeproducto: string,
    id_producto: number,
    producto: string,
    id_unidaddemedida: number,
    unidaddemedida: string,
    sectores: Array<Sector>,
    id_sectorpredeterminado: number
}

type InfoStock = {
    stock: Array<Stock>,
    proveedores: Array<OpcionSelect>,
    destinos: Array<OpcionSelect>,
    sectores: Array<OpcionSelect>,
    tiposdeproductos: Array<OpcionSelect>
}

type Validacion = {
    cantidad: boolean,
    sector: boolean,
    destino: boolean
}

type Funcion = 'agregar' | 'asignar' | undefined

const StockExistencias: React.FC = () => {
    const validacionOk: Validacion = {cantidad: true, sector: true, destino: true}
    const [funcion, setFuncion] = useState<Funcion>(undefined)
    const [productoFuncion, setProductoFuncion] = useState<Stock | undefined>(undefined)
    const [editCantidad, setEditCantidad] = useState<string>('0')
    const [editProveedor, setEditProveedor] = useState<number>(0)
    const [editCosto, setEditCosto] = useState<number>(0)
    const [editSector, setEditSector] = useState<number>(0)
    const [sectoresDisponibles, setSectoresDisponibles] = useState<Array<OpcionSelect>>([] as Array<OpcionSelect>)
    const [editDestino, setEditDestino] = useState<number>(0)
    const [editComentarios, setEditComentarios] = useState<string>('')
    const [editArchivo, setEditArchivo] = useState<File | undefined>(undefined)
    const [valid, setValid] = useState<Validacion>(validacionOk)
    const [filtroProducto, setFiltroProducto] = useState<string>('')
    const [filtroSector, setFiltroSector] = useState<number>(0)
    const [filtroTipoProducto, setFiltroTipoProducto] = useState<number>(0)
    const [datos, setDatos] = useState<InfoStock | undefined>(undefined)
    const [muestra, setMuestra] = useState<Array<Stock>>([] as Array<Stock>)
    const navigate = useNavigate()

    const {pedidoJson, pedidoBlob, errorCarga, actualizar, cargando} = useGsBackend()

    useEffect(() => {
        pedidoJson({url: 'stock', method: 'get', ok: setDatos})
    }, [pedidoJson])

    const textoSinStock: JSX.Element = <span className='text-muted'>(Sin stock)</span>

    const cerrarFuncionHandler = (): void => {
        setProductoFuncion(undefined)
        setFuncion(undefined)
    }

    const movimientoHandler = async (): Promise<void> => {
        if (funcion === undefined || datos === undefined || productoFuncion === undefined) return

        const infoDep = productoFuncion.sectores.filter(d => d.id === editSector)[0]
        const cantidadMaxima = infoDep === undefined ? 0 : infoDep.existencias
        const vld: Validacion = {
            cantidad: editCantidad !== '0' && (funcion === 'asignar' ?
                parseFloat(editCantidad) <= cantidadMaxima : true),
            sector: editSector !== 0,
            destino: funcion !== 'asignar' || editDestino !== 0
        }
        setValid(vld)
        if (!vld.cantidad || !vld.destino || !vld.sector) return

        const fd = new FormData()
        fd.append('accion', funcion)
        fd.append('id_producto', productoFuncion.id_producto.toString())
        fd.append('id_sector', editSector.toString())
        fd.append('id_proveedor', editProveedor.toString())
        fd.append('id_destino', editDestino.toString())
        fd.append('costo', editCosto.toString())
        fd.append('cantidad', editCantidad.toString())
        fd.append('comentarios', editComentarios)
        if (editArchivo !== undefined) fd.append('archivo', editArchivo, editArchivo.name)

        pedidoJson({
            url: 'stock/movimiento', method: 'post', body: fd,
            ok: () => {
                cerrarFuncionHandler()
                actualizar()
            }
        })
    }

    const funcionHandler = (arg: Funcion, producto?: Stock): void => {
        if (arg === undefined || producto === undefined) {
            setFuncion(undefined)
        } else {
            setProductoFuncion(producto)
            setEditCantidad('0')
            setEditProveedor(0)
            setEditCosto(0)
            if (arg === 'agregar') {
                setEditSector(producto.id_sectorpredeterminado)
            } else if (arg === 'asignar') {
                setEditSector(0)
                setSectoresDisponibles(producto.sectores.map(d => ({
                    id: d.id, descripcion: d.descripcion
                } as OpcionSelect)))
            }
            setEditDestino(0)
            setEditComentarios('')
            setEditArchivo(undefined)
            setValid(validacionOk)
            setFuncion(arg)
        }
    }

    const filtrar = useCallback((st: Array<Stock>): void => {
        if (filtroProducto !== '') st = st.filter(d => d.producto.toLocaleLowerCase()
            .includes(filtroProducto.toLocaleLowerCase()))
        if (filtroTipoProducto !== 0) st = st.filter(d => d.id_tipodeproducto === filtroTipoProducto)
        if (filtroSector !== 0) st = st.filter(d => d.sectores.filter(i => i.id === filtroSector).length)

        setMuestra(st)
    }, [filtroSector, filtroProducto, filtroTipoProducto])

    useEffect(() => {
        if (datos !== undefined) filtrar(datos.stock)
    }, [filtrar, datos])

    const modalFunciones = (): React.ReactNode => {
        if (productoFuncion === undefined || datos === undefined) return <></>

        switch (funcion) {
            case 'agregar':
                return (
                    <Modal className='w-50'>
                        <h4>Agregar existencias ({productoFuncion.producto})</h4>
                        <TarjetaSeccion>
                            <div className='row mb-2'>
                                <div className='col-md-3 pt-1'>
                                    Cantidad
                                </div>
                                <div className='col-md-9'>
                                    <InputTexto value={editCantidad} onChange={setEditCantidad}
                                                numerico izquierda titulo={productoFuncion.unidaddemedida}
                                                autoFocus invalido={!valid.cantidad}/>
                                </div>
                            </div>
                            <div className='row mb-2'>
                                <div className='col-md-3 pt-1'>
                                    Proveedor
                                </div>
                                <div className='col-md-9'>
                                    <InputSelect value={editProveedor} onChange={setEditProveedor}
                                                 opciones={datos.proveedores} forzarCero textocero='(Opcional)'/>
                                </div>
                            </div>
                            {editProveedor !== 0 && (
                                <div className='row mb-2'>
                                    <div className='col-md-3 pt-1'>
                                        Costo
                                    </div>
                                    <div className='col-md-9'>
                                        <InputMoneda value={editCosto} onChange={setEditCosto}/>
                                    </div>
                                </div>
                            )}
                            <div className='row mb-2'>
                                <div className='col-md-3 pt-1'>
                                    Sector
                                </div>
                                <div className='col-md-9'>
                                    <InputSelect value={editSector} onChange={setEditSector}
                                                 opciones={datos.sectores} invalido={!valid.sector}/>
                                </div>
                            </div>
                            <div className='row mb-2'>
                                <div className='col-md-3 pt-1'>
                                    Documento<br/>
                                    <small className='text-muted'>(Opcional)</small>
                                </div>
                                <div className='col-md-9'>
                                    <InputArchivo onChange={e => setEditArchivo(e)}/>
                                </div>
                            </div>
                            <div className='row'>
                                <div className='col-md-3 pt-1'>
                                    Comentarios
                                </div>
                                <div className='col-md-9'>
                                    <InputTexto value={editComentarios} onChange={setEditComentarios}/>
                                </div>
                            </div>
                        </TarjetaSeccion>
                        <ModalBotones soloEditar onCancelar={cerrarFuncionHandler} guardando={cargando}
                                      onGuardar={movimientoHandler}/>
                    </Modal>
                )
            case 'asignar':
                const infoDep = productoFuncion.sectores.filter(d => d.id === editSector)[0]
                const cantidadMaxima = infoDep === undefined ? 0 : infoDep.existencias

                return (
                    <Modal className='w-50'>
                        <h4>Asignar existencias ({productoFuncion.producto})</h4>
                        <TarjetaSeccion>
                            <div className='row mb-2'>
                                <div className='col-md-3 pt-1'>
                                    Cantidad
                                </div>
                                <div className='col-md-9'>
                                    <InputTexto value={editCantidad} onChange={setEditCantidad}
                                                numerico izquierda titulo={productoFuncion.unidaddemedida}
                                                autoFocus invalido={!valid.cantidad}
                                                textoPosterior={cantidadMaxima === 0 ? undefined :
                                                    `Max: ${cantidadMaxima}`}/>
                                </div>
                            </div>
                            <div className='row mb-2'>
                                <div className='col-md-3 pt-1'>
                                    Sector
                                </div>
                                <div className='col-md-9'>
                                    <InputSelect value={editSector} onChange={setEditSector}
                                                 opciones={sectoresDisponibles} invalido={!valid.sector}/>
                                </div>
                            </div>
                            <div className='row mb-2'>
                                <div className='col-md-3 pt-1'>
                                    Destino
                                </div>
                                <div className='col-md-9'>
                                    <InputSelect value={editDestino} onChange={setEditDestino}
                                                 opciones={datos.destinos} invalido={!valid.destino}/>
                                </div>
                            </div>
                            <div className='row mb-2'>
                                <div className='col-md-3 pt-1'>
                                    Documento<br/>
                                    <small className='text-muted'>(Opcional)</small>
                                </div>
                                <div className='col-md-9'>
                                    <InputArchivo onChange={e => setEditArchivo(e)}/>
                                </div>
                            </div>
                            <div className='row'>
                                <div className='col-md-3 pt-1'>
                                    Comentarios
                                </div>
                                <div className='col-md-9'>
                                    <InputTexto value={editComentarios} onChange={setEditComentarios}/>
                                </div>
                            </div>
                        </TarjetaSeccion>
                        <ModalBotones soloEditar onCancelar={cerrarFuncionHandler} guardando={cargando}
                                      onGuardar={movimientoHandler}/>
                    </Modal>
                )
            default:
                return <></>
        }
    }

    const excelExistenciasStock = (): void => {
        pedidoBlob({
            url: 'excel/stockexistencias',
            ok: res => funcionDescarga(res, 'stockexistencias.xlsx')
        })
    }

    return (
        <div>
            <BarraSuperior>
                <ToolbarBtn>
                    <Boton toolbar titulo='Proveedores' icono='fas fa-truck'
                           onClick={() => navigate('proveedores')}/>
                    <Boton toolbar titulo='Sectores' icono='fas fa-warehouse'
                           onClick={() => navigate('sectores')}/>
                    <Boton toolbar titulo='Destinos' icono='fas fa-square-check'
                           onClick={() => navigate('destinos')}/>
                    <Boton toolbar titulo='Configuración de Productos' icono='fas fa-boxes-packing'
                           onClick={() => navigate('productos')}/>
                    {datos?.stock.length ? <BotonesExportar onExcel={excelExistenciasStock}/> : <></>}
                </ToolbarBtn>
            </BarraSuperior>
            {datos === undefined ? (
                <BodyCarga error={errorCarga} onReintentar={actualizar}/>
            ) : (
                <>
                    <CuerpoPrincipal sinEspacio>
                        <div className='row g-3'>
                            <div className='col-auto'>
                                <i className='fas fa-search text-primary mt-2'/>
                            </div>
                            <div className='col'>
                                <div className='row g-2 mb-2'>
                                    <div className='col-md-4'>
                                        <InputTexto titulo='Producto' chico
                                                    value={filtroProducto} onChange={setFiltroProducto}
                                                    placeholder='(Todos)'/>
                                    </div>
                                    <div className='col-md-4'>
                                        <InputSelect titulo='Tipo de Producto' chico
                                                     textocero='(Todas)' opciones={datos.tiposdeproductos}
                                                     value={filtroTipoProducto} onChange={setFiltroTipoProducto}
                                                     forzarCero/>
                                    </div>
                                    <div className='col-md-4'>
                                        <InputSelect titulo='Sector' chico opciones={datos.sectores}
                                                     value={filtroSector} onChange={setFiltroSector}
                                                     forzarCero/>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </CuerpoPrincipal>
                    <table className='table table-responsive table-striped'>
                        <thead>
                        <tr>
                            <th style={{width: '33%'}} className='ps-3'>Producto</th>
                            <th style={{width: '33%'}}>Sector</th>
                            <th style={{width: '33%'}}>Stock</th>
                            <th>Acciones</th>
                        </tr>
                        </thead>
                        <tbody>
                        {muestra.length ? muestra.map((s, k) => (
                            <tr key={k} className='small'>
                                <td className='ps-3'>
                                    <span className='fw-bold'>{s.producto}</span><br/>
                                    <span className='small'>{s.tipodeproducto}</span>
                                </td>
                                <td>
                                    {s.sectores.length ? s.sectores.map((d, k) => (
                                        <div key={k}>{d.descripcion}</div>
                                    )) : textoSinStock}
                                </td>
                                <td>
                                    {s.sectores.length ? s.sectores.map((d, k) => (
                                        <div key={k}>{d.existencias} {s.unidaddemedida}</div>
                                    )) : textoSinStock}
                                </td>
                                <td className='text-nowrap'>
                                    <Boton chico toolbar titulo='Agregar' icono='fas fa-plus'
                                           className='btn-info' onClick={() => {
                                        funcionHandler('agregar', s)
                                    }}/>
                                    {s.sectores.length > 0 ? (
                                        <Boton chico toolbar titulo='Asignar' icono='fas fa-arrow-turn-up'
                                               className='btn-success' onClick={() => {
                                            setProductoFuncion(s)
                                            funcionHandler('asignar', s)
                                        }}/>
                                    ) : (
                                        <Boton chico toolbar titulo='Asignar' icono='fas fa-arrow-turn-up'
                                               className='btn-light' disabled/>
                                    )}
                                    <Boton chico toolbar titulo='Movimientos' icono='fas fa-clipboard-list'
                                           onClick={() => navigate(`${s.id_producto}/movimientos`)}/>
                                </td>
                            </tr>
                        )) : (
                            <TrInfo colSpan={4} texto='Ningún elemento coincide con el criterio de busqueda'/>
                        )}
                        </tbody>
                    </table>
                </>
            )}
            {modalFunciones()}
        </div>
    )

}

export default StockExistencias