import React, {useCallback, useEffect, useState} from "react";
import Modal from "../controles/Modal";
import InputTexto from "../controles/InputTexto";
import IconoCarga from "../controles/IconoCarga";
import ModalBotones from "../controles/ModalBotones";
import ModalConfirmarCancelar from "../controles/ModalConfirmarCancelar";
import {useGsBackend} from "../funcionesApi";

type Previsualizacion = {
    nombreyapellido: string,
    cuil: string,
    antiguedad: string,
    dias_actual: number,
    dias_agregados: number,
    dias_resultado: number
}

type Props = {
    onCerrar: () => void
}

const AsignacionVacaciones: React.FC<Props> = ({onCerrar}) => {
    const [filtroNombre, setFiltroNombre] = useState<string>('')
    const [filtroCuil, setFiltroCuil] = useState<string>('')
    const [datos, setDatos] = useState<Array<Previsualizacion> | undefined>(undefined)
    const [muestra, setMuestra] = useState<Array<Previsualizacion>>([] as Array<Previsualizacion>)
    const [confirmar, setConfirmar] = useState<boolean>(false)

    const {pedidoJson, cargando} = useGsBackend()
    useEffect(() => {
        pedidoJson({url: 'vacaciones/asignacion', ok: res => setDatos(res.previsualizacion)})
    }, [pedidoJson])

    const guardarHandler = async (): Promise<void> => {
        const fd = new FormData()
        fd.append('accion', 'asignar')

        pedidoJson({url: 'vacaciones/asignacion', method: 'post', body: fd, ok: onCerrar})
    }

    const filtrar = useCallback((entrada: Array<Previsualizacion>): void => {
        let res = entrada

        if (filtroNombre !== '') res = res.filter(e =>
            e.nombreyapellido.toLowerCase().includes(filtroNombre.toLowerCase()))

        if (filtroCuil !== '') res = res.filter(e =>
            e.cuil.toLowerCase().includes(filtroCuil.toLowerCase()))

        setMuestra(res.slice(0, 10))
    }, [filtroCuil, filtroNombre])

    useEffect(() => {
        if (datos !== undefined) filtrar(datos)
    }, [filtrar, datos])

    if (confirmar) {
        // Ventana de confirmación
        return <ModalConfirmarCancelar titulo='Asignación masiva de días'
                                       descripcion={
                                           'Usted está a punto de realizar la asignación masiva de días de ' +
                                           'vacaciones para todos los empleados. Se agregarán los días de ' +
                                           'vacaciones configurados en la tabla como días de vacaciones ' +
                                           'disponibles para toda la nómina de empleados.'
                                       }
                                       onConfirmar={() => guardarHandler()}
                                       onCancelar={() => setConfirmar(false)}/>
    } else {
        // Ventana normal
        return (
            <Modal className='w-75'>
                <h4>Asignación masiva de días</h4>
                {datos === undefined ? <IconoCarga/> : <>
                    <p className='mb-2'>
                        <strong>Previsualización</strong> (Se muestran sólo 10 registros en la tabla;
                        para ver otros casos utilice los filtros)
                    </p>
                    <table className='table table-responsive table-striped table-bordered table-sm small'>
                        <thead>
                        <tr>
                            <th>
                                <InputTexto value={filtroNombre} onChange={setFiltroNombre} titulo='Apellido y Nombre'
                                            chico/>
                            </th>
                            <th><InputTexto value={filtroCuil} onChange={setFiltroCuil} titulo='CUIL' chico/></th>
                            <th>Antiguedad</th>
                            <th className='text-nowrap'>Días Actual</th>
                            <th className='text-nowrap'>Días Agregados</th>
                            <th className='text-nowrap'>Días Resultado</th>
                        </tr>
                        </thead>
                        <tbody>
                        {muestra.map((d, i) => (
                            <tr key={i}>
                                <td>{d.nombreyapellido}</td>
                                <td>{d.cuil}</td>
                                <td>{d.antiguedad}</td>
                                <td className='text-end'>{d.dias_actual}</td>
                                {d.dias_agregados > 0 ?
                                    <td className='text-end text-success'>+{d.dias_agregados}</td> :
                                    <td className='text-end'>{d.dias_agregados}</td>}
                                <td className='text-end'>{d.dias_resultado}</td>
                            </tr>
                        ))}
                        </tbody>
                    </table>
                    <div className='row'>
                        <div className='col-auto pe-0'>
                            <i className='fas fa-exclamation-triangle text-warning'/>
                        </div>
                        <div className='col'>
                            La asignación se realizará para <em className='text-decoration-underline'>todos los
                            empleados</em> independientemente de los datos mostrados en pantalla. <br/>
                            <strong>
                                Verifique los resultados antes de realizar la asignación
                                ya que esta acción no se puede deshacer.
                            </strong>
                        </div>
                    </div>
                    <ModalBotones soloEditar guardando={cargando}
                                  onGuardar={() => setConfirmar(true)} onCancelar={onCerrar}/>
                </>}
            </Modal>
        )
    }
}

export default AsignacionVacaciones
