import React, {useEffect, useState} from "react";
import ToolbarBtn from "../controles/ToolbarBtn";
import Boton from "../controles/Boton";
import TarjetaSeccion from "../controles/TarjetaSeccion";
import InputArchivo from "../controles/InputArchivo";
import InputSelect, {OpcionSelect} from "../controles/InputSelect";
import InputTexto from "../controles/InputTexto";
import InputFecha from "../controles/InputFecha";
import BodyCarga from "../controles/BodyCarga";
import Modal from "../controles/Modal";
import ModalBotones from "../controles/ModalBotones";
import IconoCarga from "../controles/IconoCarga";
import EdicionHorarios from "./EdicionHorarios";
import dias from "../dias";
import {useNavigate, useParams} from "react-router-dom";
import CuerpoPrincipal from "../controles/CuerpoPrincipal";
import BarraSuperior from "../controles/BarraSuperior";
import {useGsBackend} from "../funcionesApi";
import BotonCarga from "../controles/BotonCarga";

type Hijo = {
    id: number,
    nombre_y_apellido: string,
    dni: string,
    fecha_nacimiento: string,
    fecha_nacimiento_fmt: string
}

type ValidacionHijo = {
    nombre_ok: boolean,
    dni_ok: boolean,
    fecha_ok: boolean
}

type ValidacionLegajo = {
    apellido_ok: boolean,
    nombre_ok: boolean,
    cuil_ok: boolean,
    fecha_nacimiento_ok: boolean,
    ubicacion_ok: boolean,
    categoria_ok: boolean,
    puesto_ok: boolean,
    fecha_inicio_ok: boolean,
    nro_legajo_ok: boolean
}

type HorarioTeorico = {
    dia: number,
    entrada: string,
    salida: string
}

type ListasValores = {
    estado_civil: Array<OpcionSelect>,
    ubicaciones: Array<OpcionSelect>,
    nacionalidades: Array<OpcionSelect>,
    categorias: Array<OpcionSelect>,
    bancos: Array<OpcionSelect>,
    convenios: Array<OpcionSelect>,
    modalidades: Array<OpcionSelect>,
    puestos: Array<OpcionSelect>,
    paises: Array<OpcionSelect>,
    provincias: Array<OpcionSelect>,
    municipios: Array<Municipio>
}

type DatosEdicion = {
    nro_legajo: string,
    nombre: string,
    apellido: string,
    cuil: string,
    fecha_nacimiento: string,
    id_nacionalidad: number,
    id_estadocivil: number,
    telefono: string,
    id_ubicacion: number,
    domicilio: string,
    cp: string,
    id_pais: number,
    id_provincia: number,
    id_municipio: number,
    id_puesto: number,
    id_categoria: number,
    cuenta_haberes: string,
    id_banco: number,
    fecha_inicio: string,
    fecha_baja: string,
    id_modalidad: number,
    id_convenio: number,
    dias_vacaciones: number,
    activo: boolean,
    nro_tarjeta: string,
    foto: string,
    motivo_baja: string,
    hijos: Array<Hijo>,
    tipo_horario: number,
    horarios_teoricos: Array<HorarioTeorico>
}

type Municipio = {
    id: number,
    id_provincia: number,
    descripcion: string
}

type InfoServer = {
    datos_legajo: DatosEdicion,
    estado_civil: Array<OpcionSelect>,
    ubicaciones: Array<OpcionSelect>,
    nacionalidades: Array<OpcionSelect>,
    categorias: Array<OpcionSelect>,
    bancos: Array<OpcionSelect>,
    convenios: Array<OpcionSelect>,
    modalidades: Array<OpcionSelect>,
    puestos: Array<OpcionSelect>,
    paises: Array<OpcionSelect>,
    provincias: Array<OpcionSelect>,
    municipios: Array<Municipio>
}

const EdicionLegajo: React.FC = () => {
    const [cargado, setCargado] = useState<boolean>(false)
    const [apellido, setApellido] = useState<string>('')
    const [nombre, setNombre] = useState<string>('')
    const [cuil, setCuil] = useState<string>('')
    const [fechaNacimiento, setFechaNacimiento] = useState<string>('')
    const [estadoCivil, setEstadoCivil] = useState<number>(0)
    const [nacionalidad, setNacionalidad] = useState<number>(0)
    const [foto, setFoto] = useState<File | undefined>(undefined)
    const [tieneFoto, setTieneFoto] = useState<boolean>(false)
    const [activo, setActivo] = useState<number>(1)
    const [nroLegajo, setNroLegajo] = useState<string>('')
    const [nroTarjeta, setNroTarjeta] = useState<string>('')
    const [ubicacion, setUbicacion] = useState<number>(0)
    const [puesto, setPuesto] = useState<number>(0)
    const [categoria, setCategoria] = useState<number>(0)
    const [fechaIngreso, setFechaIngreso] = useState<string>('')
    const [fechaBaja, setFechaBaja] = useState<string>('')
    const [banco, setBanco] = useState<number>(0)
    const [cuenta, setCuenta] = useState<string>('')
    const [modalidad, setModalidad] = useState<number>(0)
    const [convenio, setConvenio] = useState<number>(0)
    const [diasVacaciones, setDiasVacaciones] = useState(0)
    const [domicilio, setDomicilio] = useState<string>('')
    const [pais, setPais] = useState<number>(0)
    const [idArgentina, setIdArgentina] = useState<number>(-1)
    const [provincia, setProvincia] = useState<number>(0)
    const [municipio, setMunicipio] = useState<number>(0)
    const [telefono, setTelefono] = useState<string>('')
    const [motivoBaja, setMotivoBaja] = useState<string>('')
    const [hijos, setHijos] = useState<Array<Hijo>>([] as Array<Hijo>)
    const [editarHijo, setEditarHijo] = useState<number | undefined>(undefined)
    const [hijoNombre, setHijoNombre] = useState<string>('')
    const [hijoDni, setHijoDni] = useState<string>('')
    const [hijoFechaNacimiento, setHijoFechaNacimiento] = useState<string>('')
    const [fotoEdicion, setFotoEdicion] = useState<string>('')
    const [editarHorarios, setEditarHorarios] = useState<boolean>(false)
    const [tipoHorario, setTipoHorario] = useState<number>(0)
    const [horariosTeoricos, setHorariosTeoricos] = useState<Array<HorarioTeorico>>([] as Array<HorarioTeorico>)

    const [listas, setListas] = useState<ListasValores>({} as ListasValores)

    const [validacionHijos, setValidacionHijos] = useState<ValidacionHijo>({
        nombre_ok: true,
        dni_ok: true,
        fecha_ok: true
    } as ValidacionHijo)
    const [validacion, setValidacion] = useState<ValidacionLegajo>({
        apellido_ok: true,
        nombre_ok: true,
        cuil_ok: true,
        fecha_nacimiento_ok: true,
        ubicacion_ok: true,
        categoria_ok: true,
        puesto_ok: true,
        fecha_inicio_ok: true,
        nro_legajo_ok: true
    } as ValidacionLegajo)

    const params = useParams()
    const numIdEmpleado = parseInt(params.idEmpleado || '0')
    const navigate = useNavigate()

    const {pedidoJson, pedidoBlob, errorCarga, actualizar, cargando} = useGsBackend()
    useEffect(() => {
        pedidoJson({
            url: `legajos/edicion/${numIdEmpleado}`, sinErrorDispatch: true,
            ok: (res: InfoServer) => {
                // Si no habia un Id valido volver a la lista de empleados
                if (res.datos_legajo === undefined) navigate('../../')

                // Carga de listas de valores
                setListas({
                    nacionalidades: res.nacionalidades,
                    estado_civil: res.estado_civil,
                    ubicaciones: res.ubicaciones,
                    puestos: res.puestos,
                    categorias: res.categorias,
                    bancos: res.bancos,
                    paises: res.paises,
                    provincias: res.provincias,
                    municipios: res.municipios,
                    modalidades: res.modalidades,
                    convenios: res.convenios
                } as ListasValores)
                const arg = res.paises.filter(p => p.descripcion === 'Argentina')
                if (arg.length) setIdArgentina(arg[0].id)

                // Carga de datos para edición
                const d: DatosEdicion = res.datos_legajo
                setApellido(d.apellido)
                setNombre(d.nombre)
                setNroLegajo(d.nro_legajo)
                setCuil(d.cuil)
                setFechaNacimiento(d.fecha_nacimiento)
                setEstadoCivil(d.id_estadocivil)
                setNacionalidad(d.id_nacionalidad)
                setActivo(d.activo ? 1 : 2)
                setUbicacion(d.id_ubicacion)
                setPuesto(d.id_puesto)
                setCategoria(d.id_categoria)
                setNroTarjeta(d.nro_tarjeta)
                setFechaIngreso(d.fecha_inicio)
                setFechaBaja(d.fecha_baja)
                setBanco(d.id_banco)
                setCuenta(d.cuenta_haberes)
                setModalidad(d.id_modalidad)
                setConvenio(d.id_convenio)
                setDiasVacaciones(d.dias_vacaciones)
                setDomicilio(d.domicilio)
                setPais(d.id_pais)
                setProvincia(d.id_provincia)
                setMunicipio(d.id_municipio)
                setTelefono(d.telefono)
                setMotivoBaja(d.motivo_baja)
                setTieneFoto(d.foto !== undefined && d.foto !== '')
                setHijos(d.hijos)
                setTipoHorario(d.tipo_horario)
                setHorariosTeoricos(d.horarios_teoricos)

                // Cargar foto para mostrar en el perfil
                if (d.foto !== undefined && d.foto !== '') {
                    pedidoBlob({
                        url: `archivos?tipo=fotos_empleados&id_empleado=${numIdEmpleado}`,
                        ok: res => setFotoEdicion(URL.createObjectURL(res))
                    })
                }

                // Proceso terminado, mostrar panel
                setCargado(true)
            }
        })
    }, [navigate, numIdEmpleado, pedidoBlob, pedidoJson])

    const guardarEdicionHijos = async (eliminar: boolean = false): Promise<void> => {
        if (editarHijo === undefined) return

        const nombreOk = hijoNombre !== ''
        const dniOk = hijoDni !== ''
        const fechaNacimientoOk = hijoFechaNacimiento !== ''
        setValidacionHijos({nombre_ok: nombreOk, dni_ok: dniOk, fecha_ok: fechaNacimientoOk} as ValidacionHijo)
        if (!nombreOk || !dniOk || !fechaNacimientoOk) return

        const fd = new FormData()
        fd.append('accion', eliminar ? 'eliminar' : (editarHijo === 0 ? 'nuevo' : 'editar'))
        fd.append('id', editarHijo.toString())
        fd.append('nombreyapellido', hijoNombre)
        fd.append('dni', hijoDni)
        fd.append('fecha_nacimiento', hijoFechaNacimiento)

        pedidoJson({
            url: `legajos/hijos/${numIdEmpleado}`, method: 'post', body: fd,
            ok: () => {
                editarHijoHandler(undefined)
                pedidoJson({
                    url: `legajos/hijos/${numIdEmpleado}`,
                    ok: (h: Array<Hijo>) => setHijos(h)
                })
            }
        })
    }

    const opcionesActivo = [
        {id: 1, descripcion: 'Activo'},
        {id: 2, descripcion: 'Inactivo'}
    ] as Array<OpcionSelect>

    const activoHandler = (arg: number): void => {
        if (arg === 1) {
            setFechaBaja('')
            setMotivoBaja('')
        }
        setActivo(arg)
    }

    const paisHandler = (arg: number): void => {
        setMunicipio(0)
        setProvincia(0)
        setPais(arg)
    }

    const provinciaHandler = (arg: number): void => {
        setMunicipio(0)
        setProvincia(arg)
    }

    const editarHijoHandler = (arg: number | undefined): void => {
        setValidacionHijos({nombre_ok: true, dni_ok: true, fecha_ok: true} as ValidacionHijo)
        if (arg === undefined) {
            setEditarHijo(undefined)
        } else if (arg === 0) {
            setHijoNombre('')
            setHijoDni('')
            setHijoFechaNacimiento('')
            setEditarHijo(0)
        } else {
            const datos = hijos.filter(h => h.id === arg)[0]
            setHijoNombre(datos.nombre_y_apellido)
            setHijoDni(datos.dni)
            setHijoFechaNacimiento(datos.fecha_nacimiento)
            setEditarHijo(arg)
        }
    }

    const vacacionesHandler = (arg: string): void => {
        const dias = arg.replace(/\D/g, '')
        setDiasVacaciones(dias === '' ? 0 : parseInt(dias))
    }

    const guardarHandler = async (): Promise<void> => {
        // Validaciones
        const apellidoOk = apellido !== ''
        const nombreOk = nombre !== ''
        const cuilOk = cuil !== ''
        const fechaNacimientoOk = fechaNacimiento !== ''
        const ubicacionOk = ubicacion !== 0
        const categoriaOk = categoria !== 0
        const puestoOk = puesto !== 0
        const fechaInicioOk = fechaIngreso !== ''
        const nroLegajoOk = nroLegajo !== ''
        setValidacion({
            apellido_ok: apellidoOk,
            nombre_ok: nombreOk,
            cuil_ok: cuilOk,
            fecha_nacimiento_ok: fechaNacimientoOk,
            ubicacion_ok: ubicacionOk,
            categoria_ok: categoriaOk,
            puesto_ok: puestoOk,
            fecha_inicio_ok: fechaInicioOk,
            nro_legajo_ok: nroLegajoOk
        } as ValidacionLegajo)
        if (!apellidoOk || !nombreOk || !cuilOk || !fechaNacimientoOk || !ubicacionOk || !categoriaOk ||
            !puestoOk || !fechaInicioOk || !nroLegajoOk) return

        // Validacion OK, procedo a guardar
        const fd = new FormData()
        fd.append('accion', 'editar')
        fd.append('nro_legajo', nroLegajo)
        fd.append('nombre', nombre)
        fd.append('apellido', apellido)
        fd.append('cuil', cuil)
        fd.append('fecha_nacimiento', fechaNacimiento)
        fd.append('id_nacionalidad', nacionalidad.toString())
        fd.append('id_estadocivil', estadoCivil.toString())
        fd.append('telefono', telefono)
        fd.append('id_ubicacion', ubicacion.toString())
        fd.append('domicilio', domicilio)
        fd.append('cp', '')
        fd.append('id_pais', pais.toString())
        fd.append('id_provincia', provincia.toString())
        fd.append('id_municipio', municipio.toString())
        fd.append('id_puesto', puesto.toString())
        fd.append('id_categoria', categoria.toString())
        fd.append('cuenta_haberes', cuenta)
        fd.append('id_banco', banco.toString())
        fd.append('fecha_inicio', fechaIngreso)
        fd.append('fecha_baja', fechaBaja)
        fd.append('id_modalidad', '0')
        fd.append('id_convenio', '0')
        fd.append('dias_vacaciones', diasVacaciones.toString())
        fd.append('activo', activo === 1 ? '1' : '0')
        fd.append('nro_tarjeta', nroTarjeta)
        fd.append('motivo_baja', motivoBaja)
        if (foto !== undefined) fd.append('foto', foto, foto.name)

        // Enviar datos al servidor, en caso de resultado OK volver a la página principal del legajo
        pedidoJson({
            url: `legajos/edicion/${numIdEmpleado}`, method: 'post', body: fd,
            ok: () => navigate('../')
        })
    }

    const muestraHorario = (): React.ReactNode => {
        switch (tipoHorario) {
            case 0:
                return (
                    <div className='mb-3'>
                        <i className='fas fa-info-circle me-2 text-warning'/>
                        Horario del Empleado sin configurar
                    </div>
                )
            case 1:
                return (
                    <div className='mb-3'>
                        <i className='fas fa-info-circle me-2 text-info'/>
                        Sin horario definido / Sin fichar
                    </div>
                )
            case 2:
                const infoHorario = horariosTeoricos.filter(h => h.dia === -1)[0]
                if (infoHorario === undefined) {
                    return <>Error</>
                } else {
                    return (
                        <div className='mb-3'>
                            <i className='fas fa-clock me-2 text-success'/>
                            Días hábiles de {infoHorario.entrada} a {infoHorario.salida}
                        </div>
                    )
                }
            case 3:
                const infoHorarios = horariosTeoricos.filter(h => h.dia > -1 && h.dia < 7)
                return <>
                    <div className='mb-3'>
                        <i className='fas fa-clock me-2 text-success'/>
                        Horarios personalizados según el día
                    </div>
                    <table className='table table-bordered table-sm table-striped table-responsive small mb-2'>
                        <thead>
                        <tr>
                            <th>Día</th>
                            <th className='text-center'>Entrada</th>
                            <th className='text-center'>Salida</th>
                        </tr>
                        </thead>
                        <tbody>
                        {infoHorarios.map((h, k) => (
                            <tr key={k}>
                                <td>{dias.filter(d => d.numero === h.dia)[0].desc_larga}</td>
                                <td className='text-center'>{h.entrada}</td>
                                <td className='text-center'>{h.salida}</td>
                            </tr>
                        ))}
                        </tbody>
                    </table>
                </>
        }
    }

    const actualizarHorarios = async () => {
        setEditarHorarios(false)

        // Cargar nuevamente los horarios del empleado y actualizar la visualización
        pedidoJson({
            url: `legajos/horariosteoricos?id_empleado=${numIdEmpleado}`,
            ok: res => {
                if (res.tipo_horario === undefined || res.horarios_teoricos === undefined) {
                    alert('Error de carga')
                } else {
                    setTipoHorario(res.tipo_horario)
                    setHorariosTeoricos(res.horarios_teoricos)
                }
            }
        })
    }

    const cuerpoEdicion = (
        <CuerpoPrincipal>
            <div className='row'>
                <div className='col-md-8'>
                    <h5 className='ms-2'>Datos Personales</h5>
                    <TarjetaSeccion>
                        <div className='row'>
                            <div className='col-md-4'>
                                <InputTexto titulado titulo='Apellido' value={apellido} onChange={setApellido}
                                            autoFocus obligatorio invalido={!validacion.apellido_ok}/>
                            </div>
                            <div className='col-md-4'>
                                <InputTexto titulado titulo='Nombre' value={nombre} onChange={setNombre}
                                            obligatorio invalido={!validacion.nombre_ok}/>
                            </div>
                            <div className='col-md-4'>
                                <InputTexto titulado titulo='CUIL' value={cuil} onChange={setCuil}
                                            obligatorio invalido={!validacion.cuil_ok}/>
                            </div>
                        </div>
                        <div className='row'>
                            <div className='col-md-4'>
                                <InputFecha titulado titulo='Fecha de Nacimiento' value={fechaNacimiento}
                                            onChange={setFechaNacimiento} obligatorio
                                            invalido={!validacion.fecha_nacimiento_ok}/>
                            </div>
                            <div className='col-md-4'>
                                <InputSelect titulado titulo='Estado Civil' value={estadoCivil}
                                             onChange={setEstadoCivil} opciones={listas.estado_civil}/>
                            </div>
                            <div className='col-md-4'>
                                <InputSelect titulado titulo='Nacionalidad' value={nacionalidad}
                                             onChange={setNacionalidad} opciones={listas.nacionalidades}/>
                            </div>
                        </div>
                    </TarjetaSeccion>
                    <h5 className='ms-2'>Datos Laborales</h5>
                    <TarjetaSeccion>
                        <div className='row'>
                            <div className='col-md-4'>
                                <InputSelect titulado titulo='Ubicación' value={ubicacion} obligatorio
                                             onChange={setUbicacion} opciones={listas.ubicaciones}
                                             invalido={!validacion.ubicacion_ok}/>
                            </div>
                            <div className='col-md-4'>
                                <InputSelect titulado titulo='Puesto' value={puesto}
                                             onChange={setPuesto} opciones={listas.puestos}
                                             invalido={!validacion.puesto_ok}/>
                            </div>
                            <div className='col-md-4'>
                                <InputSelect titulado titulo='Categoría' value={categoria}
                                             onChange={setCategoria} opciones={listas.categorias}
                                             invalido={!validacion.categoria_ok}/>
                            </div>
                        </div>
                        <div className='row'>
                            <div className='col-md-4'>
                                <InputTexto titulado titulo='Número de Tarjeta' value={nroTarjeta}
                                            onChange={setNroTarjeta}/>
                            </div>
                            <div className='col-md-4'>
                                <InputFecha titulado titulo='Fecha de Inicio' value={fechaIngreso}
                                            onChange={setFechaIngreso} obligatorio
                                            invalido={!validacion.fecha_inicio_ok}/>
                            </div>
                            <div className='col-md-4'>
                                <InputFecha titulado titulo='Fecha de Baja' value={fechaBaja}
                                            onChange={setFechaBaja} disabled={activo !== 2}/>
                            </div>
                        </div>
                        {activo === 2 && (
                            <div className='row'>
                                <InputTexto titulado titulo='Motivo de la Baja' value={motivoBaja}
                                            onChange={setMotivoBaja}/>
                            </div>
                        )}
                        <div className='row'>
                            <div className='col-md-4'>
                                <InputSelect titulado titulo='Banco' value={banco} onChange={setBanco}
                                             opciones={listas.bancos}/>
                            </div>
                            <div className='col-md-8'>
                                <InputTexto titulado titulo='Cuenta de Haberes' value={cuenta}
                                            onChange={setCuenta}/>
                            </div>
                        </div>
                        <div className='row'>
                            <div className='col-md-4'>
                                <InputSelect titulado titulo='Modalidad' value={modalidad}
                                             onChange={setModalidad} opciones={listas.modalidades}/>
                            </div>
                            <div className='col-md-4'>
                                <InputSelect titulado titulo='Convenio' value={convenio}
                                             onChange={setConvenio} opciones={listas.convenios}/>
                            </div>
                            <div className='col-md-4'>
                                <InputTexto titulado titulo='Días de Vacaciones' value={diasVacaciones.toString()}
                                            onChange={vacacionesHandler}/>
                            </div>
                        </div>
                    </TarjetaSeccion>
                    <h5 className='ms-2'>Datos de Contacto</h5>
                    <TarjetaSeccion>
                        <div className='row'>
                            <div className='col-md-8'>
                                <InputTexto titulado titulo='Domicilio' value={domicilio}
                                            onChange={setDomicilio}/>
                            </div>
                            <div className='col-md-4'>
                                <InputSelect titulado titulo='Municipio' value={municipio}
                                             onChange={setMunicipio}
                                             opciones={pais !== idArgentina ? undefined : (
                                                 listas.municipios.filter(m => m.id_provincia === provincia)
                                             )}/>
                            </div>
                        </div>
                        <div className='row'>
                            <div className={'col-md-' + (pais === idArgentina ? '4' : '8')}>
                                <InputTexto titulado titulo='Teléfono' value={telefono} onChange={setTelefono}/>
                            </div>
                            {pais === idArgentina && (
                                <div className='col-md-4'>
                                    <InputSelect titulado titulo='Provincia' value={provincia}
                                                 onChange={provinciaHandler} opciones={listas.provincias}/>
                                </div>
                            )}
                            <div className='col-md-4'>
                                <InputSelect titulado titulo='País' value={pais} onChange={paisHandler}
                                             opciones={listas.paises}/>
                            </div>
                        </div>
                    </TarjetaSeccion>
                </div>
                <div className='col-md-4'>
                    <h5 className='ms-2'>Legajo</h5>
                    <TarjetaSeccion>
                        <InputTexto titulado titulo='Número de Legajo' value={nroLegajo}
                                    onChange={setNroLegajo} obligatorio
                                    invalido={!validacion.nro_legajo_ok}/>
                        <InputSelect value={activo} onChange={activoHandler} opciones={opcionesActivo}
                                     titulo='Estado' sincero chico/>
                    </TarjetaSeccion>
                    <h5 className='ms-2'>Foto</h5>
                    <TarjetaSeccion>
                        {tieneFoto ? (
                            <div className='row mb-3'>
                                <div className='col-6'>
                                    {fotoEdicion !== '' ? <img src={fotoEdicion} className='w-100' alt='Foto'/> :
                                        <div className='border pt-3'>
                                            <IconoCarga chico/>
                                            <div className='mb-3 text-center'>Cargando foto</div>
                                        </div>}
                                </div>
                                <div className='col-6 small'>
                                    <p className='fw-bold'>Foto actual</p>
                                    <p>
                                        Puede cargar una nueva foto en el cuadro inferior para reemplazar
                                        la foto existente <span className='text-muted'>(Opcional)</span>
                                    </p>
                                </div>
                            </div>
                        ) : (
                            <div className='mb-3'>
                                <i className='fas fa-info-circle me-2 text-info'/>
                                Sin foto cargada en el sistema
                            </div>
                        )}
                        <InputArchivo chico onChange={e => setFoto(e)}/>
                    </TarjetaSeccion>
                    <h5 className='ms-2'>Horarios teóricos</h5>
                    <TarjetaSeccion>
                        <div className='mb-3'>
                            {muestraHorario()}
                        </div>
                        <Boton chico titulo='Editar horarios' onClick={() => setEditarHorarios(true)}
                               icono='fas fa-user-clock'/>
                    </TarjetaSeccion>
                    <h5 className='ms-2'>Hijos</h5>
                    <TarjetaSeccion>
                        {hijos.length ? (
                            <table className='table table-bordered table-sm table-striped table-responsive
                            small mb-2 table-hover'>
                                <thead>
                                <tr>
                                    <th>Nombre</th>
                                    <th>DNI</th>
                                    <th>Fecha Nac.</th>
                                </tr>
                                </thead>
                                <tbody>
                                {hijos.map((h, i) => (
                                    <tr style={{cursor: 'pointer'}} onClick={() => editarHijoHandler(h.id)}
                                        key={i}>
                                        <td>{h.nombre_y_apellido}</td>
                                        <td>{h.dni}</td>
                                        <td>{h.fecha_nacimiento_fmt}</td>
                                    </tr>
                                ))}
                                </tbody>
                            </table>
                        ) : (
                            <div className='mb-3'>
                                <i className='fas fa-info-circle me-2 text-info'/>
                                Sin hijos informados en el sistema
                            </div>
                        )}
                        <Boton chico titulo='Agregar' icono='fas fa-plus'
                               onClick={() => editarHijoHandler(0)}/>
                    </TarjetaSeccion>
                </div>
            </div>
            {editarHijo !== undefined && (
                <Modal className='w-50'>
                    <h4 className='mb-3'>{editarHijo === 0 ? 'Agregar Hijo' : 'Editar Hijo'}</h4>
                    <InputTexto value={hijoNombre} onChange={setHijoNombre} titulo='Nombre y Apellido'
                                className='mb-3' autoFocus invalido={!validacionHijos.nombre_ok}/>
                    <div className='row'>
                        <div className='col-md-5'>
                            <InputTexto value={hijoDni} onChange={setHijoDni} titulo='DNI' className='mb-3'
                                        invalido={!validacionHijos.dni_ok}/>
                        </div>
                        <div className='col-md-7'>
                            <InputFecha value={hijoFechaNacimiento} onChange={setHijoFechaNacimiento}
                                        titulo='Fecha de Nacimiento' className='mb-3'
                                        invalido={!validacionHijos.fecha_ok}/>
                        </div>
                    </div>
                    <ModalBotones editando={editarHijo !== 0} guardando={cargando}
                                  onGuardar={() => guardarEdicionHijos()}
                                  onEliminar={() => guardarEdicionHijos(true)}
                                  onCancelar={() => editarHijoHandler(undefined)}/>
                </Modal>
            )}
            {editarHorarios && <EdicionHorarios onCancelar={() => setEditarHorarios(false)}
                                                onActualizar={() => actualizarHorarios()}
                                                idEmpleado={numIdEmpleado}/>}
        </CuerpoPrincipal>
    )

    return (
        <div>
            <BarraSuperior>
                <ToolbarBtn>
                    {cargado ? cargando? (
                        <BotonCarga titulo='Guardando...'/>
                        ) : (
                        <>
                            <Boton toolbar titulo='Guardar cambios y volver' icono='fas fa-save'
                                   className='btn-primary' onClick={() => guardarHandler()}/>
                            <Boton toolbar titulo='Descartar cambios' icono='fas fa-times'
                                   className='btn-danger' onClick={() => navigate('../')}/>
                        </>
                    ) : (
                        <Boton toolbar titulo='Volver' icono='fas fa-arrow-left'
                               className='btn-secondary' onClick={() => navigate('../')}/>
                    )}
                </ToolbarBtn>
            </BarraSuperior>
            {cargado ? cuerpoEdicion : <BodyCarga error={errorCarga} onReintentar={actualizar}/>}
        </div>
    )

}

export default EdicionLegajo
