import React, {useEffect} from "react";

export interface OpcionSelect {
    id: number,
    descripcion: string
}

type InputSelectProps = {
    opciones?: Array<OpcionSelect>,
    textocero?: string,
    sincero?: boolean,
    titulo?: string,
    className?: string,
    chico?: boolean,
    value: number,
    onChange: (arg: number) => void,
    titulado?: boolean,
    obligatorio?: boolean,
    invalido?: boolean,
    forzarCero?: boolean
}

/**
 * Control generador de un input tipo Select
 * @param props
 * @returns {JSX.Element}
 * @constructor
 */
const InputSelect: React.FC<InputSelectProps> = (
    {
        opciones, textocero, sincero, titulo,
        className, chico, value, onChange, titulado,
        obligatorio, invalido, forzarCero
    }
) => {
    const valores = (opciones && opciones.length) ?
        opciones.map(({id, descripcion}: OpcionSelect) => <option value={id} key={id}>{descripcion}</option>)
        : <option value='0'>{textocero ? textocero : '(N/D)'}</option>

    const valorCero = (!sincero && opciones && (opciones.length > 1 || forzarCero)) ?
        <option value='0'>{textocero ? textocero : '(Seleccione una opción)'}</option>
        : <></>

    useEffect(() => {
        if (opciones && opciones.length === 1 && value === 0 && !forzarCero) {
            onChange(opciones[0].id)
        }
    })

    if (titulo && titulado) {
        return (
            <div className='mb-2'>
                <div className='form-label ms-1 mb-1'>
                    {titulo}
                    {obligatorio && <span className='text-muted small ms-1'> (obligatorio)</span>}
                </div>
                <select className={'form-select' + (value === 0 ? ' text-muted' : '') +
                (invalido ? ' is-invalid' : '')}
                        value={value}
                        onChange={e => onChange(parseInt(e.target.value))}>
                    {valorCero}
                    {valores}
                </select>
            </div>
        )
    } else if (titulo) {
        const estilo = className + ' input-group' + (chico ? ' input-group-sm' : '')

        return (
            <div className={estilo}>
                <div className='input-group-text'>{titulo}</div>
                <select className={'form-select' + (value === 0 ? ' text-muted' : '') +
                (invalido ? ' is-invalid' : '')}
                        value={value}
                        onChange={e => onChange(parseInt(e.target.value))}>
                    {valorCero}
                    {valores}
                </select>
            </div>
        )
    } else {
        const estilo = (className || '') + ' form-select' + (chico ? ' form-select-sm' : '') +
            (value === 0 ? ' text-muted' : '') + (invalido ? ' is-invalid' : '')

        return (
            <select className={estilo} value={value}
                    onChange={e => onChange(parseInt(e.target.value))}>
                {valorCero}
                {valores}
            </select>
        )
    }
}

export default InputSelect
