import React, {useCallback, useEffect} from "react";
import './ModalError.css'
import {useAppDispatch, useAppSelector} from "../redux/hooks";
import Boton from "./Boton";
import {setErrorSistema} from "../redux/estadoReducer";

const ModalError: React.FC = () => {
    const mensaje = useAppSelector(state => state.estado.error_sistema)
    const dispatch = useAppDispatch()

    const teclasEspeciales = useCallback(e => {
        if (e.keyCode === 27) dispatch(setErrorSistema(undefined))
    }, [dispatch])

    useEffect(() => {
        document.addEventListener('keydown', teclasEspeciales, false)
        return () => document.removeEventListener('keydown', teclasEspeciales, false)
    }, [teclasEspeciales])

    return mensaje === undefined ? <></> : (
        <div className='fondomodalerror'>
            <div className='contenidomodalerror'>
                <div className='card sombramodalerror bg-danger text-white'>
                    <div className='card-body'>
                        <div className='row'>
                            <div className='col-auto'>
                                <i className='fas fa-times-circle h3 mt-3'/>
                            </div>
                            <div className='col'>
                                <div className='fw-bold h5 me-2'>Error</div>
                                <div>{mensaje}</div>
                            </div>
                        </div>
                        <div className='mb-3'>

                        </div>
                        <div className='text-center'>
                            <Boton chico titulo='Aceptar' className='btn-outline-light'
                            onClick={() => dispatch(setErrorSistema(undefined))}/>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default ModalError