import React, {useEffect, useState} from "react";
import ResumenVacaciones from "./ResumenVacaciones";
import {useAppSelector} from "../redux/hooks";
import {DatosEmpleadoVacaciones, DatosVacaciones, InfoVacaciones} from "./interfacesVacaciones";
import TablaVacaciones from "./TablaVacaciones";
import ToolbarBtn from "../controles/ToolbarBtn";
import Boton from "../controles/Boton";
import ConfiguracionVacaciones from "./ConfiguracionVacaciones";
import AsignacionVacaciones from "./AsignacionVacaciones";
import BodyCarga from "../controles/BodyCarga";
import BarraSuperior from "../controles/BarraSuperior";
import CuerpoPrincipal from "../controles/CuerpoPrincipal";
import {useGsBackend} from "../funcionesApi";
import InputTexto from "../controles/InputTexto";
import InputSelect from "../controles/InputSelect";

type Filtro = {
    id_ubicacion: number,
    empleado: string
}

type Muestras = {
    empleados: Array<DatosEmpleadoVacaciones>,
    vacaciones: Array<DatosVacaciones>
}

const Vacaciones: React.FC = () => {
    const [filtros, setFiltros] = useState<Filtro>({id_ubicacion: 0, empleado: ''})
    const [asignar, setAsignar] = useState<boolean>(false)
    const [configurar, setConfigurar] = useState<boolean>(false)
    const [datos, setDatos] = useState<InfoVacaciones | undefined>(undefined)
    const [muestra, setMuestra] = useState<Muestras>({empleados: [], vacaciones: []})
    const [idFiltrado, setIdFiltrado] = useState<number | undefined>(undefined)
    const edita = useAppSelector(state => state.auth.edicion)

    const {pedidoJson, errorCarga, actualizar} = useGsBackend()

    useEffect(() => {
        pedidoJson({url: 'vacaciones', method: 'get', ok: setDatos})
    }, [pedidoJson])

    useEffect(() => {
        if (datos === undefined) return

        let le = datos.empleados
        let lv = datos.vacaciones

        if (filtros.empleado !== '') {
            le = le.filter(e => e.descripcion.toLocaleLowerCase().includes(filtros.empleado.toLocaleLowerCase()))
            lv = lv.filter(e => e.empleado.toLocaleLowerCase().includes(filtros.empleado.toLocaleLowerCase()))
        }

        if (filtros.id_ubicacion !== 0) {
            le = le.filter(e => e.id_ubicacion === filtros.id_ubicacion)
            lv = lv.filter(e => e.id_ubicacion === filtros.id_ubicacion)
        }

        setMuestra({empleados: le, vacaciones: lv})
    }, [filtros, datos])

    const filtrarId = (id: number | undefined): void => {
        setIdFiltrado(id)
    }

    return (
        <div>
            <BarraSuperior>
                {edita ? (
                    <ToolbarBtn>
                        <Boton toolbar titulo='Asignación masiva de días' icono='fas fa-plus'
                               onClick={() => setAsignar(true)}/>
                        <Boton toolbar titulo='Configuración' icono='fas fa-cog'
                               onClick={() => setConfigurar(true)}/>
                    </ToolbarBtn>
                ) : (
                    <ToolbarBtn>
                        <Boton titulo='Visualización de vacaciones' toolbar icono='fas fa-plane'
                               className='btn-light'/>
                    </ToolbarBtn>
                )}
            </BarraSuperior>
            {datos === undefined ? (
                <BodyCarga error={errorCarga} onReintentar={actualizar}/>
            ) : (
                <>
                    <CuerpoPrincipal>
                        <div className='d-flex mb-3 align-items-center'>
                            <div className='pe-3'><i className='fas fa-search text-primary'/></div>
                            <div className='flex-grow-1'>
                                <div className='row g-2'>
                                    <div className='col-md'>
                                        <InputSelect chico titulo='Ubicación' value={filtros.id_ubicacion}
                                                     onChange={v => setFiltros({...filtros, id_ubicacion: v})}
                                                     forzarCero textocero='(Todas)' opciones={datos.ubicaciones}/>
                                    </div>
                                    <div className='col-md'>
                                        <InputTexto chico titulo='Nombre y/o Apellido' value={filtros.empleado}
                                                    onChange={v => setFiltros({...filtros, empleado: v})}
                                                    placeholder='(Todos)'/>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <h4 className='mb-0'>Resumen de Vacaciones</h4>
                    </CuerpoPrincipal>
                    <ResumenVacaciones semanas={datos.semanas} empleados={muestra.empleados}
                                       idFiltrado={idFiltrado} filtrarId={filtrarId}/>
                    <CuerpoPrincipal>
                        <h4 className='mb-0'>Próximas Vacaciones</h4>
                    </CuerpoPrincipal>
                    <TablaVacaciones vacaciones={muestra.vacaciones} idFiltrado={idFiltrado}/>
                    {configurar && <ConfiguracionVacaciones onCancelar={() => setConfigurar(false)}/>}
                    {asignar && <AsignacionVacaciones onCerrar={() => setAsignar(false)}/>}
                </>
            )}
        </div>
    )
}

export default Vacaciones
