import React, {useCallback, useEffect} from "react";
import Boton from "./Boton";
import ToolbarBtn from "./ToolbarBtn";
import Modal from "./Modal";

type Props = {
    titulo: string,
    descripcion: string,
    contenido?: React.ReactNode,
    onConfirmar: () => void,
    onCancelar: () => void
}

const ModalConfirmarCancelar: React.FC<Props> = (
    {titulo, descripcion, onConfirmar, onCancelar, contenido}
) => {
    const cancelarEscape = useCallback(e => {
        if (e.keyCode === 27) onCancelar()
    }, [onCancelar])

    useEffect(() => {
        document.addEventListener('keydown', cancelarEscape, false)
        return () => document.removeEventListener('keydown', cancelarEscape, false)
    }, [cancelarEscape])

    return (
        <Modal className='w-50'>
            <div className='row'>
                <div className='col-md-auto mb-3 mb-md-0'>
                    <i className='fas fa-exclamation-triangle text-warning fs-1 mt-md-4'/>
                </div>
                <div className='col-md'>
                    <h4>{titulo}</h4>
                    <p>{descripcion}</p>
                    {contenido}
                    <p>¿Confirmar la operación?</p>
                </div>
            </div>
            <ToolbarBtn derecha>
                <Boton toolbar className='btn-secondary' titulo='Cancelar' icono='fas fa-times'
                       onClick={() => onCancelar()}/>
                <Boton toolbar titulo='Confirmar' icono='fas fa-exclamation-triangle'
                       onClick={() => onConfirmar()}/>
            </ToolbarBtn>
        </Modal>
    )
}

export default ModalConfirmarCancelar