import React from "react";

type Props = {
    colSpan: number
}

const TrCarga: React.FC<Props> = ({colSpan}) => {
    return <tr>
        <td colSpan={colSpan}>
            <span className='spinner-border spinner-border-sm me-2 text-primary'/> Cargando...
        </td>
    </tr>
}

export default TrCarga