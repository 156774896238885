import React, {useEffect, useState} from "react";
import IconoCarga from "../controles/IconoCarga";
import InputFecha from "../controles/InputFecha";
import {useGsBackend} from "../funcionesApi";

type InfoFichadas = {
    hora: string,
    entrada: boolean,
    manual: boolean
}

type FichadasDia = {
    horario: string,
    fichadas: Array<InfoFichadas>
}

type Props = {
    empleado: number,
    fecha: string,
    mostrarHorario: boolean,
    onChange: () => void
}

const FichadasEmpleado: React.FC<Props> = ({empleado, fecha, mostrarHorario, onChange}) => {
    const [infoFichadasDia, setInfoFichadasDia] = useState<FichadasDia | undefined>(undefined)
    const [nuevoHora, setNuevoHora] = useState<string>('')
    const [validoNuevo, setValidoNuevo] = useState<boolean>(true)

    const {pedidoJson, actualizar, cargando} = useGsBackend()
    useEffect(() => {
        pedidoJson({
            url: `fichadas/revision?empleado=${empleado}&fecha=${encodeURIComponent(fecha)}`,
            ok: setInfoFichadasDia
        })
    }, [pedidoJson, empleado, fecha])

    const agregarHandler = async () => {
        if (nuevoHora === '') {
            setValidoNuevo(false)
            return
        } else {
            setValidoNuevo(true)
        }

        const fd = new FormData()
        fd.append('accion', 'nuevo')
        fd.append('empleado', empleado.toString())
        fd.append('fecha', fecha)
        fd.append('hora', nuevoHora)

        envioEdicion(fd)
        setNuevoHora('')
    }

    const eliminarHandler = async (hora: string) => {
        if (hora === '') return

        const fd = new FormData()
        fd.append('accion', 'eliminar')
        fd.append('empleado', empleado.toString())
        fd.append('fecha', fecha)
        fd.append('hora', hora)

        envioEdicion(fd)
    }

    const envioEdicion = (fd: FormData) => {
        pedidoJson({
            url: `fichadas/revision`, method: 'post', body: fd,
            ok: actualizar, finally: onChange
        })
    }

    const cantFichadas = infoFichadasDia !== undefined ? infoFichadasDia.fichadas.length : 0
    const celdasDescripcion = cantFichadas === 0 ? mostrarHorario ? 2 : 3 : 0
    const anchoCeldas = Math.round(100 / (cantFichadas + celdasDescripcion + 2))
    const estiloCeldas: React.CSSProperties = {width: `${anchoCeldas}%`}

    return <>
        <hr/>
        <h4>Fichadas del día</h4>
        {infoFichadasDia === undefined ? <IconoCarga chico/> :
            <table className='table table-responsive table-sm table-bordered text-center align-middle'>
                <thead>
                <tr>
                    {mostrarHorario && <th style={estiloCeldas} className='fw-bold bg-light'>Horario</th>}
                    {infoFichadasDia.fichadas.length ?
                        infoFichadasDia.fichadas.map((f, i) =>
                            <th style={estiloCeldas} key={i}>
                                <i className={f.entrada ? 'fas fa-sign-in-alt text-primary' :
                                    'fas fa-sign-out-alt text-muted'}/>
                            </th>) :
                        <th style={{width: `${anchoCeldas * celdasDescripcion}%`}}>Sin datos</th>}
                    <th style={estiloCeldas}>Agregar</th>
                </tr>
                </thead>
                <tbody>
                <tr>
                    {mostrarHorario && <td rowSpan={2} className='fw-bold bg-light'>
                        {infoFichadasDia.horario}
                    </td>}
                    {infoFichadasDia.fichadas.length ?
                        infoFichadasDia.fichadas.map((f, i) => <td key={i}>{f.hora}</td>) :
                        <td rowSpan={2}>No hay fichadas registradas en el día</td>}
                    <td>
                        <InputFecha hora chico titulo='Hora' value={nuevoHora} onChange={setNuevoHora}
                                    invalido={!validoNuevo} disabled={cargando}/>
                    </td>
                </tr>
                <tr>
                    {infoFichadasDia.fichadas.length ?
                        infoFichadasDia.fichadas.map((f, i) =>
                            <td key={i}>
                                {f.manual ?
                                    <button type='button' className='btn btn-danger btn-sm' disabled={cargando}
                                            onClick={() => eliminarHandler(f.hora)}>
                                        <i className='fas fa-times-circle'/>
                                    </button> :
                                    <button type='button' className='btn btn-light btn-sm' disabled>
                                        <i className='far fa-clock text-muted'/>
                                    </button>
                                }
                            </td>) : <></>}
                    <td>
                        <button type='button' className='btn btn-primary btn-sm' disabled={cargando}
                                onClick={() => agregarHandler()}>
                            <i className='fas fa-plus-circle'/>
                        </button>
                    </td>
                </tr>
                </tbody>
            </table>}
    </>
}

export default FichadasEmpleado