import React, {useCallback, useEffect, useState} from "react";
import InputSelect, {OpcionSelect} from "../controles/InputSelect";
import InputTexto from "../controles/InputTexto";
import {Filtros} from "../filtros";
import {useAppSelector} from "../redux/hooks";
import CheckEstado from "../controles/CheckEstado";
import Boton from "../controles/Boton";
import Modal from "../controles/Modal";
import BotonCheck from "../controles/BotonCheck";
import ModalBotones from "../controles/ModalBotones";
import TrCarga from "../controles/TrCarga";
import TrInfo from "../controles/TrInfo";
import {useGsBackend} from "../funcionesApi";

type Tipos = {
    id: number,
    descripcion: string
}

type Requisitos = {
    id: number,
    descripcion: string,
    aplicable: Array<Tipos>,
    mandatorio: boolean
}

const ListaRequisitos: React.FC = () => {
    const [datos, setDatos] = useState<Array<Requisitos>>([] as Array<Requisitos>)
    const [muestra, setMuestra] = useState<Array<Requisitos>>([] as Array<Requisitos>)
    const [filtroNombre, setFiltroNombre] = useState<string>('')
    const [filtroAplicable, setFiltroAplicable] = useState<number>(0)
    const [filtroMandatorio, setFiltroMandatorio] = useState<number>(0)
    const [listaTipos, setListaTipos] = useState<Array<Filtros>>([] as Array<Filtros>)
    const [editando, setEditando] = useState<number>(0)
    const [tituloEdicion, setTituloEdicion] = useState<string>('')
    const [editNombre, setEditNombre] = useState<string>('')
    const [editMandatorio, setEditMandatorio] = useState<number>(1)
    const [editAplicable, setEditAplicable] = useState<Array<Tipos>>([])
    const [editValida, setEditValida] = useState<boolean>(true)
    const edita = useAppSelector(state => state.auth.edicion)

    const {pedidoJson, actualizar, cargando} = useGsBackend()
    useEffect(() => {
        pedidoJson({
            url: 'requisitos/requisitos', ok: res => {
                setListaTipos(res.tiposderequisitos)
                setDatos(res.requisitos)
            }
        })
    }, [pedidoJson])

    const listaMandatorio = [
        {id: 1, descripcion: 'Sí'},
        {id: 2, descripcion: 'No'}
    ] as Array<OpcionSelect>

    const filtrar = useCallback((datos: Array<Requisitos>): void => {
        if (filtroAplicable !== 0)
            datos = datos.filter(d => d.aplicable.filter(
                ap => ap.id === filtroAplicable
            ).length)
        if (filtroNombre !== '')
            datos = datos.filter(d => d.descripcion.toLowerCase().includes(
                filtroNombre.toLowerCase()
            ))
        if (filtroMandatorio !== 0)
            datos = datos.filter(d => d.mandatorio === (filtroMandatorio === 1))

        setMuestra(datos)
    }, [filtroAplicable, filtroMandatorio, filtroNombre])

    useEffect(() => {
        filtrar(datos)
    }, [filtrar, datos])

    const opcionesMandatorio: Array<OpcionSelect> = [
        {id: 1, descripcion: 'Mandatorio'},
        {id: 2, descripcion: 'No mandatorio'}
    ]

    const modoEdicion = (id: number): void => {
        const info = datos.filter(d => d.id === id)[0]
        setEditNombre(info.descripcion)
        setEditMandatorio(info.mandatorio ? 1 : 2)
        setEditAplicable(info.aplicable)
        setTituloEdicion('Editar requisito')
        setEditando(id)
    }

    const modoNuevo = (): void => {
        setEditNombre('')
        setEditMandatorio(1)
        setEditAplicable([] as Array<Tipos>)
        setTituloEdicion('Nuevo requisito')
        setEditando(-1)
    }

    const toggleAplicable = (id: number): void => {
        if (editAplicable.filter(e => e.id === id).length === 0) {
            const nuevo = listaTipos.filter(t => t.id === id)[0]
            setEditAplicable([...editAplicable, nuevo])
        } else {
            setEditAplicable(editAplicable.filter(e => e.id !== id))
        }
    }

    const guardarHandler = async (eliminar: boolean = false): Promise<void> => {
        const valido = editNombre !== ''
        setEditValida(valido)
        if (!valido) return

        const fd = new FormData()
        const tipos = editAplicable.map(t => t.id).join(',')
        fd.append('accion', eliminar ? 'eliminar' : (editando === -1 ? 'nuevo' : 'editar'))
        fd.append('id', editando.toString())
        fd.append('nombre', editNombre)
        fd.append('mandatorio', editMandatorio === 1 ? '1' : '0')
        fd.append('tipos', tipos)

        pedidoJson({
            url: 'requisitos/requisitos', method: 'post', body: fd,
            ok: () => {
                setEditando(0)
                actualizar()
            }
        })
    }

    return (
        <>
            <h4>Requisitos</h4>
            <div className='border rounded-2'>
                <table className={'table table-responsive table-striped mb-0' + (edita ? ' table-hover' : '')}>
                    <thead>
                    <tr>
                        <td colSpan={3}>
                            <div className='row g-3'>
                                {edita && <div className='col-auto'>
                                    <Boton chico titulo='Nuevo' icono='fas fa-plus' onClick={modoNuevo}
                                           disabled={cargando}/>
                                </div>}
                                <div className='col-auto'>
                                    <i className='fas fa-search text-muted mt-2'/>
                                </div>
                                <div className='col'>
                                    <div className='row g-2'>
                                        <div className='col-md-4'>
                                            <InputTexto titulo='Requisito' chico
                                                        value={filtroNombre} onChange={setFiltroNombre}
                                                        placeholder='(Todos)'/>
                                        </div>
                                        <div className='col-md-4'>
                                            <InputSelect titulo='Aplicable a' chico
                                                         textocero='(Todos)' opciones={listaTipos}
                                                         value={filtroAplicable} onChange={setFiltroAplicable}/>
                                        </div>
                                        <div className='col-md-4'>
                                            <InputSelect titulo='Mandatorio' chico
                                                         textocero='(Todos)' opciones={listaMandatorio}
                                                         value={filtroMandatorio} onChange={setFiltroMandatorio}/>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </td>
                    </tr>
                    <tr>
                        <th>Requisito</th>
                        <th>Aplicable a</th>
                        <th>Mandatorio</th>
                    </tr>
                    </thead>
                    <tbody>
                    {muestra.length ? muestra.map((req, i) => (
                        <tr className={edita ? 'cursorpointer' : ''} key={i}
                            onClick={() => {
                                if (edita) modoEdicion(req.id)
                            }}>
                            <td>{req.descripcion}</td>
                            <td>
                                {req.aplicable.map((ap, k) => (
                                    <div className='badge bg-info me-2' key={k}>{ap.descripcion}</div>
                                ))}
                            </td>
                            <td><CheckEstado estado={req.mandatorio} times textosino/></td>
                        </tr>
                    )) : cargando ? (
                        <TrCarga colSpan={3}/>
                    ) : (
                        <TrInfo colSpan={3} texto='Sin requisitos para mostrar'/>
                    )}
                    </tbody>
                </table>
            </div>
            {editando !== 0 && (
                <Modal className='w-50'>
                    <h4>{tituloEdicion}</h4>
                    <InputTexto chico titulo='Nombre del Requisito' value={editNombre} autoFocus
                                onChange={setEditNombre} className='mb-2' invalido={!editValida}/>
                    <InputSelect chico titulo='Exigencia' value={editMandatorio}
                                 onChange={setEditMandatorio} className='mb-2'
                                 opciones={opcionesMandatorio} sincero/>
                    <h5>Aplicable a:</h5>
                    {listaTipos.map((t, i) => (
                        <BotonCheck titulo={t.descripcion} key={i}
                                    value={editAplicable.filter(e => e.id === t.id).length > 0}
                                    onClick={() => toggleAplicable(t.id)}/>
                    ))}
                    <ModalBotones editando={editando !== -1} guardando={cargando}
                                  onCancelar={() => setEditando(0)}
                                  onGuardar={() => guardarHandler()}
                                  onEliminar={() => guardarHandler(true)}/>
                </Modal>
            )}
        </>
    )
}

export default ListaRequisitos
