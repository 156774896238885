import React from "react";

type Props = {
    icono?: string,
    estilo?: string
    onClick: () => void
}

const BotonIcono: React.FC<Props> = ({icono, estilo, onClick}) => {
    if (estilo === undefined) estilo = 'btn-light'
    if (icono === undefined) icono = 'fas fa-external-link-alt'

    return (
        <button type='button' className={`btn ${estilo} btn-sm`} onClick={onClick}>
            <i className={icono}/>
        </button>
    )
}

export default BotonIcono
