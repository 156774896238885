import {createSlice, PayloadAction} from "@reduxjs/toolkit";

export interface AuthState {
    usuario?: string,
    nombrecompleto?: string,
    token?: string,
    cliente?: string,
    logo?: string,
    accesos?: Array<string>,
    admin?: boolean,
    edicion?: boolean
}

export const authSlice = createSlice({
    name: 'auth',
    initialState: {
        usuario: undefined,
        nombrecompleto: undefined,
        token: undefined,
        cliente: undefined,
        logo: undefined,
        accesos: [] as Array<string>,
        admin: undefined,
        edicion: undefined
    } as AuthState,
    reducers: {
        setLogueo: (state, action: PayloadAction<AuthState>) => {
            state.usuario = action.payload.usuario
            state.nombrecompleto = action.payload.nombrecompleto
            state.token = action.payload.token
            state.cliente = action.payload.cliente
            state.logo = action.payload.logo
            state.accesos = action.payload.accesos
            state.admin = action.payload.admin
            state.edicion = action.payload.edicion
        },
        setDeslogueo: state => {
            state.usuario = undefined
            state.nombrecompleto = undefined
            state.token = undefined
            state.cliente = undefined
            state.logo = undefined
            state.accesos = [] as Array<string>
            state.admin = undefined
            state.edicion = undefined
        }
    }
})

export const {setLogueo, setDeslogueo} = authSlice.actions

export default authSlice.reducer
