import React from "react";
import TiposDeRequisitos from "./TiposDeRequisitos";
import ListaRequisitos from "./ListaRequisitos";
import Boton from "../controles/Boton";
import BarraSuperior from "../controles/BarraSuperior";
import ToolbarBtn from "../controles/ToolbarBtn";
import CuerpoPrincipal from "../controles/CuerpoPrincipal";

const Requisitos: React.FC = () => {
    return (
        <div>
            <BarraSuperior>
                <ToolbarBtn>
                    <Boton chico titulo='Configuración de Requisitos' icono='fas fa-cog'/>
                </ToolbarBtn>
            </BarraSuperior>
            <CuerpoPrincipal>
                <div className='row g-3'>
                    <div className='col-md-3'>
                        <TiposDeRequisitos/>
                    </div>
                    <div className='col-md-9'>
                        <ListaRequisitos/>
                    </div>
                </div>
            </CuerpoPrincipal>
        </div>
    )
}

export default Requisitos
