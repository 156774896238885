import React, {useCallback, useEffect, useState} from "react";
import {formatoMoneda} from "./formatoMoneda";

type Props = {
    value: number,
    onChange: (v: number) => void,
    chico?: boolean,
    invalido?: boolean
}

type DatosEntrada = {
    nro: number,
    coma: boolean,
    cero: boolean,
    doblecero: boolean
}

const InputMoneda: React.FC<Props> = (
    {value, onChange, chico, invalido}
) => {
    const [datos, setDatos] = useState<DatosEntrada>(
        {nro: value, coma: false, cero: false, doblecero: false}
    )
    const [texto, setTexto] = useState<string>('')

    const valorHandler = useCallback((numero: number, arg?: string): void => {
        const rnd = Math.floor(numero * 100) / 100
        if (arg?.endsWith(',000')) arg = arg?.substring(0, arg?.length - 1)
        setDatos({
            nro: rnd,
            coma: arg !== undefined ? numero % 1 === 0 && (arg.endsWith(',') || arg.endsWith(',0')
                || arg.endsWith(',00')) : false,
            cero: arg !== undefined ? arg.includes(',') && arg.endsWith('0') : false,
            doblecero: arg !== undefined ? arg.endsWith(',00') : false
        })
        onChange(rnd)
    }, [onChange])

    const cambioHandler = (arg: string): void => {
        const numero = parseFloat(arg
            .replace(/[^\d,]/g, '')
            .split(',')
            .slice(0, 2)
            .join('.')) || 0
        valorHandler(numero, arg)
    }

    useEffect(() => {
        let str = formatoMoneda(datos.nro, true) + (datos.coma ? ',' : '') + (datos.cero ? '0' : '') +
            (datos.doblecero ? '0' : '')
        const posComa = str.lastIndexOf(',')
        if (-1 < posComa && posComa < str.length - 3 && str.endsWith('0')) str = str.substring(0, str.length - 1)
        setTexto(str)
    }, [datos])

    useEffect(() => {
        if (datos.nro !== value) valorHandler(value)
    }, [valorHandler, value, datos.nro])

    return (
        <input type='text' value={texto} onChange={e => cambioHandler(e.target.value)}
               className={'form-control' + (chico ? ' form-control-sm' : '') + (invalido ? ' is-invalid' : '')}/>
    )
}

export default InputMoneda