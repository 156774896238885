import React from "react";
import ToolbarBtn from "../controles/ToolbarBtn";
import Boton from "../controles/Boton";
import {useAppSelector} from "../redux/hooks";
import {Navigate, Outlet, useLocation, useNavigate} from "react-router-dom";
import BarraSuperior from "../controles/BarraSuperior";

const Configuracion: React.FC = () => {
    const auth = useAppSelector(state => state.auth)
    const navigate = useNavigate()
    const location = useLocation()

    const btnGris = 'btn-light'
    const btnActivo = 'btn-primary'

    const pantalla = location.pathname.split('/')[2]
    if (pantalla === undefined) return <Navigate to='tablas' replace/>

    return (
        <div>
            <BarraSuperior>
                <ToolbarBtn>
                    <Boton toolbar titulo='Tablas de Parámetros' icono='fas fa-list-alt'
                           onClick={() => navigate('tablas')}
                           className={pantalla === 'tablas' ? btnActivo : btnGris}/>
                    {auth.admin && (
                        <>
                            <Boton toolbar titulo='Usuarios' icono='fas fa-users'
                                   onClick={() => navigate('usuarios')}
                                   className={pantalla === 'usuarios' ? btnActivo : btnGris}/>
                            <Boton toolbar titulo='Auditoría' icono='fas fa-clipboard-list'
                                   onClick={() => navigate('auditoria')}
                                   className={pantalla === 'auditoria' ? btnActivo : btnGris}/>
                        </>
                    )}
                </ToolbarBtn>
            </BarraSuperior>
            <Outlet/>
        </div>
    )
}

export default Configuracion
