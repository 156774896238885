import React from "react";
import {DatosEmpleadoVacaciones, SemanaVacaciones} from "./interfacesVacaciones";
import TrInfo from "../controles/TrInfo";

type Props = {
    semanas: Array<SemanaVacaciones>,
    empleados: Array<DatosEmpleadoVacaciones>,
    idFiltrado: number | undefined,
    filtrarId: (arg: number | undefined) => void
}

const ResumenVacaciones: React.FC<Props> = (
    {semanas, empleados, idFiltrado, filtrarId}
) => {
    const ancho_semana = (85 / semanas.length).toString() + '%'

    const filtroHandler = (id: number): void => {
        if (idFiltrado === id) {
            filtrarId(undefined)
        } else {
            filtrarId(id)
        }
    }

    return (
        <table className='table table-responsive table-striped table-sm small table-hover border-top'>
            <thead>
            <tr>
                <th rowSpan={2} style={{width: '15%'}} className='border-end ps-3'>Empleado</th>
                {semanas.filter(s => s.semanas_muestra > 0).map((s, i) => (
                    <th colSpan={s.semanas_muestra} className='text-center' key={i}>{s.desc_mes} {s.anio}</th>
                ))}
            </tr>
            <tr>
                {semanas.map((s, i) => (
                    <th style={{width: ancho_semana}} className='text-center' key={i}>{s.desc_semana}</th>
                ))}
            </tr>
            </thead>
            <tbody>
            {empleados.length ? empleados.map((e, i) => (
                <tr style={{cursor: 'pointer'}} onClick={() => filtroHandler(e.id)} key={i}
                    className={idFiltrado === e.id ? 'bg-primary' : ''}>
                    <td className={(idFiltrado === e.id ? 'text-light' : '') + ' ps-3'}>{e.descripcion}</td>
                    {semanas.map((s, i) => s.ids_empleados.includes(e.id) ? (
                        <td className='bg-info text-center' key={i}>
                            <i className='fas fa-plane text-white-50'/>
                        </td>
                    ) : (
                        <td className='text-center text-muted' key={i}>-</td>
                    ))}
                </tr>
            )) : (
                <TrInfo colSpan={semanas.length + 1} paddingStart
                        texto='No hay información de vacaciones planificadas para mostrar'/>
            )}
            </tbody>
        </table>
    )
}

export default ResumenVacaciones
