import '../../node_modules/js-joda/dist/js-joda.min'
import React, {useEffect, useState} from "react";
import {useAppSelector} from "../redux/hooks";
import Boton from "../controles/Boton";
import Modal from "../controles/Modal";
import ModalBotones from "../controles/ModalBotones";
import InputFecha from "../controles/InputFecha";
import {ChronoUnit, LocalDate} from "js-joda";
import ToolbarBtn from "../controles/ToolbarBtn";
import TarjetaCarga from "../controles/TarjetaCarga";
import {useNavigate, useParams} from "react-router-dom";
import BarraSuperior from "../controles/BarraSuperior";
import CuerpoPrincipal from "../controles/CuerpoPrincipal";
import {useGsBackend} from "../funcionesApi";

type DatosEmp = {
    apellido: string,
    nombre: string,
    en_vacaciones: boolean,
    dias_vacaciones: number
}

type Vacaciones = {
    id: number,
    actual: boolean,
    pasadas: boolean,
    fecha_desde: string,
    fecha_desde_fmt: string,
    fecha_hasta: string,
    fecha_hasta_fmt: string,
    dias: number
}

const VacacionesEmpleado: React.FC = () => {
    const [empleado, setEmpleado] = useState<DatosEmp | undefined>(undefined)
    const [vacaciones, setVacaciones] = useState<Array<Vacaciones>>([] as Array<Vacaciones>)
    const [editarVacaciones, setEditarVacaciones] = useState<number | undefined>(undefined)
    const [editDesde, setEditDesde] = useState<string>('')
    const [editHasta, setEditHasta] = useState<string>('')
    const [dias, setDias] = useState<number>(0)
    const [disponiblesEdicion, setDisponiblesEdicion] = useState<number>(0)
    const [validoDesde, setValidoDesde] = useState<boolean>(true)
    const [validoHasta, setValidoHasta] = useState<boolean>(true)
    const [validoDias, setValidoDias] = useState<boolean>(true)
    const edita = useAppSelector(state => state.auth.edicion)
    const params = useParams()
    const navigate = useNavigate()
    const numIdEmpleado = parseInt(params.idEmpleado || '0')

    const {pedidoJson, errorCarga, actualizar, cargando} = useGsBackend()
    useEffect(() => {
            pedidoJson({
                url: `legajos/vacaciones/${numIdEmpleado}`,
                ok: res => {
                    // Si no habia un id valido volver a Legajos
                    if (res.empleado === undefined) navigate('../../')

                    setEmpleado(res.empleado)
                    setVacaciones(res.vacaciones)
                }
            })
        }, [pedidoJson, navigate, numIdEmpleado]
    )

    const edicionHandler = (idVacaciones: number | undefined): void => {
        if (empleado === undefined) return

        setValidoDesde(true)
        setValidoHasta(true)
        setValidoDias(true)
        if (idVacaciones === undefined) {
            setEditarVacaciones(undefined)
        } else if (idVacaciones === 0) {
            setEditDesde('')
            setEditHasta('')
            setDisponiblesEdicion(empleado.dias_vacaciones)
            validarFechas('', '')
            setEditarVacaciones(0)
        } else {
            const datos = vacaciones.filter(v => v.id === idVacaciones)[0]
            const disponibles = empleado.dias_vacaciones + datos.dias
            setEditDesde(datos.fecha_desde)
            setEditHasta(datos.fecha_hasta)
            setDisponiblesEdicion(disponibles)
            validarFechas(datos.fecha_desde, datos.fecha_hasta, disponibles)
            setEditarVacaciones(idVacaciones)
        }
    }

    const validarFechas = (pDesde: string, pHasta: string, pDias?: number): number => {
        if (pDesde === '' || pHasta === '' || empleado === undefined) {
            setDias(0)
            return 0
        }

        const diasDisponibles = pDias === undefined ? disponiblesEdicion : pDias

        const ld = LocalDate
        const fDesde = ld.parse(pDesde)
        const fHasta = ld.parse(pHasta)
        const diasVac = ChronoUnit.DAYS.between(fDesde, fHasta) + 1

        setValidoHasta(diasVac > 0)
        setValidoDias(diasVac > 0 && diasVac <= diasDisponibles)

        const muestra = Math.max(diasVac, 0)
        setDias(muestra)
        return muestra
    }

    const guardarHandler = async (eliminar: boolean = false): Promise<void> => {
        if (editarVacaciones === undefined) return

        const desdeOk = editDesde !== ''
        const hastaOk = editHasta !== ''
        setValidoDesde(desdeOk)
        setValidoHasta(hastaOk)
        if (!desdeOk || !hastaOk) return
        if (validarFechas(editDesde, editHasta) < 1) return
        if (!validoDias) return

        const fd = new FormData()
        fd.append('accion', eliminar ? 'eliminar' : (editarVacaciones === 0 ? 'nuevo' : 'editar'))
        fd.append('id', editarVacaciones.toString())
        fd.append('fecha_desde', editDesde)
        fd.append('fecha_hasta', editHasta)

        pedidoJson({
            url: `legajos/vacaciones/${numIdEmpleado}`, method: 'post', body: fd,
            ok: () => {
                edicionHandler(undefined)
                actualizar()
            }
        })
    }

    if (empleado !== undefined) {
        const nombreCompleto = `${empleado.apellido}, ${empleado.nombre}`

        const estadoVacaciones = (v: Vacaciones) => {
            if (v.actual) {
                return <span><i className='fas fa-plane me-1'/> En curso</span>
            } else if (v.pasadas) {
                return <span className='text-success'><i className='fas fa-check-double me-1'/> Pasadas</span>
            } else {
                return <span className='text-info'><i className='fas fa-calendar-alt me-1'/>Agendadas</span>
            }
        }

        const fechaHandler = (val: string, campoDesde: boolean): void => {
            if (campoDesde) {
                setEditDesde(val)
                validarFechas(val, editHasta)
            } else {
                setEditHasta(val)
                validarFechas(editDesde, val)
            }
        }

        return (
            <div>
                <BarraSuperior>
                    <ToolbarBtn>
                        <Boton toolbar titulo='Volver al Legajo' icono='fas fa-arrow-left'
                               className='btn-secondary' onClick={() => navigate('../')}/>
                        {edita && <Boton toolbar titulo='Nuevas Vacaciones' icono='fas fa-plane'
                                         onClick={() => edicionHandler(0)}/>}
                    </ToolbarBtn>
                </BarraSuperior>
                <CuerpoPrincipal>
                    <h4>
                        <strong>{nombreCompleto}</strong> - Vacaciones del Empleado
                    </h4>
                    {empleado.en_vacaciones ? (
                        <div className='badge bg-info mb-2'>
                            <i className='fas fa-plane me-1'/> En Vacaciones
                        </div>
                    ) : <></>}
                    <div>
                        <i className='fas fa-info-circle me-2 text-info'/>
                        {empleado.dias_vacaciones === 0 ? 'Sin días de vacaciones disponibles' :
                            empleado.dias_vacaciones === 1 ? '1 día de vacaciones disponible' :
                                `${empleado.dias_vacaciones} días de vacaciones disponibles`}
                    </div>
                </CuerpoPrincipal>
                <table className={'table table-responsive table-striped' + (edita ? ' table-hover' : '')}>
                    <thead>
                    <tr>
                        <th className='ps-3'>Estado</th>
                        <th>Fecha Desde</th>
                        <th>Fecha Hasta</th>
                        <th>Días</th>
                    </tr>
                    </thead>
                    <tbody>
                    {
                        vacaciones.length ?
                            vacaciones.map((v, i) => (
                                <tr className={edita ? 'cursorpointer' : ''} key={i}
                                    onClick={() => {
                                        if (edita) edicionHandler(v.id)
                                    }}>
                                    <td className='ps-3'>{estadoVacaciones(v)}</td>
                                    <td>{v.fecha_desde_fmt}</td>
                                    <td>{v.fecha_hasta_fmt}</td>
                                    <td className='text-end'>{v.dias}</td>
                                </tr>
                            ))
                            :
                            <tr>
                                <td className='ps-3' colSpan={4}>No hay vacaciones cargadas en el Sistema</td>
                            </tr>
                    }
                    </tbody>
                </table>
                {editarVacaciones !== undefined && (
                    <Modal className='w-50'>
                        <h4>{editarVacaciones === 0 ? 'Nuevas Vacaciones' : 'Editar Vacaciones'}</h4>
                        <div className='row mb-2'>
                            <div className='col-sm-6'>
                                <InputFecha value={editDesde} onChange={v => fechaHandler(v, true)}
                                            invalido={!validoDesde}
                                            titulo='Desde' className='mb-3'/>
                                <InputFecha value={editHasta} onChange={v => fechaHandler(v, false)}
                                            invalido={!validoHasta}
                                            titulo='Hasta' className='mb-3'/>
                            </div>
                            <div className='col-sm-6'>
                                <div className='row'>
                                    <strong>Disponibilidad del Empleado</strong>
                                </div>
                                <div className='row'>
                                    <div className='col-sm-6'>Días disponibles</div>
                                    <div className='col-sm-6'>{disponiblesEdicion}</div>
                                </div>
                                <div className='row'>
                                    <div className='col-sm-6'>Días solicitados</div>
                                    <div className='col-sm-6'>
                                        {validoDias ? dias : (
                                            <span className='text-danger'>
                                                {dias} <i className='fas fa-times-circle ms-1'/>
                                            </span>
                                        )}
                                    </div>
                                </div>
                            </div>
                        </div>
                        {editarVacaciones === 0 ? (
                            <ModalBotones onGuardar={() => guardarHandler()} guardando={cargando}
                                          onCancelar={() => edicionHandler(undefined)}/>
                        ) : (
                            <ModalBotones editando
                                          onGuardar={() => guardarHandler()} guardando={cargando}
                                          onEliminar={() => guardarHandler(true)}
                                          onCancelar={() => edicionHandler(undefined)}/>
                        )}
                    </Modal>
                )}
            </div>
        )
    } else {
        return <TarjetaCarga error={errorCarga} onReintentar={actualizar} onVolver={() => navigate('../')}/>
    }
}

export default VacacionesEmpleado
