import React from "react";
import {setDeslogueo} from "../redux/authReducer";
import {useAppDispatch} from "../redux/hooks";
import ToolbarBtn from "./ToolbarBtn";
import Boton from "./Boton";
import {useGsBackend} from "../funcionesApi";

type Props = {
    estiloModal?: boolean
}

const BotonDesloguear: React.FC<Props> = ({estiloModal}) => {
    const dispatch = useAppDispatch()
    const {pedidoJson} = useGsBackend()

    // Funcion para cerrar sesión en el sistema
    const desloguear = () => {
        pedidoJson({
            url: 'logout', method: 'post',
            ok: () => {
                localStorage.removeItem('login')
                dispatch(setDeslogueo())
            }
        })
    }

    if (estiloModal) {
        return <ToolbarBtn derecha>
            <Boton toolbar titulo='Cerrar sesión' icono='fas fa-sign-out-alt' onClick={desloguear}/>
        </ToolbarBtn>
    } else {
        return <button className='btn btn-link btn-sm p-0 text-primary' onClick={desloguear}>
            <i className='fas fa-sign-out-alt me-2'/>Cerrar sesión
        </button>
    }
}

export default BotonDesloguear