import React from "react";
import Boton from "./Boton";
import CuerpoPrincipal from "./CuerpoPrincipal";

type Props = {
    error: string | undefined,
    onReintentar: () => void
}

const BodyCarga: React.FC<Props> = ({error, onReintentar}) => {
    if (error) {
        return (
            <CuerpoPrincipal>
                <p>
                    <i className='fas fa-exclamation-triangle text-danger me-2'/> Error de carga: {error}
                </p>
                <Boton titulo='Reintentar' icono='fas fa-sync-alt' onClick={onReintentar}/>
            </CuerpoPrincipal>
        )
    } else {
        return (
            <CuerpoPrincipal>
                <span className='spinner-border spinner-border-sm me-2 text-primary'/> Cargando...
            </CuerpoPrincipal>
        )
    }
}

export default BodyCarga