import CheckEstado from "./CheckEstado";
import React from "react";

type EstadoMensajeProps = {
    estado: boolean,
    mensajes?: string[],
    mensajeOk: string
}

const EstadoMensaje: React.FC<EstadoMensajeProps> = (
    {estado, mensajes, mensajeOk}
) => {
    const vistaMensajes = (datos: string[] | undefined, mensajeOk: string) => {
        if (datos !== undefined && datos.length) {
            return (
                <>
                    {datos.map((msg, i) => (
                        <span key={i}>{msg}<br/></span>
                    ))}
                </>
            )
        } else {
            return <>{mensajeOk}</>
        }
    }

    return (
        <div className='row'>
            <div className='col-auto'><CheckEstado estado={estado}/></div>
            <div className={'col p-0 ' + (estado ? 'text-success' : 'text-danger')}>
                {vistaMensajes(mensajes, mensajeOk)}
            </div>
        </div>
    )
}

export default EstadoMensaje
