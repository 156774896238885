import React, {useCallback, useEffect, useState} from "react";
import InputSelect, {OpcionSelect} from "../controles/InputSelect";
import InputTexto from "../controles/InputTexto";
import CheckEstado from "../controles/CheckEstado";
import Boton from "../controles/Boton";
import {DatosEmpleado} from "./interfacesLegajo";
import {useAppSelector} from "../redux/hooks";
import {Filtros} from "../filtros";
import AltaEmpleado from "./AltaEmpleado";
import BotonesExportar from "../controles/BotonesExportar";
import ThOrdenable, {OrdenTabla} from "../controles/ThOrdenable";
import TrInfo from "../controles/TrInfo";
import TrCarga from "../controles/TrCarga";
import {useNavigate} from "react-router-dom";
import BarraSuperior from "../controles/BarraSuperior";
import CuerpoPrincipal from "../controles/CuerpoPrincipal";
import ToolbarBtn from "../controles/ToolbarBtn";
import {useGsBackend} from "../funcionesApi";
import {funcionDescarga} from "../descargas/descargas";

const ListaEmpleados: React.FC = () => {
    const [alta, setAlta] = useState<boolean>(false)
    const [orden, setOrden] = useState<OrdenTabla>({campo: 'apellido', ascendente: true} as OrdenTabla)
    const [empleados, setEmpleados] = useState<Array<DatosEmpleado>>([] as Array<DatosEmpleado>)
    const [muestra, setMuestra] = useState<Array<DatosEmpleado>>([] as Array<DatosEmpleado>)
    const [ubicacion, setUbicacion] = useState<number>(0)
    const [listaUbicaciones, setListaUbicaciones] = useState<Array<Filtros>>([] as Array<Filtros>)
    const [nombre, setNombre] = useState<string>('')
    const [categoria, setCategoria] = useState<number>(0)
    const [listaCategorias, setListaCategorias] = useState<Array<Filtros>>([] as Array<Filtros>)
    const [cuil, setCuil] = useState<string>('')
    const [soloActivos, setSoloActivos] = useState<number>(1)
    const edita = useAppSelector(state => state.auth.edicion)
    const navigate = useNavigate()

    const opcSoloActivos = [
        {id: 1, descripcion: 'Sí'},
        {id: 2, descripcion: 'No'}
    ] as Array<OpcionSelect>

    const filtroyOrden = useCallback((datos: Array<DatosEmpleado>): void => {
        // Filtros seleccionados
        if (soloActivos === 1) datos = datos.filter(d => d.activo)
        if (nombre !== '')
            datos = datos.filter(d => (d.nombre + ' ' + d.apellido).toLowerCase().includes(
                nombre.toLowerCase()
            ))
        if (cuil !== '') datos = datos.filter(d => d.cuil.toLowerCase().includes(cuil.toLowerCase()))
        if (ubicacion !== 0) datos = datos.filter(d => d.id_ubicacion === ubicacion)
        if (categoria !== 0) datos = datos.filter(d => d.id_categoria === categoria)

        // Orden seleccionado
        if (orden.campo === 'estado') {
            datos = datos.sort(a => a.estado === orden.ascendente ? 1 : -1)
        } else {
            type campos = 'nro_legajo' | 'apellido' | 'nombre' | 'categoria' | 'cuil' | 'ubicacion'
            const prueba: campos = orden.campo as campos
            datos = datos.sort((a, b) =>
                a[prueba].localeCompare(b[prueba]) * (orden.ascendente ? 1 : -1))
        }

        setMuestra(datos)
    }, [categoria, cuil, nombre, orden, soloActivos, ubicacion])

    useEffect(() => {
        filtroyOrden(empleados)
    }, [filtroyOrden, empleados])

    const {pedidoJson, pedidoBlob, cargando} = useGsBackend()
    useEffect(() => {
        pedidoJson({
            url: 'legajos/empleados',
            ok: res => {
                setListaUbicaciones(res.ubicaciones)
                setListaCategorias(res.categorias)
                setEmpleados(res.empleados)
            }
        })
    }, [pedidoJson])

    const altaHandler = (arg: number): void => {
        navigate(arg.toString())
    }

    const excelEmpleados = () => {
        pedidoBlob({url: 'excel/empleados', ok: res => funcionDescarga(res, 'empleados.xlsx')})
    }

    return (
        <div>
            <BarraSuperior>
                <ToolbarBtn>
                    <Boton toolbar titulo='Reportes' icono='fas fa-chart-pie'
                           onClick={() => navigate('reportes')}/>
                    {edita && <Boton toolbar titulo='Alta de Empleado' icono='fas fa-plus'
                                     onClick={() => setAlta(true)}/>}
                    <BotonesExportar onExcel={excelEmpleados}/>
                </ToolbarBtn>
            </BarraSuperior>
            <CuerpoPrincipal sinEspacio>
                <div className='row g-3'>
                    <div className='col-auto'>
                        <i className='fas fa-search text-primary mt-2'/>
                    </div>
                    <div className='col'>
                        <div className='row g-2 mb-2'>
                            <div className='col-md-4'>
                                <InputSelect titulo='Ubicación' chico
                                             opciones={listaUbicaciones} textocero='(Todas)'
                                             value={ubicacion} onChange={setUbicacion}/>
                            </div>
                            <div className='col-md-4'>
                                <InputSelect titulo='Categoría' chico
                                             textocero='(Todas)' opciones={listaCategorias}
                                             value={categoria} onChange={setCategoria}/>
                            </div>
                            <div className='col-md-4'>
                                <InputSelect titulo='Sólo activos' chico
                                             opciones={opcSoloActivos} sincero
                                             value={soloActivos} onChange={setSoloActivos}/>
                            </div>
                        </div>
                        <div className='row g-2 mb-2'>
                            <div className='col-md-6'>
                                <InputTexto titulo='Nombre y/o Apellido' chico
                                            value={nombre} onChange={setNombre}
                                            placeholder='(Todos)'/>
                            </div>
                            <div className='col-md-6'>
                                <InputTexto titulo='CUIL' chico
                                            value={cuil} onChange={setCuil}
                                            placeholder='(Todos)'/>
                            </div>
                        </div>
                    </div>
                </div>
            </CuerpoPrincipal>
            <table className='table table-responsive table-striped table-hover'>
                <thead>
                <tr>
                    <ThOrdenable titulo='Legajo' campo='nro_legajo' orden={orden} onClick={setOrden}
                                 className='ps-3'/>
                    <ThOrdenable titulo='Apellido' campo='apellido' orden={orden} onClick={setOrden}/>
                    <ThOrdenable titulo='Nombre' campo='nombre' orden={orden} onClick={setOrden}/>
                    <ThOrdenable titulo='Categoría' campo='categoria' orden={orden} onClick={setOrden}/>
                    <ThOrdenable titulo='CUIL' campo='cuil' orden={orden} onClick={setOrden}/>
                    <ThOrdenable titulo='Ubicación' campo='ubicacion' orden={orden} onClick={setOrden}/>
                    <ThOrdenable titulo='Estado' campo='estado' orden={orden} onClick={setOrden}
                                 className='text-center'/>
                </tr>
                </thead>
                <tbody>
                {muestra.length ? muestra.map((emp, i) => (
                    <tr style={{'cursor': 'pointer'}} key={i}
                        onClick={() => navigate(emp.id.toString())}
                        className={emp.activo ? '' : 'text-muted'}>
                        <td className='ps-3'>{emp.nro_legajo}</td>
                        <td>{emp.apellido}</td>
                        <td>{emp.nombre}</td>
                        <td>{emp.categoria}</td>
                        <td>{emp.cuil}</td>
                        <td>{emp.ubicacion}</td>
                        <td className='text-nowrap text-center'>
                            {
                                emp.activo ? <>
                                    <CheckEstado estado={emp.estado}/>
                                    {emp.vacaciones && <i className='fas fa-plane ms-2 text-info'/>}
                                </> : <div className='badge bg-light'>Inactivo</div>
                            }
                        </td>
                    </tr>
                )) : cargando ? (
                    <TrCarga colSpan={7}/>
                ) : (
                    <TrInfo colSpan={7} texto='No hay empleados para mostrar'/>
                )}
                </tbody>
            </table>
            {alta && <AltaEmpleado onAlta={id => altaHandler(id)} onCancelar={() => setAlta(false)}/>}
        </div>
    )
}

export default ListaEmpleados
