import React, {useEffect, useRef} from "react";
import './BotonModulo.scss'
import {Modulo} from "../modulos";
import {Tooltip as BsTooltip} from "bootstrap";

type Props = {
    mod: Modulo, pantalla: string, onCambio: (arg: string) => void
}

const BotonModulo: React.FC<Props> = ({mod, pantalla, onCambio}) => {
    const claseActivo = pantalla === mod.clave ? 'activo' : ''
    const botonRef = useRef<HTMLDivElement>(null)

    useEffect(() => {
        if (botonRef.current === null) return

        const t = new BsTooltip(botonRef.current, {
            placement: 'right',
            customClass: 'tooltipModulo',
            title: mod.titulo,
            offset: [0, 10]
        })
        return () => t.dispose()
    })

    return (
        <div className={`botonModulo ${claseActivo}`}
             onClick={() => onCambio(mod.clave)}
             ref={botonRef}
             data-bs-toogle='tooltip'>
            <i className={`${mod.icono}`}/>
        </div>
    )
}

export default BotonModulo