import React, {useEffect, useState} from "react";
import {useNavigate, useParams} from "react-router-dom";
import BarraSuperior from "../controles/BarraSuperior";
import ToolbarBtn from "../controles/ToolbarBtn";
import Boton from "../controles/Boton";
import {ElementoTrazabilidad} from "./Trazabilidad";
import {useGsBackend} from "../funcionesApi";
import BodyCarga from "../controles/BodyCarga";
import CuerpoPrincipal from "../controles/CuerpoPrincipal";
import VistaEstadosTrazabilidad from "./VistaEstadosTrazabilidad";
import TarjetaSeccion from "../controles/TarjetaSeccion";
import TextoND from "../controles/TextoND";
import TrInfo from "../controles/TrInfo";
import TrazabilidadIngresarMovimiento from "./TrazabilidadIngresarMovimiento";
import {funcionDescarga} from "../descargas/descargas";
import Tooltip, {InfoTooltip} from "../controles/Tooltip";

type Movimiento = {
    id: number,
    fechayhora: string,
    descripcion: string,
    estado_previo: number,
    estado_actualizado: number,
    documento: string,
    usuario: string,
    nombre_usuario: string
}

type Datos = {
    detalle: ElementoTrazabilidad,
    movimientos: Array<Movimiento>
}

const TrazabilidadDetalle: React.FC = () => {
    const [datos, setDatos] = useState<Datos | undefined>(undefined)
    const [nuevo, setNuevo] = useState<boolean>(false)
    const [infoTooltip, setInfoTooltip] = useState<InfoTooltip | undefined>(undefined)
    const navigate = useNavigate()
    const params = useParams()
    const idTrazabilidad = parseInt(params.idTrazabilidad || '0')

    const {pedidoJson, pedidoBlob, errorCarga, actualizar} = useGsBackend()
    useEffect(() => {
        pedidoJson({url: `trazabilidad/${idTrazabilidad}`, ok: setDatos})
    }, [pedidoJson, idTrazabilidad])

    const vistaDiferenciaFecha = (diferencia: number): string => {
        if (diferencia < 0) {
            return `Hace ${-diferencia} días`
        } else if (diferencia > 0) {
            return `En ${diferencia} días`
        } else {
            return 'Hoy'
        }
    }

    const cargaTooltipMovimientos = (idMovimiento: number): void => {
        setInfoTooltip({
            urlPreview: `previews?tipo=movimientostrazabilidad&id_movimiento=${idMovimiento}`
        })
    }

    const descargaMovimientoTrazabilidad = (idMovimientoTrazabilidad: number, nombreArchivo: string): void => {
        pedidoBlob({
            url: `archivos?tipo=movimientostrazabilidad&id_movimiento=${idMovimientoTrazabilidad}`,
            ok: res => funcionDescarga(res, nombreArchivo)
        })
    }

    return (
        <div>
            <BarraSuperior>
                <ToolbarBtn>
                    <Boton toolbar titulo='Volver' icono='fas fa-chevron-left' className='btn-secondary'
                           onClick={() => navigate('../../')}/>
                    <Boton toolbar titulo='Ingresar Movimiento' icono='fas fa-plus'
                           onClick={() => setNuevo(true)}/>
                </ToolbarBtn>
            </BarraSuperior>
            {datos === undefined ? (
                <BodyCarga error={errorCarga} onReintentar={actualizar}/>
            ) : (
                <>
                    <CuerpoPrincipal sinEspacio>
                        <div className='d-flex align-items-center mb-2'>
                            <div style={{fontSize: '1.5em'}} className='pe-3'>
                                <span className='badge bg-dark'>{datos.detalle.cod_seguimiento}</span>
                            </div>
                            <div className='flex-grow-1'>
                                <h3 className='mb-0'>{datos.detalle.descripcion}</h3>
                                <div style={{fontSize: '1.25em'}}>
                                    <VistaEstadosTrazabilidad estado={datos.detalle.estado}/>
                                </div>
                            </div>
                        </div>
                        <TarjetaSeccion>
                            <div className='row'>
                                <div className='col-lg-2'><strong>Ingreso</strong></div>
                                <div className='col-lg-4'>
                                    {datos.detalle.fecha_inicio_fmt}<br/>
                                    <strong>{datos.detalle.usuario}</strong> ({datos.detalle.nombre_usuario})
                                </div>
                                {datos.detalle.fecha_fin_real_fmt === '' ? (
                                    <>
                                        <div className='col-lg-2'><strong>Finalización estimada</strong></div>
                                        <div className='col-lg-4'>
                                            {datos.detalle.fecha_fin_estimada_fmt}<br/>
                                            <small>
                                                <i className='fas fa-caret-right text-muted me-2'/>
                                                <strong>
                                                    {vistaDiferenciaFecha(datos.detalle.diferencia_fecha_fin)}
                                                </strong>
                                            </small>
                                        </div>
                                    </>
                                ) : (
                                    <>
                                        <div className='col-lg-2'><strong>Finalizado</strong></div>
                                        <div className='col-lg-4'>{datos.detalle.fecha_fin_real_fmt}</div>
                                    </>
                                )}
                            </div>
                            <hr/>
                            <div className='row mb-2'>
                                <div className='col-lg-2'><strong>Proveedor</strong></div>
                                <div className='col-lg-4'>{datos.detalle.proveedor}</div>
                                <div className='col-lg-2'><strong>Origen / Maquinaria</strong></div>
                                <div className='col-lg-4'>{datos.detalle.destino}</div>
                            </div>
                            <div className='row mb-2'>
                                <div className='col-lg-2'><strong>Observaciones</strong></div>
                                <div className='col-lg-4'><TextoND texto={datos.detalle.observaciones}/></div>
                                <div className='col-lg-2'><strong>Sector</strong></div>
                                <div className='col-lg-4'>{datos.detalle.sector}</div>
                            </div>
                        </TarjetaSeccion>
                        <h4>Movimientos</h4>
                    </CuerpoPrincipal>
                    <table className='table table-responsive table-striped'>
                        <thead>
                        <tr>
                            <th className='ps-3'>Fecha y Hora</th>
                            <th>Descripción</th>
                            <th>Estado Previo</th>
                            <th>Estado Actualizado</th>
                            <th>Documento</th>
                            <th>Usuario</th>
                        </tr>
                        </thead>
                        <tbody>
                        {datos.movimientos.length ? datos.movimientos.map((m, k) => (
                            <tr key={k}>
                                <td className='ps-3'>{m.fechayhora}</td>
                                <td>{m.descripcion}</td>
                                <td><VistaEstadosTrazabilidad estado={m.estado_previo}/></td>
                                <td><VistaEstadosTrazabilidad estado={m.estado_actualizado}/></td>
                                <td>
                                    {m.documento !== '' && (
                                        <span className='enlacespan text-primary'
                                              style={{'cursor': 'pointer'}}
                                              onClick={e => {
                                                  e.stopPropagation()
                                                  descargaMovimientoTrazabilidad(m.id, m.documento)
                                              }}
                                              onMouseEnter={() => {
                                                  cargaTooltipMovimientos(m.id)
                                              }}
                                              onMouseLeave={() => setInfoTooltip(undefined)}>
                                              <i className='fas fa-file-download me-2'/>Descargar
                                              </span>
                                    )}
                                </td>
                                {m.usuario !== ''? (
                                    <td><strong>{m.usuario}</strong> ({m.nombre_usuario})</td>
                                ) : (
                                    <td className='text-muted'>N/D</td>
                                )}
                            </tr>
                        )) : (
                            <TrInfo colSpan={6} texto='No hay movimientos para mostrar'/>
                        )}
                        </tbody>
                    </table>
                    {infoTooltip !== undefined && <Tooltip datos={infoTooltip}/>}
                    {nuevo && (
                        <TrazabilidadIngresarMovimiento idTrazabilidad={datos.detalle.id}
                                                        estadoPrevio={datos.detalle.estado}
                                                        onCancelar={() => setNuevo(false)}
                                                        onGuardar={() => {
                                                            setNuevo(false)
                                                            actualizar()
                                                        }}/>
                    )}
                </>
            )}
        </div>
    )
}

export default TrazabilidadDetalle