import React from "react";

type TextoNDProps = {
    texto?: string
}

const TextoND: React.FC<TextoNDProps> = ({texto}) => {
    if (!texto || texto === '') {
        return <span className='text-muted'>N/D</span>
    } else {
        return <>{texto}</>
    }
}

export default TextoND
