export interface Modulo {
    clave: string,
    titulo: string,
    icono: string
}

const modLegajos: Modulo = {
    clave: 'legajos',
    titulo: 'Legajos',
    icono: 'fas fa-user-friends'
}

const modRequisitos: Modulo = {
    clave: 'requisitos',
    titulo: 'Requisitos',
    icono: 'fas fa-list-alt'
}

const modVacaciones: Modulo = {
    clave: 'vacaciones',
    titulo: 'Vacaciones',
    icono: 'fas fa-plane'
}

const modStock: Modulo = {
    clave: 'stock',
    titulo: 'Stock',
    icono: 'fas fa-boxes-stacked'
}

const modTrazabilidad: Modulo = {
    clave: 'trazabilidad',
    titulo: 'Trazabilidad de Piezas',
    icono: 'fas fa-toolbox'
}

const modConfiguracion: Modulo = {
    clave: 'configuracion',
    titulo: 'Configuración',
    icono: 'fas fa-cog'
}

const Modulos = [
    modLegajos, modRequisitos, modVacaciones, modStock, modTrazabilidad, modConfiguracion
]

export default Modulos
