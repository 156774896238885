import React from 'react';
import {createRoot} from "react-dom/client";
import App from './App';
import store from "./redux/store";
import {Provider} from "react-redux";
import {BrowserRouter, Navigate, Route, Routes} from "react-router-dom";
import Requisitos from "./requisitos/Requisitos";
import Vacaciones from "./vacaciones/Vacaciones";
import Configuracion from "./configuracion/Configuracion";
import ListaEmpleados from "./legajos/ListaEmpleados";
import ReportesLegajos from "./legajos/reportes/ReportesLegajos";
import LegajoEmpleado from "./legajos/LegajoEmpleado";
import EdicionLegajo from "./legajos/EdicionLegajo";
import HorarioEmpleado from "./legajos/HorarioEmpleado";
import RequisitosEmpleado from "./legajos/RequisitosEmpleado";
import SancionesEmpleado from "./legajos/SancionesEmpleado";
import VacacionesEmpleado from "./legajos/VacacionesEmpleado";
import StockExistencias from "./stock/StockExistencias";
import StockProveedores from "./stock/StockProveedores";
import StockSectores from "./stock/StockSectores";
import StockDestinos from "./stock/StockDestinos";
import StockProductos from "./stock/StockProductos";
import ConfiguracionParametros from "./configuracion/ConfiguracionParametros";
import ConfiguracionUsuarios from "./configuracion/ConfiguracionUsuarios";
import RegistroAuditoria from "./configuracion/RegistroAuditoria";
import StockConsultaMovimientos from "./stock/StockConsultaMovimientos";
import StockHistorialProveedor from "./stock/StockHistorialProveedor";
import config from "./config";
import Trazabilidad from "./trazabilidad/Trazabilidad";
import TrazabilidadDetalle from "./trazabilidad/TrazabilidadDetalle";

createRoot(document.getElementById('root')!).render(
    <React.StrictMode>
        <Provider store={store}>
            <BrowserRouter basename={config.url_base}>
                <Routes>
                    <Route path='/' element={<App/>}>
                        <Route path='legajos'>
                            <Route index element={<ListaEmpleados/>}/>
                            <Route path='reportes'>
                                <Route index element={<ReportesLegajos/>}/>
                                <Route path=':reporte' element={<ReportesLegajos/>}/>
                            </Route>
                            <Route path=':idEmpleado'>
                                <Route index element={<LegajoEmpleado/>}/>
                                <Route path='editar' element={<EdicionLegajo/>}/>
                                <Route path='horario' element={<HorarioEmpleado/>}/>
                                <Route path='requisitos' element={<RequisitosEmpleado/>}/>
                                <Route path='vacaciones' element={<VacacionesEmpleado/>}/>
                                <Route path='sanciones' element={<SancionesEmpleado/>}/>
                            </Route>
                        </Route>
                        <Route path='requisitos' element={<Requisitos/>}/>
                        <Route path='vacaciones' element={<Vacaciones/>}/>
                        <Route path='stock'>
                            <Route index element={<StockExistencias/>}/>
                            <Route path='proveedores'>
                                <Route index element={<StockProveedores/>}/>
                                <Route path=':idProveedor/historial' element={<StockHistorialProveedor/>}/>
                            </Route>
                            <Route path='sectores'>
                                <Route index element={<StockSectores/>}/>
                                <Route path=':idSector/movimientos' element={<StockConsultaMovimientos/>}/>
                            </Route>
                            <Route path='destinos'>
                                <Route index element={<StockDestinos/>}/>
                                <Route path=':idDestino/movimientos' element={<StockConsultaMovimientos/>}/>
                            </Route>
                            <Route path='productos' element={<StockProductos/>}/>
                            <Route path=':idProducto/movimientos' element={<StockConsultaMovimientos/>}/>
                        </Route>
                        <Route path='trazabilidad'>
                            <Route index element={<Trazabilidad/>}/>
                            <Route path=':idTrazabilidad'>
                                <Route index element={<TrazabilidadDetalle/>}/>
                            </Route>
                        </Route>
                        <Route path='configuracion' element={<Configuracion/>}>
                            <Route path='tablas' element={<ConfiguracionParametros/>}>
                                <Route path=':tabla' element={<ConfiguracionParametros/>}/>
                            </Route>
                            <Route path='usuarios' element={<ConfiguracionUsuarios/>}/>
                            <Route path='auditoria' element={<RegistroAuditoria/>}/>
                        </Route>
                        <Route path='*' element={<Navigate to='../' replace/>}/>
                    </Route>
                </Routes>
            </BrowserRouter>
        </Provider>
    </React.StrictMode>
);
