import React, {useEffect, useState} from "react";
import Boton from "../controles/Boton";
import ToolbarBtn from "../controles/ToolbarBtn";
import TarjetaCarga from "../controles/TarjetaCarga";
import CheckEstado from "../controles/CheckEstado";
import InputSelect, {OpcionSelect} from "../controles/InputSelect";
import Modal from "../controles/Modal";
import TarjetaSeccion from "../controles/TarjetaSeccion";
import InputTexto from "../controles/InputTexto";
import ModalBotones from "../controles/ModalBotones";
import TrInfo from "../controles/TrInfo";
import {useNavigate} from "react-router-dom";
import BarraSuperior from "../controles/BarraSuperior";
import CuerpoPrincipal from "../controles/CuerpoPrincipal";
import {useGsBackend} from "../funcionesApi";

type Destino = {
    id: number,
    descripcion: string,
    observaciones: string,
    habilitado: boolean,
    eliminable: boolean
}

type InfoDestinos = {
    destinos: Array<Destino>
}

const StockDestinos: React.FC = () => {
    const [datos, setDatos] = useState<InfoDestinos | undefined>(undefined)
    const [editando, setEditando] = useState<number | undefined>(undefined)
    const [editDescripcion, setEditDescripcion] = useState<string>('')
    const [editObservaciones, setEditObservaciones] = useState<string>('')
    const [editHabilitado, setEditHabilitado] = useState<number>(1)
    const [eliminable, setEliminable] = useState<boolean>(true)
    const [validaDescripcion, setValidaDescripcion] = useState<boolean>(true)
    const navigate = useNavigate()

    const {pedidoJson, errorCarga, actualizar, cargando} = useGsBackend()

    useEffect(() => {
        pedidoJson({method: 'get', url: 'stock/destinos', ok: setDatos, sinErrorDispatch: true})
    }, [pedidoJson])

    const opcionesHabilitado = [
        {id: 1, descripcion: 'Sí'},
        {id: 2, descripcion: 'No'}
    ] as Array<OpcionSelect>

    const editarHandler = (id?: number): void => {
        if (datos === undefined) return
        if (id === undefined) {
            setEditando(undefined)
        } else if (id === 0) {
            setEditDescripcion('')
            setEditObservaciones('')
            setEditHabilitado(1)
            setValidaDescripcion(true)
            setEditando(0)
        } else {
            const item = datos?.destinos.filter(d => d.id === id)[0]
            if (item === undefined) return
            setEditDescripcion(item.descripcion)
            setEditObservaciones(item.observaciones)
            setEditHabilitado(item.habilitado ? 1 : 2)
            setValidaDescripcion(true)
            setEliminable(item.eliminable)
            setEditando(id)
        }
    }

    const guardarHandler = async (eliminar?: boolean): Promise<void> => {
        if (editando === undefined) return

        const vld = editDescripcion !== ''
        setValidaDescripcion(vld)
        if (!vld) return

        const fd = new FormData()
        fd.append('accion', editando === 0 ? 'nuevo' : eliminar ? 'eliminar' : 'editar')
        fd.append('id', editando.toString())
        fd.append('descripcion', editDescripcion)
        fd.append('observaciones', editObservaciones)
        fd.append('habilitado', editHabilitado === 1 ? '1' : '0')

        pedidoJson({
            url: 'stock/destinos', method: 'post', body: fd,
            ok: () => {
                editarHandler(undefined)
                actualizar()
            }
        })
    }

    if (datos === undefined) {
        return (
            <TarjetaCarga error={errorCarga} onReintentar={actualizar}
                          onVolver={() => navigate('../../')}/>
        )
    } else {
        return (
            <div>
                <BarraSuperior>
                    <ToolbarBtn>
                        <Boton toolbar titulo='Volver' icono='fas fa-arrow-left' className='btn-secondary'
                               onClick={() => navigate('../../')}/>
                        <Boton toolbar titulo='Alta de Destino' icono='fas fa-plus'
                               onClick={() => editarHandler(0)}/>
                    </ToolbarBtn>
                </BarraSuperior>
                <CuerpoPrincipal sinEspacio>
                    <h4>
                        <i className='fas fa-square-check text-primary me-2'/>
                        Destinos
                    </h4>
                </CuerpoPrincipal>
                <table className='table table-responsive table-striped table-hover'>
                    <thead>
                    <tr>
                        <th style={{width: '40%'}} className='ps-3'>Descripción</th>
                        <th style={{width: '40%'}}>Observaciones</th>
                        <th style={{width: '20%'}}>Habilitado</th>
                        <th>Acciones</th>
                    </tr>
                    </thead>
                    <tbody>
                    {datos.destinos.length ? datos.destinos.map((d, k) => (
                        <tr key={k} style={{cursor: 'pointer'}} onClick={() => editarHandler(d.id)}>
                            <td className='fw-bold ps-3'>{d.descripcion}</td>
                            <td>{d.observaciones}</td>
                            <td><CheckEstado estado={d.habilitado} textosino/></td>
                            <td className='text-nowrap'>
                                <Boton chico toolbar titulo='Movimientos' icono='fas fa-clipboard-list'
                                       onClick={e => {
                                           e.stopPropagation()
                                           navigate(`${d.id}/movimientos`)
                                       }}/>
                            </td>
                        </tr>
                    )) : (
                        <TrInfo colSpan={4} texto='No se encuentran destinos para mostrar'/>
                    )}
                    </tbody>
                </table>
                {editando !== undefined && (
                    <Modal className='w-50'>
                        <h4>{editando === 0 ? 'Alta de Destino' : 'Editar Destino'}</h4>
                        <TarjetaSeccion>
                            <div className='row mb-2'>
                                <div className='col-md-3 pt-1'>Descripción</div>
                                <div className='col-md-9'>
                                    <InputTexto value={editDescripcion} onChange={setEditDescripcion}
                                                autoFocus invalido={!validaDescripcion}/>
                                </div>
                            </div>
                            <div className='row mb-2'>
                                <div className='col-md-3 pt-1'>Observaciones</div>
                                <div className='col-md-9'>
                                    <InputTexto value={editObservaciones} onChange={setEditObservaciones}/>
                                </div>
                            </div>
                            <div className='row mb-2'>
                                <div className='col-md-3 pt-1'>Habilitado</div>
                                <div className='col-md-9'>
                                    <InputSelect value={editHabilitado} onChange={setEditHabilitado}
                                                 opciones={opcionesHabilitado}/>
                                </div>
                            </div>
                        </TarjetaSeccion>
                        <ModalBotones editando={editando > 0} soloEditar={editando > 0 && !eliminable}
                                      guardando={cargando}
                                      onCancelar={() => editarHandler(undefined)}
                                      onEliminar={() => guardarHandler(true).catch(err => alert(err))}
                                      onGuardar={guardarHandler}/>
                    </Modal>
                )}
            </div>
        )
    }
}

export default StockDestinos