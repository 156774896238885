import EstadoMensaje from "../controles/EstadoMensaje";
import React from "react";
import {VacacionesLegajoItem} from "./interfacesLegajo";

type VacacionesLegajoProps = {
    vacaciones: Array<VacacionesLegajoItem>
}

const VacacionesLegajo: React.FC<VacacionesLegajoProps> = ({vacaciones}) => {
    if (!vacaciones || vacaciones.length === 0) {
        return <EstadoMensaje estado={true} mensajeOk='No hay vacaciones próximas'/>
    } else {
        return (
            <>
                <table className='table table-bordered table-sm table-striped small'>
                    <thead>
                    <tr>
                        <th>Estado</th>
                        <th>Fecha Desde</th>
                        <th>Fecha Hasta</th>
                        <th>Días</th>
                    </tr>
                    </thead>
                    <tbody>
                    {vacaciones.map((v: VacacionesLegajoItem, i: number) => (
                        <tr key={i}>
                            <td>
                                {v.actual ? (
                                    <span><i className='fas fa-plane me-1'/> En curso</span>
                                ) : (
                                    <span className='text-muted'>Agendada</span>
                                )}
                            </td>
                            <td>{v.fecha_desde}</td>
                            <td>{v.fecha_hasta}</td>
                            <td className='text-end'>{v.dias}</td>
                        </tr>
                    ))}
                    </tbody>
                </table>
            </>
        )
    }
}

export default VacacionesLegajo
