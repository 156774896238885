import React from "react";
import {DatosHorarioSemanal} from "./interfacesLegajo";

type HorarioSemanalProps = {
    horariosemanal: Array<DatosHorarioSemanal>
}

const HorarioSemanal: React.FC<HorarioSemanalProps> = ({horariosemanal}) => {
    let salidaHorarios = []
    if (horariosemanal !== undefined && horariosemanal.length === 7) {
        for (let i = 0; i < 7; i++) {
            const dato = horariosemanal[i]
            const estilo = (dato.inconsistencia ? 'table-warning' :
                (i === 0 || i === 6 ? 'bg-light' : ''))
            salidaHorarios.push(
                <td style={{'width': '14%'}} className={'text-center ' + estilo} key={i}>
                    {dato.horario !== undefined && dato.horario !== '' ? dato.horario
                        : <span className='text-muted' key={i}>-</span>
                    }
                </td>
            )
        }
    } else {
        salidaHorarios.push(<td key={0} colSpan={7} className='text-muted text-center'>-</td>)
    }

    return (
        <table className='table table-sm table-bordered small'>
            <thead>
            <tr>
                <th className='text-center bg-light'>Do</th>
                <th className='text-center'>Lu</th>
                <th className='text-center'>Ma</th>
                <th className='text-center'>Mi</th>
                <th className='text-center'>Ju</th>
                <th className='text-center'>Vi</th>
                <th className='text-center bg-light'>Sa</th>
            </tr>
            </thead>
            <tbody>
            <tr>
                {salidaHorarios}
            </tr>
            </tbody>
        </table>
    )
}

export default HorarioSemanal
