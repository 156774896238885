import {useCallback, useRef, useState} from "react";
import config from "./config";
import {authHeader, errorApi, verificarAutorizacion} from "./autorizacion";
import {notificaResultado} from "./controles/Notificacion";

export interface GsRequestParams {
    url: string,
    method?: 'get' | 'post'
    ok?: (res: any) => void,
    err?: (res: any) => void,
    finally?: () => void
    sinHeaders?: boolean,
    sinErrorDispatch?: boolean,
    sinNotificacion?: boolean
    body?: FormData
}

export interface GsBackendInterface {
    cargando: boolean,
    pedidoJson: (request: GsRequestParams) => void,
    pedidoBlob: (request: GsRequestParams) => void,
    actualizar: () => void,
    errorCarga: string | undefined
}

export const useGsBackend = () => {
    const [cargando, setCargando] = useState<boolean>(false)
    const [errorCarga, setErrorCarga] = useState<string | undefined>(undefined)
    const ultimoRequest = useRef<GsRequestParams | null>(null)

    const pedidoJson = useCallback((request: GsRequestParams): void => {
        if (request.method !== 'post') ultimoRequest.current = request

        setCargando(true)
        fetch(config.api + request.url, {
            method: request.method || 'get',
            headers: request.sinHeaders ? undefined : authHeader(),
            body: request.body
        })
            .then(res => verificarAutorizacion(res))
            .then(res => res.json())
            .then(res => {
                if (request.method === 'post' && !request.sinNotificacion) {
                    if (notificaResultado(res)) {
                        if (request.ok !== undefined) request.ok(res)
                    }
                } else {
                    if (request.ok !== undefined) request.ok(res)
                }
            })
            .catch(err => {
                setErrorCarga(err.toString())
                errorApi(err, request.sinErrorDispatch)
                if (request.err !== undefined) request.err(err)
            })
            .finally(() => {
                setCargando(false)
                if (request.finally !== undefined) request.finally()
            })
    }, [])

    const pedidoBlob = useCallback((request: GsRequestParams): void => {
        setCargando(true)
        fetch(config.api + request.url, {
            method: request.method || 'get',
            headers: request.sinHeaders ? undefined : authHeader(),
            body: request.body
        })
            .then(res => verificarAutorizacion(res))
            .then(res => res.blob())
            .then(res => {
                if (request.ok !== undefined) request.ok(res)
            })
            .catch(err => {
                setErrorCarga(err.toString())
                errorApi(err, request.sinErrorDispatch)
            })
            .finally(() => {
                setCargando(false)
                if (request.finally !== undefined) request.finally()
            })
    }, [])

    const actualizar = () => {
        if (ultimoRequest.current !== null) pedidoJson(ultimoRequest.current)
    }

    return {cargando, pedidoJson, pedidoBlob, actualizar, errorCarga} as GsBackendInterface
}
