import React, {useEffect, useState} from "react";
import Modal from "../controles/Modal";
import InputTexto from "../controles/InputTexto";
import ModalBotones from "../controles/ModalBotones";
import Boton from "../controles/Boton";
import {useAppSelector} from "../redux/hooks";
import TrCarga from "../controles/TrCarga";
import TrInfo from "../controles/TrInfo";
import {useGsBackend} from "../funcionesApi";

type TipoDeRequisitos = {
    id: number,
    descripcion: string
}

const TiposDeRequisitos: React.FC = () => {
    const [datos, setDatos] = useState<Array<TipoDeRequisitos>>([] as Array<TipoDeRequisitos>)
    const [editando, setEditando] = useState<number>(0)
    const [tituloEdicion, setTituloEdicion] = useState<string>('')
    const [editNombre, setEditNombre] = useState<string>('')
    const [editValida, setEditValida] = useState<boolean>(true)
    const edita = useAppSelector(state => state.auth.edicion)

    const {pedidoJson, cargando, actualizar} = useGsBackend()
    useEffect(() => {
        pedidoJson({url: 'requisitos/tiposderequisitos', ok: res => setDatos(res.tiposderequisitos)})
    }, [pedidoJson])

    const editarHandler = (id: number): void => {
        const dato = datos.filter(d => d.id === id)[0]
        setEditNombre(dato.descripcion)
        setEditValida(true)
        setTituloEdicion('Editar Tipo de Requisito')
        setEditando(id)
    }

    const nuevoHandler = (): void => {
        setEditNombre('')
        setEditValida(true)
        setTituloEdicion('Nuevo Tipo de Requisito')
        setEditando(-1)
    }

    const guardarHandler = async (eliminar: boolean = false): Promise<void> => {
        const valido = editNombre !== ''
        setEditValida(valido)
        if (!valido) return

        const fd = new FormData()
        fd.append('accion', eliminar ? 'eliminar' : (editando === -1 ? 'nuevo' : 'editar'))
        fd.append('id', editando.toString())
        fd.append('nombre', editNombre)

        pedidoJson({
            url: 'requisitos/tiposderequisitos', method: 'post', body: fd,
            ok: () => {
                setEditando(0)
                actualizar()
            }
        })
    }

    return (
        <>
            <h4>Tipos de Requisitos</h4>
            <div className='border rounded-2'>
                <table className={'table table-striped mb-0' + (edita ? ' table-hover' : '')}>
                    <thead>
                    <tr>
                        <th>Descripción</th>
                    </tr>
                    </thead>
                    <tbody>
                    {datos.length ? datos.map((r, i) => (
                        <tr className={edita ? 'cursorpointer' : ''} onClick={() => {
                            if (edita) editarHandler(r.id)
                        }} key={i}>
                            <td>{r.descripcion}</td>
                        </tr>
                    )) : cargando ? (
                        <TrCarga colSpan={1}/>
                    ) : (
                        <TrInfo colSpan={1} texto='Sin tipos de requisito para mostrar'/>
                    )}
                    </tbody>
                    {edita && (
                        <thead>
                        <tr>
                            <th>
                                <Boton chico titulo='Nuevo' icono='fas fa-plus' onClick={nuevoHandler}
                                       disabled={cargando}/>
                            </th>
                        </tr>
                        </thead>
                    )}
                </table>
            </div>
            {editando !== 0 && (
                <Modal className='w-50'>
                    <h4>{tituloEdicion}</h4>
                    <InputTexto chico titulo='Tipo de Requisito' value={editNombre}
                                onChange={setEditNombre} className='mb-2'
                                invalido={!editValida} autoFocus/>
                    <ModalBotones editando={editando !== -1} guardando={cargando}
                                  onCancelar={() => setEditando(0)}
                                  onGuardar={() => guardarHandler()}
                                  onEliminar={() => guardarHandler(true)}/>
                </Modal>
            )}
        </>
    )
}

export default TiposDeRequisitos
