import React from 'react'
import Modulos from '../modulos'
import {useAppSelector} from "../redux/hooks";
import infoVersion from '../infoVersion.json'
import BotonModulo from "./BotonModulo";

type NavegacionProps = {
    pantalla: string,
    onCambio: (arg: string) => void
}

const Navegacion: React.FC<NavegacionProps> = ({pantalla, onCambio}) => {
    const accesos: Array<string> = useAppSelector(state => state.auth.accesos) || [] as Array<string>

    return (
        <div className='pt-4 sticky-top'>
            <div>
                <div className='btn-group-vertical w-100'>
                    {Modulos
                        .filter(mod => accesos.includes(mod.clave))
                        .map((mod, k) => (
                            <BotonModulo key={k} mod={mod} pantalla={pantalla} onCambio={onCambio}/>
                        ))}
                </div>
            </div>
            <div>
                <div className='text-muted small text-center mt-3 text-nowrap small'>
                    {infoVersion.mayor}.{infoVersion.menor}.{infoVersion.revision}
                </div>
            </div>
        </div>
    )
}

export default Navegacion
