import React, {useEffect, useState} from "react";
import Modal from "../controles/Modal";
import ModalBotones from "../controles/ModalBotones";
import TarjetaSeccion from "../controles/TarjetaSeccion";
import InputTexto from "../controles/InputTexto";
import InputFecha from "../controles/InputFecha";
import InputSelect, {OpcionSelect} from "../controles/InputSelect";
import IconoCarga from "../controles/IconoCarga";
import {useGsBackend} from "../funcionesApi";

type Props = {
    onAlta: (arg: number) => void,
    onCancelar: () => void
}

type ValidacionNuevoLegajo = {
    apellido_ok: boolean,
    nombre_ok: boolean,
    cuil_ok: boolean,
    fecha_nacimiento_ok: boolean,
    ubicacion_ok: boolean,
    categoria_ok: boolean,
    puesto_ok: boolean,
    fecha_inicio_ok: boolean,
    nro_legajo_ok: boolean
}


const AltaEmpleado: React.FC<Props> = ({onAlta, onCancelar}) => {
    const [datosOk, setDatosOk] = useState<boolean>(false)
    const [ubicaciones, setUbicaciones] = useState<Array<OpcionSelect>>([] as Array<OpcionSelect>)
    const [categorias, setCategorias] = useState<Array<OpcionSelect>>([] as Array<OpcionSelect>)
    const [puestos, setPuestos] = useState<Array<OpcionSelect>>([] as Array<OpcionSelect>)
    const [apellido, setApellido] = useState<string>('')
    const [nombre, setNombre] = useState<string>('')
    const [cuil, setCuil] = useState<string>('')
    const [fechaNacimiento, setFechaNacimiento] = useState<string>('')
    const [ubicacion, setUbicacion] = useState<number>(0)
    const [categoria, setCategoria] = useState<number>(0)
    const [puesto, setPuesto] = useState<number>(0)
    const [fechaInicio, setFechaInicio] = useState<string>('')
    const [nroLegajo, setNroLegajo] = useState<string>('')
    const [validacion, setValidacion] = useState<ValidacionNuevoLegajo>({
        apellido_ok: true,
        nombre_ok: true,
        cuil_ok: true,
        fecha_nacimiento_ok: true,
        ubicacion_ok: true,
        categoria_ok: true,
        puesto_ok: true,
        fecha_inicio_ok: true,
        nro_legajo_ok: true
    } as ValidacionNuevoLegajo)

    const {pedidoJson, cargando} = useGsBackend()
    useEffect(() => {
        setDatosOk(false)
        pedidoJson({
            url: 'legajos/alta', ok: res => {
                setUbicaciones(res.ubicaciones)
                setCategorias(res.categorias)
                setPuestos(res.puestos)
            }
        })
        setDatosOk(true)
    }, [pedidoJson])

    const guardarHandler = async (): Promise<void> => {
        // Validaciones para dar de alta el nuevo legajo
        const v: ValidacionNuevoLegajo = {
            apellido_ok: apellido !== '',
            nombre_ok: nombre !== '',
            cuil_ok: cuil !== '',
            fecha_nacimiento_ok: fechaNacimiento !== '',
            ubicacion_ok: ubicacion !== 0,
            categoria_ok: categoria !== 0,
            puesto_ok: puesto !== 0,
            fecha_inicio_ok: fechaInicio !== '',
            nro_legajo_ok: nroLegajo !== ''
        }
        setValidacion(v)
        if (!v.apellido_ok || !v.nombre_ok || !v.cuil_ok || !v.fecha_nacimiento_ok || !v.ubicacion_ok ||
            !v.categoria_ok || !v.puesto_ok || !v.fecha_inicio_ok || !v.nro_legajo_ok) return

        const fd = new FormData()
        fd.append('accion', 'nuevo')
        fd.append('apellido', apellido)
        fd.append('nombre', nombre)
        fd.append('cuil', cuil)
        fd.append('fecha_nacimiento', fechaNacimiento)
        fd.append('ubicacion', ubicacion.toString())
        fd.append('categoria', categoria.toString())
        fd.append('puesto', puesto.toString())
        fd.append('fecha_inicio', fechaInicio)
        fd.append('nro_legajo', nroLegajo)

        pedidoJson({
            url: 'legajos/alta', method: 'post', body: fd,
            ok: res => {
                const id = res.nuevo_id
                if (id !== undefined) {
                    onAlta(id)
                } else {
                    alert('No se recibió el nuevo Id')
                }
            }
        })
    }

    return (
        <Modal className='w-75'>
            <h4>Alta de Empleado</h4>
            {!datosOk ? <IconoCarga/> : <>
                <div className='row mb-2'>
                    <div className='col-auto pe-0'><i className='fas fa-info-circle text-info'/></div>
                    <div className='col'>
                        <strong>Se dará de alta un nuevo legajo en el sistema.</strong><br/>
                        Complete a continuación los campos requeridos para dar de alta al Empleado; luego podrá
                        acceder a la información y a la configuración completa desde su legajo.
                    </div>
                </div>
                <TarjetaSeccion>
                    <div className='row'>
                        <div className='col-sm-6'>
                            <InputTexto titulado titulo='Número de Legajo' value={nroLegajo} onChange={setNroLegajo}
                                        invalido={!validacion.nro_legajo_ok} autoFocus/>
                        </div>
                        <div className='col-sm-6'>
                            <InputSelect titulado titulo='Ubicación' value={ubicacion} onChange={setUbicacion}
                                         opciones={ubicaciones} invalido={!validacion.ubicacion_ok}/>
                        </div>
                    </div>
                    <div className='row'>
                        <div className='col-sm-6'>
                            <InputSelect titulado titulo='Categoría' value={categoria} onChange={setCategoria}
                                         opciones={categorias} invalido={!validacion.categoria_ok}/>
                        </div>
                        <div className='col-sm-6'>
                            <InputSelect titulado titulo='Puesto' value={puesto} onChange={setPuesto}
                                         opciones={puestos} invalido={!validacion.puesto_ok}/>
                        </div>
                    </div>
                </TarjetaSeccion>
                <TarjetaSeccion>
                    <div className='row'>
                        <div className='col-sm-4'>
                            <InputTexto titulado titulo='Apellido' value={apellido} onChange={setApellido}
                                        invalido={!validacion.apellido_ok}/>
                        </div>
                        <div className='col-sm-4'>
                            <InputTexto titulado titulo='Nombre' value={nombre} onChange={setNombre}
                                        invalido={!validacion.nombre_ok}/>
                        </div>
                        <div className='col-sm-4'>
                            <InputTexto titulado titulo='CUIL' value={cuil} onChange={setCuil}
                                        invalido={!validacion.cuil_ok}/>
                        </div>
                    </div>
                    <div className='row'>
                        <div className='col-sm-6'>
                            <InputFecha titulado titulo='Fecha de Nacimiento' value={fechaNacimiento}
                                        onChange={setFechaNacimiento} invalido={!validacion.fecha_nacimiento_ok}/>
                        </div>
                        <div className='col-sm-6'>
                            <InputFecha titulado titulo='Fecha de Inicio' value={fechaInicio} onChange={setFechaInicio}
                                        invalido={!validacion.fecha_inicio_ok}/>
                        </div>
                    </div>
                </TarjetaSeccion>
            </>}
            <ModalBotones onGuardar={() => guardarHandler()} guardando={cargando}
                          onCancelar={() => onCancelar()}/>
        </Modal>
    )
}

export default AltaEmpleado
