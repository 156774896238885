type Estado = {
    id: number,
    descripcion: string,
    color: string
}

const estadosTrazabilidad: Array<Estado> = [
    {id: 1, descripcion: 'En Curso', color: 'success'},
    {id: 2, descripcion: 'Finalizado', color: 'success'},
    {id: 3, descripcion: 'Demorado', color: 'danger'},
    {id: 4, descripcion: 'Cancelado', color: 'secondary'},
    {id: 5, descripcion: 'En Revisión', color: 'warning'}
]

export default estadosTrazabilidad