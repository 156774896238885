import React from "react";
import './ThOrdenable.css'

export type OrdenTabla = {
    campo: string,
    ascendente: boolean
}

type Props = {
    titulo: string,
    campo: string,
    orden: OrdenTabla,
    onClick: (arg: OrdenTabla) => void,
    className?: string
}

const ThOrdenable: React.FC<Props> = ({titulo, campo, orden, onClick,
                                      className}) => {
    const icono = (): React.ReactNode => {
        if (orden.campo === campo) {
            return <i className={'fas text-info ms-2 ' + (orden.ascendente ? 'fa-caret-up' : 'fa-caret-down')}/>
        } else {
            return <></>
        }
    }

    const ordenHandler = (): void => {
        if (orden.campo === campo) {
            onClick({campo: orden.campo, ascendente: !orden.ascendente} as OrdenTabla)
        } else {
            onClick({campo: campo, ascendente: true} as OrdenTabla)
        }
    }

    return <th className={'text-nowrap thordenable ' + (className || '')}
               style={{cursor: 'pointer'}} onClick={ordenHandler}>
        {titulo}{icono()}
    </th>
}

export default ThOrdenable