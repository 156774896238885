import React, {useEffect} from "react";
import {useAppDispatch, useAppSelector} from "../redux/hooks";
import {setNotificacion} from "../redux/estadoReducer";
import store from "../redux/store";

export const notificaResultado = (arg: any): boolean => {
    if ('resultado' in arg && 'mensaje' in arg) {
        const mje: string = arg.mensaje === '' ? 'OK (Falta mensaje de respuesta)' : arg.mensaje
        if (arg.resultado === true) {
            store.dispatch(setNotificacion({tipo: 'success', mensaje: mje}))
            return true
        } else {
            store.dispatch(setNotificacion({tipo: 'error', mensaje: mje}))
            return false
        }
    } else {
        store.dispatch(setNotificacion({tipo: 'warning', mensaje: 'Formato de resultado JSON incorrecto'}))
        return false
    }
}

const Notificacion: React.FC = () => {
    const notificacion = useAppSelector(state => state.estado.notificacion)
    const dispatch = useAppDispatch()
    const tiempoNotificaciones = 2000

    useEffect(() => {
        const timeout = setTimeout(() => dispatch(setNotificacion(undefined)), tiempoNotificaciones)
        return () => clearTimeout(timeout)
    }, [dispatch, notificacion])

    if (notificacion === undefined) {
        return <React.Fragment/>
    }

    const iconoNotificacion = (): string => {
        switch (notificacion.tipo) {
            case 'success':
                return 'fa-check-circle text-success'
            case 'warning':
                return 'fa-exclamation-triangle text-warning'
            case 'error':
                return 'fa-times-circle text-danger'
            case 'info':
                return 'fa-info-circle text-info'
        }
    }

    return (
        <div className='position-fixed bottom-0 start-50 translate-middle' style={{zIndex: 99999}}>
            <div className='p-3 bg-dark shadow rounded-2 text-light mb-3'>
                <i className={`fas ${iconoNotificacion()} me-2 fa-bounce`}/>
                {notificacion.mensaje}
            </div>
        </div>
    )
}
export default Notificacion