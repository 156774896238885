import React from "react";

type Props = {
    titulo?: string
}

const BotonCarga: React.FC<Props> = ({titulo}) => {
    return <button type='button' className='btn btn-light btn-sm' disabled>
        <span className='spinner-border spinner-border-sm me-2 text-primary'/>
        {titulo === undefined ? 'Cargando...' : titulo}
    </button>
}

export default BotonCarga