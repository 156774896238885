import {AuthState, setDeslogueo} from "./redux/authReducer";
import store from "./redux/store";
import {setErrorSistema} from "./redux/estadoReducer";

export const authHeader = (): HeadersInit => {
    const [usuario, token] = authToken()
    const auth = 'Basic ' + btoa(`${usuario}:${token}`)
    return new Headers({
        'Authorization': auth
    })
}

export const authToken = (): [string, string] => {
    const auth: AuthState = store.getState().auth
    return [auth.usuario || '', auth.token || '']
}

export const verificarAutorizacion = (res: Response): Response => {
    if (res.status === 401) {
        throw new Error('NOAUTH')
    } else {
        return res
    }
}

export const errorApi = (err: Error, sinAlerta?: boolean): boolean => {
    if (err.message === 'NOAUTH') {
        store.dispatch(setDeslogueo())
        return true
    } else {
        if (!sinAlerta) store.dispatch(setErrorSistema(err.message))
        return false
    }
}

export const verificarAcceso = (arg: string): boolean => {
    const accesos: Array<string> | undefined = store.getState().auth.accesos
    if (accesos === undefined) {
        return false
    } else {
        return accesos.includes(arg)
    }
}