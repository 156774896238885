import React from "react";
import Boton from "../controles/Boton";
import ToolbarBtn from "../controles/ToolbarBtn";
import ResumenLegajo from "./ResumenLegajo";
import {useNavigate, useParams} from "react-router-dom";
import BarraSuperior from "../controles/BarraSuperior";

const LegajoEmpleado: React.FC = () => {
    const navigate = useNavigate()
    const params = useParams()

    const idEmpleado = parseInt(params.idEmpleado || '0')

    // Pantalla principal del legajo del empleado
    return (
        <div>
            <BarraSuperior>
                <ToolbarBtn>
                    <Boton toolbar titulo='Volver' icono='fas fa-arrow-left' className='btn-secondary'
                           onClick={() => navigate('../../')}/>
                    <Boton toolbar titulo='Novedades, Horario y Fichadas' icono='fas fa-clock'
                           onClick={() => navigate('horario')}/>
                    <Boton toolbar titulo='Requisitos' icono='fas fa-list'
                           onClick={() => navigate('requisitos')}/>
                    <Boton toolbar titulo='Vacaciones' icono='fas fa-plane'
                           onClick={() => navigate('vacaciones')}/>
                    <Boton toolbar titulo='Sanciones' icono='fas fa-exclamation-triangle'
                           onClick={() => navigate('sanciones')}/>
                </ToolbarBtn>
            </BarraSuperior>
            <ResumenLegajo idEmpleado={idEmpleado}/>
        </div>
    )
}

export default LegajoEmpleado
