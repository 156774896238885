import React from "react";

type Props = {
    onChange: (arg: File | undefined) => void,
    invalido?: boolean,
    chico?: boolean
}

const InputArchivo: React.FC<Props> = ({onChange, invalido, chico}) => {
    const archivoHandler = (archivo: FileList | null) => {
        if (archivo !== null && archivo.length) {
            onChange(archivo[0])
        } else {
            onChange(undefined)
        }
    }

    return <input className={'form-control' + (invalido ? ' is-invalid' : '') + (chico ? ' form-control-sm' : '')}
                  type='file' onChange={e => archivoHandler(e.target.files)}/>
}

export default InputArchivo
