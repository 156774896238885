import React, {MutableRefObject, useRef} from "react";
import {useAppSelector} from "../redux/hooks";
import TituloVentana from "./TituloVentana";
import Navegacion from "./Navegacion";
import PantallaCargando from "../controles/PantallaCargando";
import {AuthState} from "../redux/authReducer";
import TituloPagina from "./TituloPagina";
import Modulos from "../modulos";
import ErrorDeslogueo from "../login/ErrorDeslogueo";
import ModalError from "../controles/ModalError";
import {Navigate, useLocation, useNavigate} from "react-router-dom";
import {verificarAcceso} from "../autorizacion";
import Notificacion from "../controles/Notificacion";

type Props = {
    outlet?: React.ReactElement
}

const PantallaPrincipal: React.FC<Props> = ({outlet}) => {
    const location = useLocation()
    const cargando = useAppSelector(state => state.estado.cargando)
    const errorSistema = useAppSelector(state => state.estado.error_sistema)
    const auth: AuthState = useAppSelector(state => state.auth)
    const modulosHabilitados = auth.accesos || [] as Array<string>
    const modulosFiltrados = Modulos.filter(m => modulosHabilitados.includes(m.clave))
    const pantallaInicial = modulosFiltrados.length ? modulosFiltrados[0].clave : ''
    const pantalla = location.pathname.split('/').slice(1)[0] || 'inicio'
    const navigate = useNavigate()

    const barraRef = useRef() as MutableRefObject<HTMLDivElement>

    // Si el nombre de la pantalla es inicio entonces redirigir a la pantalla inicial
    if (pantalla === 'inicio') {
        return <Navigate to={pantallaInicial} replace/>
    }

    // Verificar que no haya errores de asignación de módulos
    if (pantalla === '') return <ErrorDeslogueo
        mensaje='El usuario no cuenta con módulos habilitados. Contacte al Administrador del Sistema.'/>

    // Verificar autorización para la pantalla seleccionada
    if (!verificarAcceso(pantalla)) return <Navigate to='../' replace/>

    // Ajustar el título de la ventana según la función
    TituloVentana(auth.cliente || '', pantalla)

    // Pantalla principal
    return (
        <div className='d-flex min-vh-100'>
            <div className='px-2 pt-0 m-0 bg-dark' ref={barraRef}>
                <Navegacion pantalla={pantalla} onCambio={p => navigate(p)}/>
            </div>
            <div className='flex-grow-1 p-0 m-0'>
                <TituloPagina pagina={pantalla}/>
                {outlet}
            </div>
            {cargando && <PantallaCargando/>}
            {errorSistema && <ModalError/>}
            <Notificacion/>
        </div>
    )
}

export default PantallaPrincipal
