import React, {useEffect, useState} from "react";
import {useAppSelector} from "../redux/hooks";
import CheckEstado from "../controles/CheckEstado";
import Boton from "../controles/Boton";
import Modal from "../controles/Modal";
import ModalBotones from "../controles/ModalBotones";
import BotonCheck from "../controles/BotonCheck";
import TextoND from "../controles/TextoND";
import InputArchivo from "../controles/InputArchivo";
import {urlDescargaRequisito} from "../descargas/descargasLegajo";
import ToolbarBtn from "../controles/ToolbarBtn";
import TarjetaCarga from "../controles/TarjetaCarga";
import Tooltip, {InfoTooltip} from "../controles/Tooltip";
import {useNavigate, useParams} from "react-router-dom";
import BarraSuperior from "../controles/BarraSuperior";
import CuerpoPrincipal from "../controles/CuerpoPrincipal";
import {useGsBackend} from "../funcionesApi";
import {funcionDescarga} from "../descargas/descargas";

type Tipos = {
    id: number,
    descripcion: string
}

type DatosEmp = {
    apellido: string,
    nombre: string,
    tiposderequisitos: Array<Tipos>
}

type DatosReq = {
    id: number,
    id_requisito: number,
    descripcion: string,
    fecha_carga: string,
    usuario_carga: string,
    nombre_usuario_carga: string,
    notas_carga: string,
    nombre_archivo: string,
    mandatorio: boolean
}

const RequisitosEmpleado: React.FC = () => {
    const [empleado, setEmpleado] = useState<DatosEmp>({} as DatosEmp)
    const [requisitos, setRequisitos] = useState<Array<DatosReq>>([] as Array<DatosReq>)
    const [configurando, setConfigurando] = useState<boolean>(false)
    const [tiposEmpleado, setTiposEmpleado] = useState<Array<Tipos>>([] as Array<Tipos>)
    const [listaTipos, setListaTipos] = useState<Array<Tipos>>([] as Array<Tipos>)
    const [edicionRequisito, setEdicionRequisito] = useState<DatosReq | undefined>(undefined)
    const [notasCarga, setNotasCarga] = useState<string>('')
    const [archivo, setArchivo] = useState<File | undefined>(undefined)
    const [validoArchivo, setValidoArchivo] = useState<boolean>(true)
    const [infoTooltip, setInfoTooltip] = useState<InfoTooltip | undefined>(undefined)
    const edita = useAppSelector(state => state.auth.edicion)
    const params = useParams()
    const navigate = useNavigate()
    const numIdEmpleado = parseInt(params.idEmpleado || '0')

    const {pedidoJson, pedidoBlob, actualizar, errorCarga, cargando} = useGsBackend()
    useEffect(() => {
        pedidoJson({
            url: `legajos/requisitos/${numIdEmpleado}`, sinErrorDispatch: true,
            ok: res => {
                // Si no habia un id valido volver a Legajos
                if (res.empleado === undefined) navigate('../../')

                setEmpleado(res.empleado)
                setRequisitos(res.requisitos)
                setListaTipos(res.tiposderequisitos)
            }
        })
    }, [pedidoJson, navigate, numIdEmpleado])

    const configurarHandler = () => {
        const valor = tiposEmpleado.map(t => t.id).join(',')
        const fd = new FormData()
        fd.append('accion', 'tiposderequisitos')
        fd.append('tipos', valor)

        envioEdicion(fd)
    }

    const editarHandler = (idRequisito: number) => {
        const req = requisitos.filter(r => r.id_requisito === idRequisito)
        if (req.length === 1) {
            const infoReq = req[0]
            setNotasCarga(infoReq.notas_carga)
            setArchivo(undefined)
            setValidoArchivo(true)
            setEdicionRequisito(infoReq)
        }
    }

    const archivoHandler = (e: File | undefined): void => {
        if (e !== undefined) setValidoArchivo(true)
        setArchivo(e)
    }

    const guardarHandler = () => {
        if (edicionRequisito === undefined) return

        // Validacion de archivo
        if (archivo === undefined) {
            setValidoArchivo(false)
            return
        }

        const fd = new FormData()
        fd.append('accion', 'editar')
        fd.append('id_requisito', edicionRequisito.id_requisito.toString())
        fd.append('notas_carga', notasCarga)
        fd.append('archivo', archivo, archivo.name)

        envioEdicion(fd)
    }

    const envioEdicion = (fd: FormData): void => {
        pedidoJson({
            url: `legajos/requisitos/${numIdEmpleado}`, method: 'post', body: fd,
            ok: () => {
                setEdicionRequisito(undefined)
                setConfigurando(false)
                actualizar()
            }
        })
    }

    const abrirConfiguracion = (): void => {
        setTiposEmpleado(empleado.tiposderequisitos)
        setConfigurando(true)
    }

    const toggleTipo = (id: number): void => {
        if (tiposEmpleado.filter(e => e.id === id).length === 0) {
            const nuevo = listaTipos.filter(t => t.id === id)[0]
            setTiposEmpleado([...tiposEmpleado, nuevo])
        } else {
            setTiposEmpleado(tiposEmpleado.filter(e => e.id !== id))
        }
    }

    const cargaTooltipRequisitos = (idReq: number): void => {
        setInfoTooltip({
            urlPreview: `previews?tipo=requisitos&id_empleado=${numIdEmpleado}&id_requisito=${idReq}`
        })
    }

    const InfoEstado = (r: DatosReq) => {
        if (r.mandatorio && r.usuario_carga === '') {
            return (
                <div className='text-danger mb-2'>
                    <i className='fas fa-exclamation-triangle me-2'/>
                    Requisito mandatorio no informado en el Sistema
                </div>
            )
        } else if (!r.mandatorio && r.usuario_carga === '') {
            return (
                <div className='text-info mb-2'>
                    <i className='fas fa-info-circle me-2'/>
                    Requisito no informado en el Sistema
                </div>
            )
        } else if (r.usuario_carga !== '') {
            return (
                <div className='text-success mb-2'>
                    <i className='fas fa-check-circle me-2'/>
                    Requisito informado en el Sistema
                </div>
            )
        } else {
            return <></>
        }
    }

    const notasHandler = (e: { target: { value: React.SetStateAction<string>; }; }) => {
        setNotasCarga(e.target.value)
    }

    const nombreCompleto = `${empleado.apellido}, ${empleado.nombre}`

    if (empleado.apellido !== undefined) {
        return (
            <div>
                <BarraSuperior>
                    <ToolbarBtn>
                        <Boton toolbar titulo='Volver al Legajo' icono='fas fa-arrow-left'
                               className='btn-secondary' onClick={() => navigate('../')}/>
                        {edita && <Boton toolbar titulo='Configurar' icono='fas fa-cog'
                                         onClick={abrirConfiguracion}/>}
                    </ToolbarBtn>
                </BarraSuperior>
                <CuerpoPrincipal>
                    <h4>
                        <strong>{nombreCompleto}</strong> - Requisitos del Empleado
                    </h4>
                    <div>
                        <span className='me-2'>Tipos de Requisitos:</span>
                        {
                            empleado.tiposderequisitos.length ?
                                empleado.tiposderequisitos.map((t, i) => <div
                                    className='badge bg-info me-2' key={i}>{t.descripcion}</div>)
                                :
                                <span className='text-muted'>(Ninguno)</span>
                        }
                    </div>
                </CuerpoPrincipal>
                <table className={'table table-responsive table-striped' + (edita ? ' table-hover' : '')}>
                    <thead>
                    <tr>
                        <th className='ps-3'>Requisito</th>
                        <th>Fecha de Carga</th>
                        <th>Usuario de Carga</th>
                        <th>Notas de Carga</th>
                        <th>Archivo</th>
                        <th>Mandatorio</th>
                    </tr>
                    </thead>
                    <tbody>
                    {requisitos.length ?
                        requisitos.map((r, i) => {
                            const requerido = r.mandatorio && r.usuario_carga === ''
                            return (
                                <tr className={edita ? 'cursorpointer' : ''}
                                    onClick={() => {
                                        if (edita) editarHandler(r.id_requisito)
                                    }} key={i}>
                                    <td className={(requerido ? 'text-danger' : '') + ' ps-3'}>
                                        {r.descripcion}
                                        {requerido && <i className='fas fa-exclamation-triangle ms-2'/>}
                                    </td>
                                    <td>{r.fecha_carga}</td>
                                    <td className='small'>
                                        <strong>{r.usuario_carga}</strong><br/>
                                        {r.nombre_usuario_carga}
                                    </td>
                                    <td>{r.notas_carga}</td>
                                    <td>
                                        {r.nombre_archivo !== '' ?
                                            <span className='enlacespan text-primary'
                                                  style={{'cursor': 'pointer'}}
                                                  onClick={e => {
                                                      e.stopPropagation()
                                                      pedidoBlob({
                                                          url: urlDescargaRequisito(numIdEmpleado, r.id_requisito),
                                                          ok: res => funcionDescarga(res, r.nombre_archivo)
                                                      })
                                                  }}
                                                  onMouseEnter={() => cargaTooltipRequisitos(r.id_requisito)}
                                                  onMouseLeave={() => setInfoTooltip(undefined)}>
                                              <i className='fas fa-file-download me-2'/>Descargar
                                              </span>
                                            : <TextoND/>}
                                    </td>
                                    <td><CheckEstado estado={r.mandatorio} textosino={true} times={true}/></td>
                                </tr>
                            )
                        })
                        : (
                            <tr>
                                <td colSpan={6} className='ps-3'>No hay ningún requisito para mostrar</td>
                            </tr>
                        )}
                    </tbody>
                </table>
                {configurando && (
                    <Modal className='w-50'>
                        <h5>Tipos de Requisitos para <strong>{nombreCompleto}</strong></h5>
                        {listaTipos.map((t, i) => (
                            <BotonCheck titulo={t.descripcion}
                                        value={tiposEmpleado.filter(e => e.id === t.id).length > 0}
                                        onClick={() => toggleTipo(t.id)} key={i}/>
                        ))}
                        <ModalBotones soloEditar guardando={cargando}
                                      onCancelar={() => setConfigurando(false)}
                                      onGuardar={() => configurarHandler()}/>
                    </Modal>
                )}
                {edicionRequisito !== undefined && (
                    <Modal className='w-50'>
                        <div className='row'>
                            <div className='col'>
                                <h4>{edicionRequisito.descripcion}</h4>
                            </div>
                            <div className='col-auto text-nowrap'>
                                {edicionRequisito.mandatorio ? (
                                    <span className='text-primary'>
                                        <i className='fas fa-exclamation-triangle'/> Mandatorio
                                    </span>
                                ) : (
                                    <span className='text-muted'>
                                        <i className='fas fa-info-circle'/> No mandatorio
                                    </span>
                                )}
                            </div>
                        </div>
                        {InfoEstado(edicionRequisito)}
                        {edicionRequisito.usuario_carga !== '' && (
                            <>
                                <div className='row mb-2'>
                                    <div className='col-sm-3 fw-bold'>Fecha de Carga</div>
                                    <div className='col-sm-9'>{edicionRequisito.fecha_carga}</div>
                                </div>
                                <div className='row mb-2'>
                                    <div className='col-sm-3 fw-bold'>Usuario</div>
                                    <div className='col-sm-9'>
                                        <strong>{edicionRequisito.usuario_carga}</strong>
                                        &nbsp;({edicionRequisito.nombre_usuario_carga})
                                    </div>
                                </div>
                                <div className='row mb-2'>
                                    <div className='col-sm-3 fw-bold'>Documento</div>
                                    <div className='col-sm-9'>
                                        <Boton titulo={edicionRequisito.nombre_archivo} icono='fas fa-download' chico
                                               onClick={() => {
                                                   pedidoBlob({
                                                       url: urlDescargaRequisito(numIdEmpleado,
                                                           edicionRequisito.id_requisito),
                                                       ok: res => funcionDescarga(res,
                                                           edicionRequisito.nombre_archivo)
                                                   })
                                               }}/>
                                    </div>
                                </div>
                                <hr/>
                            </>
                        )}
                        <div className='row mb-2'>
                            <div className='col-sm-3'>
                                <span className='fw-bold'>Notas de Carga</span><br/>
                                <small className='text-muted'>(Opcional)</small>
                            </div>
                            <div className='col-sm-9'>
                            <textarea className='form-control' rows={3} autoFocus
                                      value={notasCarga} onChange={notasHandler}/>
                            </div>
                        </div>
                        <div className='row mb-2'>
                            <div className='col-sm-3 pt-1'>
                                {edicionRequisito.usuario_carga === '' ? (
                                    <span className='fw-bold'>Documento</span>
                                ) : (
                                    <span className='fw-bold'>Nuevo documento</span>
                                )}
                            </div>
                            <div className='col-sm-9'>
                                <InputArchivo onChange={e => archivoHandler(e)} invalido={!validoArchivo}/>
                            </div>
                        </div>
                        <ModalBotones soloEditar guardando={cargando}
                                      onCancelar={() => setEdicionRequisito(undefined)}
                                      onGuardar={() => guardarHandler()}/>
                    </Modal>
                )}
                {infoTooltip !== undefined && <Tooltip datos={infoTooltip}/>}
            </div>
        )
    } else {
        return <TarjetaCarga error={errorCarga} onReintentar={actualizar} onVolver={() => navigate('../')}/>
    }
}

export default RequisitosEmpleado
