type Tablas = {
    codigo: string,
    titulo: string
}

export const tablasParametros: Array<Tablas> = [
    {codigo: 'bancos', titulo: 'Bancos'},
    {codigo: 'categorias', titulo: 'Categorías'},
    {codigo: 'convenios', titulo: 'Convenios'},
    {codigo: 'estadocivil', titulo: 'Estado Civil'},
    {codigo: 'modalidades', titulo: 'Modalidades'},
    {codigo: 'puestos', titulo: 'Puestos'},
    {codigo: 'tiponovedad', titulo: 'Tipos de Novedades'},
    {codigo: 'ubicaciones', titulo: 'Ubicaciones'}
]