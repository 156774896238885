import React, {useEffect, useState} from "react";
import Modal from "../controles/Modal";
import ModalBotones from "../controles/ModalBotones";
import BotonIcono from "../controles/BotonIcono";
import InputTexto from "../controles/InputTexto";
import TrInfo from "../controles/TrInfo";
import {useGsBackend} from "../funcionesApi";

type Props = {
    onCancelar: () => void
}

type TipoProductos = {
    id: number,
    descripcion: string,
    eliminable: boolean
}

type InfoEdicionTiposProductos = {
    tipos_productos: Array<TipoProductos>
}

const EdicionTiposProductos: React.FC<Props> = ({onCancelar}) => {
    const [datos, setDatos] = useState<InfoEdicionTiposProductos | undefined>(undefined)
    const [editando, setEditando] = useState<number | undefined>(undefined)
    const [editDescripcion, setEditDescripcion] = useState<string>('')
    const [validaDescripcion, setValidaDescripcion] = useState<boolean>(true)

    const {pedidoJson, actualizar} = useGsBackend()

    useEffect(() => {
        pedidoJson({url: 'stock/tiposdeproductos', method: 'get', ok: setDatos})
    }, [pedidoJson])

    const editarHandler = (id?: number): void => {
        if (id === undefined) {
            setEditando(undefined)
        } else if (id === 0) {
            setEditDescripcion('')
            setValidaDescripcion(true)
            setEditando(0)
        } else {
            const item = datos?.tipos_productos.filter(d => d.id === id)[0]
            if (item === undefined) return

            setEditDescripcion(item.descripcion)
            setValidaDescripcion(true)
            setEditando(item.id)
        }
    }

    const guardarHandler = async (id: number, eliminar?: boolean): Promise<void> => {
        // Validar item correcto
        if (datos === undefined) return

        // Validar descripcion completa
        const vld = editDescripcion !== ''
        setValidaDescripcion(vld)
        if (!vld) return

        const fd = new FormData()
        fd.append('accion', eliminar ? 'eliminar' : id === 0 ? 'nuevo' : 'editar')
        fd.append('id', id.toString())
        fd.append('descripcion', editDescripcion)

        pedidoJson({
            url: 'stock/tiposdeproductos', method: 'post', body: fd,
            ok: () => {
                editarHandler(undefined)
                actualizar()
            }
        })
    }

    if (datos === undefined) {
        return (
            <></>
        )
    } else {
        return (
            <Modal className='w-50'>
                <h4>Tipos de Productos</h4>
                <table className='table table-responsive table-sm table-striped table-bordered'>
                    <thead>
                    <tr>
                        <th>
                            <div className='row'>
                                <div className='col pt-1'>
                                    Descripción
                                </div>
                                <div className='col-auto'>
                                    <BotonIcono onClick={() => editarHandler(0)} icono='fas fa-plus'
                                                estilo='btn-primary'/>
                                </div>
                            </div>
                        </th>
                    </tr>
                    </thead>
                    <tbody>
                    {datos.tipos_productos.length ? datos.tipos_productos.map((t, k) => (
                        <tr key={k}>
                            <td>
                                <div className='row g-2'>
                                    <div className='col pt-1'>
                                        {editando === t.id ? (
                                            <InputTexto value={editDescripcion} onChange={setEditDescripcion}
                                                        chico autoFocus invalido={!validaDescripcion}/>
                                        ) : (
                                            <>{t.descripcion}</>
                                        )}
                                    </div>
                                    <div className='col-auto text-nowrap'>
                                        {editando === t.id ? (
                                            <>
                                                <BotonIcono onClick={() => guardarHandler(t.id)} icono='fas fa-check'
                                                            estilo='btn-success'/>
                                                {t.eliminable && <BotonIcono onClick={() =>
                                                    guardarHandler(t.id, true)}
                                                                             icono='fas fa-trash'
                                                                             estilo='btn-danger ms-2'/>}
                                            </>
                                        ) : (
                                            <BotonIcono onClick={() => editarHandler(t.id)} icono='fas fa-edit'
                                                        estilo='btn-primary'/>
                                        )}
                                    </div>
                                </div>
                            </td>
                        </tr>
                    )) : editando === undefined ? (
                        <TrInfo colSpan={1} texto='No hay tipos de productos para mostrar'/>
                    ) : (<></>)}
                    {editando === 0 && (
                        <tr>
                            <td>
                                <div className='row g-2'>
                                    <div className='col pt-1'>
                                        <InputTexto value={editDescripcion} onChange={setEditDescripcion}
                                                    chico autoFocus invalido={!validaDescripcion}/>
                                    </div>
                                    <div className='col-auto text-nowrap'>
                                        <BotonIcono onClick={() => guardarHandler(0)} icono='fas fa-check'
                                                    estilo='btn-success'/>
                                    </div>
                                </div>
                            </td>
                        </tr>
                    )}
                    </tbody>
                </table>
                <ModalBotones soloCancelar onCancelar={onCancelar}/>
            </Modal>
        )
    }
}

export default EdicionTiposProductos