import React, {useEffect, useState} from "react";
import Modal from "../controles/Modal";
import ModalBotonCerrar from "../controles/ModalBotonCerrar";
import TrCarga from "../controles/TrCarga";
import {useGsBackend} from "../funcionesApi";

type Props = {
    onCerrar: () => void
}

type OpcionDesa = {
    titulo: string,
    clave: string,
    valor: boolean
}

const OpcionesDesarrollo: React.FC<Props> = ({onCerrar}) => {
    const [datos, setDatos] = useState<Array<OpcionDesa> | undefined>(undefined)
    const [act, setAct] = useState<string | undefined>(undefined)

    const {pedidoJson, cargando} = useGsBackend()

    useEffect(() => {
        pedidoJson({url: 'desa/opciones', ok: res => setDatos(res.opciones)})
    }, [pedidoJson])

    const alternar = (clave: string): void => {
        const fd = new FormData()
        fd.append('clave', clave)

        setAct(clave)
        pedidoJson({
            url: 'desa/opciones', method: 'post', body: fd, sinNotificacion: true,
            ok: res => setDatos(res.opciones)
        })
    }

    return (
        <Modal className='w-25'>
            <h4>Opciones de Desarrollo</h4>
            <table className='table border'>
                <thead>
                <tr>
                    <th style={{width: '60%'}}>Opcion</th>
                    <th style={{width: '40%'}}>Valor</th>
                </tr>
                </thead>
                <tbody>
                {datos === undefined ? (
                    <TrCarga colSpan={2}/>
                ) : datos.map((o, k) => (
                    <tr key={k}>
                        <td>{o.titulo}</td>
                        <td>
                            <div className='form-check form-switch'>
                                <input className='form-check-input' type='checkbox' checked={o.valor}
                                       onChange={() => alternar(o.clave)}/>
                                {act === o.clave && cargando && (
                                    <span className='spinner-border spinner-border-sm ms-2 text-primary'/>
                                )}
                            </div>
                        </td>
                    </tr>
                ))}
                </tbody>
            </table>
            <ModalBotonCerrar onCerrar={onCerrar}/>
        </Modal>
    )
}

export default OpcionesDesarrollo