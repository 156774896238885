import EstadoMensaje from "../controles/EstadoMensaje";
import TextoND from "../controles/TextoND";
import CheckEstado from "../controles/CheckEstado";
import React, {useState} from "react";
import {RequisitoLegajoItem} from "./interfacesLegajo";
import {urlDescargaRequisito} from "../descargas/descargasLegajo";
import Tooltip, {InfoTooltip} from "../controles/Tooltip";
import {useGsBackend} from "../funcionesApi";
import {funcionDescarga} from "../descargas/descargas";

type RequisitosLegajoProps = {
    id_empleado: number,
    requisitos: Array<RequisitoLegajoItem>
}

const RequisitosLegajo: React.FC<RequisitosLegajoProps> = ({id_empleado, requisitos}) => {
    const [infoTooltip, setInfoTooltip] = useState<InfoTooltip | undefined>(undefined)
    const {pedidoBlob} = useGsBackend()

    const cargaTooltipRequisitos = (idReq: number): void => {
        setInfoTooltip({
            urlPreview: `previews?tipo=requisitos&id_empleado=${id_empleado}&id_requisito=${idReq}`
        })
    }

    if (!requisitos || requisitos.length === 0) {
        return <EstadoMensaje estado={true} mensajeOk='No hay requisitos exigibles'/>
    } else {
        return (
            <>
                <table className='table table-bordered table-sm table-striped small'>
                    <thead>
                    <tr>
                        <th>Requisito</th>
                        <th>Fecha de carga</th>
                        <th>Archivo</th>
                    </tr>
                    </thead>
                    <tbody>
                    {requisitos.map((s: RequisitoLegajoItem, i: number) => (
                        <tr key={i}>
                            <td>
                                <CheckEstado estado={s.id !== 0}/>
                                <span className='ms-2'>{s.descripcion}</span>
                            </td>
                            <td><TextoND texto={s.fecha_carga}/></td>
                            <td>
                                {s.nombre_archivo !== '' ?
                                    <span className='enlacespan text-primary'
                                          style={{'cursor': 'pointer'}}
                                          onClick={e => {
                                              e.stopPropagation()
                                              pedidoBlob({
                                                  url: urlDescargaRequisito(id_empleado, s.id_requisito),
                                                  ok: res => funcionDescarga(res, s.nombre_archivo)
                                              })
                                          }}
                                          onMouseEnter={() => cargaTooltipRequisitos(s.id_requisito)}
                                          onMouseLeave={() => setInfoTooltip(undefined)}>
                                              <i className='fas fa-file-download me-2'/>Descargar
                                              </span>
                                    : <TextoND/>}
                            </td>
                        </tr>
                    ))}
                    </tbody>
                </table>
                {infoTooltip !== undefined && <Tooltip datos={infoTooltip}/>}
            </>
        )
    }
}

export default RequisitosLegajo
