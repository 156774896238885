import React from "react";
import './PantallaLogin.css'
import Modal from "../controles/Modal";
import BotonDesloguear from "../controles/BotonDesloguear";
import PantallaCargando from "../controles/PantallaCargando";
import {useAppSelector} from "../redux/hooks";

type Props = {
    mensaje: string
}

const ErrorDeslogueo: React.FC<Props> = ({mensaje}) => {
    const cargando = useAppSelector(state => state.estado.cargando)

    return <div className='fondologin'>
        <Modal className='w-50' sinFondo>
            <div className='mb-3'>
                <i className='fas fa-times-circle text-danger me-2'/>
                {mensaje}
            </div>
            <BotonDesloguear estiloModal/>
        </Modal>
        {cargando && <PantallaCargando/>}
    </div>
}

export default ErrorDeslogueo