import React, {useCallback, useEffect} from "react";
import Boton from "./Boton";
import ToolbarBtn from "./ToolbarBtn";
import {useAppSelector} from "../redux/hooks";
import BotonCarga from "./BotonCarga";

type Props = {
    editando?: boolean,
    soloEditar?: boolean,
    soloVista?: boolean,
    soloCancelar?: boolean,
    onGuardar?: () => void,
    onCancelar: () => void,
    onEliminar?: () => void,
    tituloNuevo?: string,
    aceptarDisable?: boolean,
    guardando?: boolean
}

const ModalBotones: React.FC<Props> = (
    {
        editando, soloEditar, soloVista,
        soloCancelar, onGuardar, onCancelar, onEliminar,
        tituloNuevo, aceptarDisable, guardando
    }
) => {
    const errorSistema = useAppSelector(state => state.estado.error_sistema)

    const teclasEspeciales = useCallback(e => {
        if (errorSistema) return
        if (e.keyCode === 27) {
            onCancelar()
        } else if (e.keyCode === 13 && onGuardar !== undefined) {
            onGuardar()
        }
    }, [onCancelar, onGuardar, errorSistema])

    useEffect(() => {
        document.addEventListener('keydown', teclasEspeciales, false)
        return () => document.removeEventListener('keydown', teclasEspeciales, false)
    }, [teclasEspeciales])

    if (guardando) {
        return (
            <ToolbarBtn derecha>
                <BotonCarga titulo='Guardando...'/>
            </ToolbarBtn>
        )
    } else if (editando && onEliminar !== undefined && !soloEditar && onGuardar !== undefined) {
        return (
            <ToolbarBtn derecha>
                <Boton toolbar className='btn-secondary' titulo='Cancelar' icono='fas fa-times'
                       onClick={() => {
                           onCancelar()
                       }}/>
                <Boton toolbar titulo='Eliminar' icono='fas fa-trash' className='btn-danger'
                       onClick={() => {
                           onEliminar()
                       }}/>
                <Boton toolbar titulo='Guardar cambios' icono='fas fa-save' onClick={() => {
                    onGuardar()
                }} disabled={aceptarDisable}/>
            </ToolbarBtn>
        )
    } else if (soloEditar && onGuardar !== undefined) {
        return (
            <ToolbarBtn derecha>
                <Boton toolbar className='btn-secondary' titulo='Cancelar' icono='fas fa-times'
                       onClick={() => {
                           onCancelar()
                       }}/>
                <Boton toolbar titulo='Guardar cambios' icono='fas fa-save' onClick={() => {
                    onGuardar()
                }} disabled={aceptarDisable}/>
            </ToolbarBtn>
        )
    } else if (soloVista && onEliminar !== undefined) {
        return (
            <ToolbarBtn derecha>
                <Boton toolbar className='btn-secondary' titulo='Cerrar' icono='fas fa-times'
                       onClick={() => {
                           onCancelar()
                       }}/>
                <Boton toolbar titulo='Eliminar' icono='fas fa-trash' className='btn-danger'
                       onClick={() => {
                           onEliminar()
                       }}/>
            </ToolbarBtn>
        )
    } else if (onGuardar !== undefined) {
        return (
            <ToolbarBtn derecha>
                <Boton toolbar className='btn-secondary' titulo='Cancelar' icono='fas fa-times'
                       onClick={() => {
                           onCancelar()
                       }}/>
                <Boton toolbar titulo={tituloNuevo !== undefined ? tituloNuevo : 'Crear nuevo'} icono='fas fa-save'
                       onClick={() => {
                           onGuardar()
                       }} disabled={aceptarDisable}/>
            </ToolbarBtn>
        )
    } else if (soloCancelar) {
        return (
            <ToolbarBtn derecha>
                <Boton toolbar className='btn-secondary' titulo='Cerrar' icono='fas fa-times'
                       onClick={() => {
                           onCancelar()
                       }}/>
            </ToolbarBtn>
        )
    } else {
        return <>Configuración incorrecta</>
    }
}

export default ModalBotones
